import { takeLatest, select, put, call } from 'redux-saga/effects';
import { track } from 'lib/sdks/analytics';
import { SHORTLIST_CLICK_INIT } from '../constants';
import {
  makeSelectShortlistModalShown,
  makeSelectHasLocalStorageAccess,
  makeSelectShortlistFull,
} from '../selectors';
import { setModal, removeFromShortlist, addToShortlist } from '../actions';

export function* handleShortlistClick({ payload: modifiedPayload }) {
  const { isShortlisted, trackingData, ...payload } = modifiedPayload;

  const hasLocalStorageAccess = yield select(makeSelectHasLocalStorageAccess());
  const shortlistModalShown = yield select(makeSelectShortlistModalShown());
  const shortlistFull = yield select(makeSelectShortlistFull());

  const { id } = payload;

  try {
    if (!hasLocalStorageAccess) {
      yield put(setModal({ name: 'localStorage', data: payload }));
      return;
    }

    if (!shortlistModalShown || (!isShortlisted && shortlistFull)) {
      yield put(
        setModal({
          name: 'shortlist',
          data: payload,
          trackingData,
          shortlistFull,
        }),
      );
      return;
    }

    const action = isShortlisted ? removeFromShortlist : addToShortlist;
    yield put(action(payload));

    yield call(
      track,
      `global.${isShortlisted ? 'remove_from' : 'add_to'}_shortlist`,
      { ...trackingData, trader_id: id },
    );
  } catch (error) {
    // TODO: what to do for error
  }
}

export default function* watcher() {
  yield takeLatest(SHORTLIST_CLICK_INIT, handleShortlistClick);
}
