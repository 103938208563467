import config from 'lib/config';

export const getGoogleAuthHeaders = async (url: string) => {
  if (typeof window !== 'undefined' || config.platform !== 'gcp') {
    return {};
  }

  const { GoogleAuth } = await import('google-auth-library');
  const auth = new GoogleAuth();
  try {
    const audience = new URL(url).origin;
    const client = await auth.getIdTokenClient(audience);
    return await client.getRequestHeaders(audience);
  } catch (e) {
    return {};
  }
};
