export const appendQuery = (
  url: string,
  params: Partial<Record<string, string | number>>,
) => {
  const [path, hash] = url.split('#');
  const pathContainsQuery = path.includes('?');
  return `${path}${Object.keys(params).reduce(
    (res, key, index) =>
      `${res}${index === 0 && !pathContainsQuery ? '?' : '&'}${key}=${
        params[key]
      }`,
    '',
  )}${hash !== undefined ? `#${hash}` : ''}`;
};
