import { takeLatest, put, call, cancelled } from 'redux-saga/effects';
import formatTrader from '@utils/formatters/trader-by-name';
import type { TradeBasic } from 'lib/sdks/search';
import { searchProfileCompanies } from 'lib/sdks/search';

import { GET_TRADE_BY_NAME_INIT } from '../constants';
import { getTradeByNameSuccess, getTradeByNameError } from '../actions';

export function* callGetTradeByNameInit({
  payload: { company },
}: {
  payload: { company: string };
}) {
  let controller;

  try {
    controller = window.AbortController ? new AbortController() : undefined;
    const abortSignal = controller?.signal || undefined;

    const data: TradeBasic[] = yield call(
      searchProfileCompanies,
      company,
      abortSignal,
    );

    yield put(
      getTradeByNameSuccess({
        data: formatTrader(data),
      }),
    );
  } catch (error) {
    yield put(getTradeByNameError(error));
  } finally {
    const isCancelled: boolean = yield cancelled();
    if (isCancelled) {
      if (controller?.abort) {
        controller.abort();
      }
    }
  }
}

export default function* watcher() {
  // @ts-ignore
  yield takeLatest(GET_TRADE_BY_NAME_INIT, callGetTradeByNameInit);
}
