import type { Timestamp } from 'firebase/firestore';
import type {
  AdditionalDataFirestore,
  JobStartDateValue,
} from 'lib/sdks/jobs-management/job.type';

export interface FirebaseUser {
  email?: string | null;
  emailVerified?: boolean;
  contactPrefs?: {
    emailMarketing: boolean;
    dateSynced?: Timestamp;
    dateUserUpdated?: Timestamp;
    knownBeforeRegistration?: boolean;
  };
  name?: {
    first: string;
    last: string;
  };
  postcode?: string;
  phoneNumber?: string;
  dateUpdated?: Timestamp;
  dateCreated?: Timestamp;
}

export interface UserProfile extends FirebaseUser {
  email: string | null;
  emailVerified: boolean;
  uid: string;
  displayName: string | null;
}

export interface Trade {
  averageFeedbackScoreAnnual: number;
  displayName: string;
  heritageId: number;
  location: string;
  uniqueName: string;
  phoneNumber?: string;
  phoneLabel?: string;
}

export type JobType = 'DirectMessage' | 'RequestAQuote' | 'RAQAfterMessage';
export type JobTags = 'chat';

export type JobStatus =
  | 'Submitted'
  | 'Offered'
  | 'Allocated'
  | 'NoTrades'
  | 'Expired'
  | 'Failed'
  | 'Declined'
  | 'TradePoolExtinguished'
  | 'Closed';

export type LeadStatus =
  | 'Submitted'
  | 'Offered'
  | 'Accepted'
  | 'Declined'
  | 'TimedOut'
  | 'Redirected';

export type JobLead = {
  companyId: number;
  status: LeadStatus;
  dateUpdated: Timestamp | null;
  dateCreated: Timestamp | null;
};

export interface Job {
  id: string;
  heritageId?: string;
  type: JobType;
  token?: string;
  correlationId?: string;
  description: string;
  category: string | null;
  categoryId?: number | null;
  additionalData?: AdditionalDataFirestore;
  homeownerId?: string;
  consumerId?: string;
  tags?: JobTags[];
  initialHomeownerDetails?: {
    email: string;
    name: string;
    phoneNumber: string;
    postcode: string;
  };
  status: JobStatus;
  leads: JobLead[];
  dateUpdated: Timestamp | null;
  dateCreated: Timestamp | null;
  requestedStartDate?: JobStartDateValue | null;
}

export type FirestoreJobLead = {
  tradeId: number;
  status: LeadStatus;
  dateUpdated: Timestamp | null;
  dateCreated: Timestamp | null;
};

export interface FirestoreJob extends Omit<Job, 'leads'> {
  leads?: FirestoreJobLead[];
}

export enum ReviewStatus {
  PUBLISHED = 'published',
  SUBMITTED = 'submitted',
}

export interface Review {
  reviewId?: string;
  comments: string;
  company: Trade;
  dateCreated: Timestamp;
  datePublished: Timestamp | null;
  dateWorked: Timestamp;
  heritageId: number | null;
  scores: {
    courtesy: number | null;
    overall: number | null;
    tidiness: number | null;
    timekeeping: number | null;
    workmanship: number | null;
  };
  status: ReviewStatus;
  type: string | null;
  workDescription: string;
}
