import makeSize from '@toolshed/styleguide/styles/make-size';
import { fontFamilyMain } from 'lib/design-system';

export const sizes = {
  atom: 8,
  smicro: 9,
  micro: 10,
  lmicro: 11,
  small: 12,
  sbase: 13,
  base: 14,
  lbase: 15,
  smedium: 16,
  medium: 18,
  lmedium: 20,
  slarge: 22,
  large: 24,
  llarge: 26,
  xlarge: 28,
  xxlarge: 32,
  title: 36,
  titleLarge: 40,
  titleXLarge: 44,
  titleXXLarge: 48,
  titleXXXLarge: 52,
  errorCode: 59,
};

export const lineHeightRatio = 1.5;
export const letter = '0em';

export const families = {
  light: {
    weight: 300,
    style: 'normal',
  },
  regular: {
    weight: 400,
    style: 'normal',
  },
  bold: {
    weight: 700,
    style: 'normal',
  },
  semibold: {
    weight: 600,
    style: 'normal',
  },
};

export const typeface = ({
  size,
  family = {},
  letterSpacing = true,
  uppercase = false,
  style,
}) => {
  const fontSize = makeSize(size);
  return `
    font-family: ${fontFamilyMain};
    font-weight: ${family.weight};
    font-style: ${family.style};
    font-size: ${fontSize};
    line-height: ${lineHeightRatio};
    ${letterSpacing ? `letter-spacing: ${letter};` : ''};
    ${uppercase ? `text-transform: uppercase;` : ''};
    ${style ? `font-style: ${style};` : ''};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  `;
};

export const light = (size = 'base') =>
  typeface({ size, family: families.light });
export const regular = (size = 'base') =>
  typeface({ size, family: families.regular });
export const regularItalic = (size = 'base') =>
  typeface({ size, family: families.regular, style: 'italic' });
export const semibold = (size = 'base') =>
  typeface({ size, family: families.semibold });
export const bold = (size = 'base') =>
  typeface({ size, family: families.bold });
