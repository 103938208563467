import styled, { keyframes } from 'styled-components';
import colors from '@toolshed/styleguide/styles/colors';
import spacing from '@toolshed/styleguide/styles/spacing';
import {
  bold,
  semibold,
  regular,
} from '@toolshed/styleguide/styles/typography';
import tablet from '@styles/breakpoint/tablet';
import tabletPortrait from '@styles/breakpoint/tablet-portrait';
import {
  ButtonBase,
  ButtonBaseInner,
} from '@toolshed/styleguide/components/Button/ButtonBase';

import CatLogo from 'public/static/checkatrade-logo.svg';
import {
  PrimaryButton,
  spacingInlineNarrow,
  breakpointMediumStart,
  spacing1,
  spacing2,
  spacing3,
  spacing4,
  spacing5,
  spacing6,
} from 'lib/mortar';

export const Wrapper = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 60px;
  width: 100%;
  background-color: ${colors.white};
  border-bottom: ${spacing.navBorder}px solid ${colors.skyBlue};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 103;
  box-sizing: border-box;

  ${tablet(`
    padding: 0 ${spacing2};
  `)};
`;

export const ActionButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1 1 0;
  justify-content: flex-end;

  ${tablet(`
    max-width: 224px;
    margin-right: ${spacing2};
  `)};

  ${PrimaryButton} {
    margin-right: ${spacing3};
    @media (max-width: ${breakpointMediumStart}) {
      margin-right: ${spacing1};
    }
  }
`;

const slideInLeft = keyframes`
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`;

// animation to slide out the home page to the left
const slideOutLeft = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
`;

export const Wing = styled.div`
  position: fixed;
  top: ${spacing.nav}px;
  left: 0;
  right: 0;
  z-index: 102;
  width: 100vw;
  background-color: ${colors.white};
  bottom: 0;
  visibility: hidden;
  transform: translate3d(-100%, 0, 0);
  &.wing-enter-done,
  &.wing-exit {
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }
  &.wing-enter {
    animation: ${slideInLeft} 0.2s forwards;
  }
  &.wing-exit {
    animation: ${slideOutLeft} 0.2s forwards;
  }
`;

export const Menu = styled.div`
  width: 28px;
  align-self: center;
  margin: 0 0 0 ${spacing4};
  position: relative;

  ${tablet(`
    display: none;
  `)};

  @media screen and (min-width: 376px) {
    margin: ${spacing1} ${spacing2} 0px ${spacing4};
  }

  span {
    width: 24px;
    height: 24px;

    @media screen and (min-width: 376px) {
      width: 32px;
      height: 32px;
    }
  }
`;

export const Inner = styled.div`
  position: relative;
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;

  ${tabletPortrait(`
    max-width: ${spacing.maxPageWidth}px;
    margin: 0 auto;
  `)}
`;

export const NavButton = styled(PrimaryButton)`
  padding: ${spacing1} ${spacing2};
  min-height: 32px;

  @media screen and (min-width: 376px) {
    padding: ${spacing1} ${spacing3};
  }

  @media screen and (min-width: ${breakpointMediumStart}) {
    padding: 0px ${spacing6};
    min-height: 40px;
  }
`;

export const Logo = styled.div`
  margin: auto;
`;

export const Image = styled(CatLogo)`
  height: 13px;
  width: auto;
  cursor: pointer;

  @media screen and (min-width: 376px) {
    height: 17px;
  }

  @media screen and (min-width: ${breakpointMediumStart}) {
    height: 19px;
  }
`;

export const LogoLink = styled.a`
  display: flex;
`;

export const Links = styled.div`
  display: none;

  ${tablet(`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    margin-right: ${spacing2};
    ${bold('base')};
  `)};
`;

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  height: 100%;
  cursor: pointer;
  position: relative;

  &:hover {
    color: ${colors.blueLightened};
  }
`;

export const LinkItem = styled(ButtonBase)`
  padding: ${spacingInlineNarrow} 0;
  margin: 0 ${spacing5};
  cursor: pointer;
`;

export const LinkItemInner = styled(ButtonBaseInner)`
  ${semibold('lbase')};
  color: ${colors.blue};
  padding: ${spacing1};
`;

export const MegaWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  flex: 1;
  overflow: auto;
  border-top: 1px solid ${colors.light};
  background-color: ${colors.white};
  display: flex;
  flex-direction: row;

  box-sizing: border-box;
`;

export const MegaInner = styled.nav`
  display: flex;
  flex-direction: row;
  padding: ${spacing.xxlarge}px 0px;
  ${tabletPortrait(`
    max-width: 1050px;
    margin: 0 auto;

  `)}
`;
export const MegaSection = styled.div`
  margin: ${spacing2};
  margin-right: ${spacing.xlarge}px;
  padding-right: ${spacing.xlarge}px;
  width: calc(100% - ${spacing4});
  border-right: 1px solid ${colors.blue};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  ${tabletPortrait(`
    &:first-child {
      margin-left: ${spacing2};
      width: calc(100% - ${spacing.xlarge + spacing2});
    }
    &:last-child {
      border-right: none;
      margin-right: ${spacing2};
      padding-right: 0;
      width: calc(100% - ${spacing.xlarge + spacing2});
    }
  `)};
  &:last-child {
    border-right: none;
    padding-right: 0;
  }
`;

export const MegaHighlight = styled.div`
  ${semibold('smedium')};
  background-color: ${colors.blue};
  color: ${colors.white};
  margin-bottom: ${spacing2};
  padding: ${spacing1};
  padding-right: 30px;
  clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
  display: flex;
  ${tabletPortrait(semibold('smedium'))};
`;

export const MegaHeading = styled.div`
  ${semibold('smedium')};
  color: ${colors.blue};
  padding: ${spacing1} 0;
  margin-bottom: ${spacing2};
`;

export const MegaParagraph = styled.p`
  ${regular('small')};
  flex: 1;
  color: ${colors.medium};
  margin: 0;
  margin-bottom: ${spacing5};
  max-width: 100%;
`;

export const SavedTradesWrapper = styled.div`
  ${regular('small')};
  display: flex;
  flex: 1;
`;

export const SavedTradesIconContainer = styled.div`
  position: relative;
  margin-right: ${spacing2};
`;

export const NavIconContainer = styled.div`
  position: absolute;
  right: 5px;
  top: 12px;
`;

export const BadgeContainer = styled.div`
  position: absolute;
  right: -2px;
  top: -2px;
`;

export const HamburgerBadgeContainer = styled.div`
  position: absolute;
  right: -5px;
  top: 2px;
`;
