/* istanbul ignore file */

/* istanbul ignore next */
export const baseState = {
  loading: false,
  error: undefined,
  data: undefined,
};

/* istanbul ignore next */
const initialState = {
  uniqueName: undefined,
  data: undefined,
  loading: undefined,
  error: undefined,
  checklists: baseState,
  altTrades: {
    loading: false,
    error: undefined,
    data: [],
  },
};

export default initialState;
