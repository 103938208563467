import { getLocalStorage, subscribe, saveState } from './local-storage';

const cleanupOldShortlistItems = state => {
  const cleanShortlist = [];
  state.global.shortlist.data.map(item => {
    // Legacy shortlist items don't have uniqueName. So remove them
    if (item.uniqueName) {
      cleanShortlist.push(item);
    }
  });

  return {
    ...state,
    global: {
      ...state.global,
      shortlist: { ...state.global.shortlist, data: cleanShortlist },
    },
  };
};

const syncLocalStorageState = (
  {
    saveOnActionTypes = [],
    localStorageChange,
    localStorageLoaded,
    localStorageNoAccess,
    keys = [],
  } = {},
  localStorage = { getLocalStorage, subscribe, saveState },
) => {
  let state;
  let loaded = false;
  let hasAccess = false;
  try {
    state = localStorage.getLocalStorage();
    hasAccess = true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error); // Note: This should be handled by Datadog when it is installed
  }

  return store => next => action => {
    if (!loaded) {
      loaded = true;
      // load local storage state if it exists
      if (state) {
        if (state.global.shortlist) {
          const cleanState = cleanupOldShortlistItems(state);
          store.dispatch(localStorageChange(cleanState));
          localStorage.saveState(store.getState(), keys);
        } else {
          store.dispatch(localStorageChange(state));
        }
      }

      // listen to storage changes if we havee access
      if (hasAccess) {
        localStorage.subscribe(e => {
          store.dispatch(localStorageChange(e));
        });
      } else {
        store.dispatch(localStorageNoAccess());
      }

      store.dispatch(localStorageLoaded());
    }

    const result = next(action);
    if (!hasAccess) {
      return result;
    }

    const { type } = action;
    // only update local state on actions we care about
    if (saveOnActionTypes.includes(type)) {
      localStorage.saveState(store.getState(), keys);
    }

    return result;
  };
};

export default syncLocalStorageState;
