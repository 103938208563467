import type { Experiments } from './types';

type ABTestFactoryArgs = {
  isServer: boolean;
  query: any;
  abTests?: Experiments;
};

type UpdateABTestAction = {
  type: typeof ACTION_UPDATE_AB_TESTS;
  payload: Experiments;
};

export const ACTION_UPDATE_AB_TESTS = 'UPDATE_AB_TESTS';

const abTestsFactory = ({ isServer, abTests = {} }: ABTestFactoryArgs) => {
  const initialState = isServer ? abTests : {};

  return (
    state: Experiments = initialState,
    { type, payload }: UpdateABTestAction,
  ) => {
    if (type === ACTION_UPDATE_AB_TESTS) {
      return { ...state, ...payload };
    }

    return state;
  };
};

export default abTestsFactory;
