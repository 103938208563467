const { OUT_OF_TEST_VALUE } = require('./ab-tests.constants');

export const getVariantCookieValue = (rollout, variants) => {
  const rolloutDist = Math.random();
  if (rolloutDist > rollout) {
    return OUT_OF_TEST_VALUE;
  }

  const rand = Math.random(); // 0 - 0.99
  let probSum = 0.0;
  const len = variants.length;
  for (let i = 0; i < len; i += 1) {
    const variant = variants[i];
    const { value, distribution } = variant;
    if (rand < probSum + distribution && probSum <= rand) {
      return value;
    }

    probSum += distribution;
  }

  return OUT_OF_TEST_VALUE;
};

export const getCookieName = ({ name, experimentId }) =>
  `ab-${name}-${experimentId}`;

export const getTestValueFactory = (req, res) => experimentOptions => {
  const { variants, rollout, cookieMaxAge } = experimentOptions;

  const cookieName = getCookieName(experimentOptions);

  let cookieValue = req.cookies[cookieName];
  if (typeof cookieValue === 'undefined') {
    cookieValue = getVariantCookieValue(rollout, variants);
  }

  res.cookie(cookieName, cookieValue.toString(), {
    httpOnly: true,
    maxAge: (cookieMaxAge || 86400 * 90) * 1000, // a number representing the milliseconds from Date.now() for expiry
    path: '/',
    secure: true,
  });

  return cookieValue;
};
