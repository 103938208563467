import React from 'react';
import PropTypes from 'prop-types';

const JsonLD = ({ info, key } = {}) => {
  const schema = {
    '@context': 'http://schema.org',
    ...info,
  };

  return (
    <script
      key={key}
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
    />
  );
};

JsonLD.defaultProps = {
  key: 'json-ld',
};

JsonLD.propTypes = {
  info: PropTypes.object,
  key: PropTypes.string,
};

export default JsonLD;
