import type { ActionsUnion } from '@utils/actions';
import { createAction, simpleActionCreator } from '@utils/actions';
import * as TYPES from './constants';

export const locationChange = (payload: { url: string }) =>
  createAction(TYPES.LOCATION_CHANGE, payload);

export const setModal = (payload: {
  name: string | undefined;
  data?: unknown;
  trackingData?: unknown;
  shortlistFull?: boolean;
}) => createAction(TYPES.SET_MODAL, payload);

export const setGlobalError = (payload: { status: string }) =>
  createAction(TYPES.SET_GLOBAL_ERROR, payload);

export const localStorageChange = (payload: {
  global: {
    shortlist: { data: unknown[]; modalShown: boolean };
    messageTraderHistory: { expires: number }[];
    recentSearches: {
      categoryId: number;
      location: string;
    }[];
  };
}) =>
  createAction(TYPES.LOCAL_STORAGE_CHANGE, {
    ...payload,
    meta: {
      time: Date.now(),
    },
  });

export const localStorageLoaded = (payload: unknown) =>
  createAction(TYPES.LOCAL_STORAGE_LOADED, payload);

export const localStorageNoAccess = (payload: unknown) =>
  createAction(TYPES.LOCAL_STORAGE_NO_ACCESS, payload);

export const getCategoriesInit = (payload: {
  isServer: boolean;
  includeAliasCategories: boolean;
  persistFromSSR?: boolean;
}) => createAction(TYPES.GET_CATEGORIES_INIT, payload);

export const getCategoriesSuccess = (payload: { data: unknown }) =>
  createAction(TYPES.GET_CATEGORIES_SUCCESS, payload);

export const getCategoriesError = (payload: unknown) =>
  createAction(TYPES.GET_CATEGORIES_ERROR, payload);

// Shortlist

export const handleShortlistClick = (payload: {
  isShortlisted: boolean;
  trackingData: unknown;
}) => createAction(TYPES.SHORTLIST_CLICK_INIT, payload);

export const addToShortlist = (payload: unknown) =>
  createAction(TYPES.ADD_TO_SHORTLIST, payload);

export const removeFromShortlist = (payload: { uniqueName: string }) =>
  createAction(TYPES.REMOVE_FROM_SHORTLIST, payload);

export const clearShortlist = simpleActionCreator(TYPES.CLEAR_SHORTLIST);

export const setMostRecentDescription = (payload: { description: string }) =>
  createAction(TYPES.SET_MOST_RECENT_DESCRIPTION, payload);

export const setMessageTraderSent = (payload: { correlationId: string }) =>
  createAction(TYPES.SET_MESSAGE_TRADER_SENT, payload);

// SEND A JOB
export const sendAJobSetStep = (payload: { step: string }) =>
  createAction(TYPES.SEND_A_JOB_SET_STEP, payload);

export const getCategoryByNameInit = (payload: unknown) =>
  createAction(TYPES.GET_CATEGORIES_BY_NAME_INIT, payload);

export const getTradeByNameInit = (payload: unknown) =>
  createAction(TYPES.GET_TRADE_BY_NAME_INIT, payload);

export const getTradeByNameSuccess = (payload: { data: unknown[] }) =>
  createAction(TYPES.GET_TRADE_BY_NAME_SUCCESS, payload);

export const getTradeByNameError = (payload: unknown) =>
  createAction(TYPES.GET_TRADE_BY_NAME_ERROR, payload);

export const addRecentSearch = (payload: {
  categoryId: number;
  location: string;
}) => createAction(TYPES.ADD_RECENT_SEARCH, payload);

export const removeRecentSearch = (payload: {
  categoryId: number;
  location: string;
}) => createAction(TYPES.REMOVE_RECENT_SEARCH, payload);

export const actions = {
  locationChange,
  setModal,
  setGlobalError,
  localStorageChange,
  localStorageLoaded,
  localStorageNoAccess,
  getCategoriesError,
  getCategoriesInit,
  getCategoriesSuccess,
  handleShortlistClick,
  addToShortlist,
  removeFromShortlist,
  clearShortlist,
  setMostRecentDescription,
  setMessageTraderSent,
  sendAJobSetStep,
  getCategoryByNameInit,
  getTradeByNameInit,
  getTradeByNameSuccess,
  getTradeByNameError,
  removeRecentSearch,
  addRecentSearch,
};

export type Actions = ActionsUnion<typeof actions>;
