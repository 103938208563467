import PATTERNS from '@toolshed/core/patterns/patterns';
import validatePattern from './validate-pattern';
import validateMaxLength from './validate-max-length';
import validateRequired from './validate-required';

const ValidateEmail = (email, required) => {
  const lowerCaseEmail = email ? email.toLowerCase() : '';

  if (required && validateRequired(lowerCaseEmail)) {
    return 'Required';
  }

  if (
    !validatePattern(lowerCaseEmail, {
      pattern: PATTERNS.email,
    }) &&
    !validateMaxLength(lowerCaseEmail, {
      maxLength: 75,
    })
  ) {
    return undefined;
  }

  return 'Invalid email address';
};

export default ValidateEmail;
