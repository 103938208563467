import sortByLabel from '@utils/sort-by-label';
import type { Category, SingleCategory } from 'lib/sdks/search';

const flattenCategories = (
  categories: Category[],
  noSort: boolean = true,
): SingleCategory[] => {
  const parentCategories = [];
  const childCategories: SingleCategory[] = [];
  const len = categories.length;

  for (let i = 0; i < len; i += 1) {
    const category = categories[i];
    const { subcategories = [], id, label, name, vanityCategory } = category;
    parentCategories.push({
      id,
      label,
      name,
      highlighted: subcategories.length > 0,
    });

    const subLen = subcategories.length;

    for (let j = 0; j < subLen; j += 1) {
      const subCategory = subcategories[j];
      if (noSort) {
        parentCategories.push({
          ...subCategory,
          parent: { id, name, label, vanityCategory },
        });
      } else {
        childCategories.push({
          ...subCategory,
          parent: { id, name, label, vanityCategory },
        });
      }
    }
  }

  if (noSort) {
    //here childCategories have already been pushed into their parents
    return parentCategories;
  }

  return (
    parentCategories
      .sort(sortByLabel)
      // @ts-ignore
      .concat(childCategories.sort(sortByLabel))
  );
};

export default flattenCategories;
