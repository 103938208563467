import {
  RANKING_ALGORITHMS,
  RankingAlgorithmStates,
  SEARCH_ALGO_EXPERIMENT_ID,
  SERVER_TRACKING,
  START_DATE_SCREEN,
  StartDateScreenStates,
  UpdatedTrackingToggleStates,
} from './ab-tests.constants';

type Experiment = {
  name: string;
  experimentId: string;
  rollout: number;
  variants: {
    value: number;
    distribution: number;
  }[];
};

// Please keep the experiments in alphabetical order - they're easier to find that way
const experiments: readonly Experiment[] = [
  {
    name: RANKING_ALGORITHMS,
    experimentId: SEARCH_ALGO_EXPERIMENT_ID,
    rollout: 1,
    variants: [
      {
        value: RankingAlgorithmStates.CONTROL,
        distribution: 0,
      },
      {
        value: RankingAlgorithmStates.NEW_ALGO_WEIGHT_3,
        distribution: 1,
      },
    ],
  },
  {
    name: START_DATE_SCREEN,
    experimentId: 'C-1533',
    rollout: 0,
    variants: [
      {
        value: StartDateScreenStates.CONTROL,
        distribution: 0.5,
      },
      {
        value: StartDateScreenStates.START_DATE_SCREEN_FIRST,
        distribution: 0.5,
      },
    ],
  },
  {
    name: SERVER_TRACKING,
    experimentId: 'feature_toggle',
    rollout: 1,
    variants: [
      {
        value: UpdatedTrackingToggleStates.ENABLED,
        distribution: 0, // NO DISTRIBUTION
      },
      {
        value: UpdatedTrackingToggleStates.CONTROL, // DISABLED
        distribution: 1,
      },
    ],
  },
];

module.exports = experiments;
