import type { z } from 'zod';
import { logger } from 'lib/utils/logger';
import { APIError } from '../errors';

type CreateApiArgs<Schema> = {
  url: string;
  method?: string;
  schema: Schema;
};

type ApiHandlerBaseArgs<Payload = unknown> = {
  payload?: Payload;
};

type ApiArgs<Payload = unknown> = ApiHandlerBaseArgs<Payload> & {
  token: string;
};

/**
 * Factory function to create an API handler function which use our auth token
 *
 * @example ```tsx
 * const createUser = createAuthAPI({
 *   url: `${process.env.API_URL}/user`,
 *   method: 'POST',
 *    schema: UserSchema,
 * });
 *
 * createUser({ payload: { name: 'John' }, token });
 * ```
 */
export const createAuthAPI = <Payload, Schema extends z.ZodSchema = any>({
  url,
  method = 'GET',
  schema,
}: CreateApiArgs<Schema>) => {
  return async ({
    payload,
    token,
  }: ApiArgs<Payload>): Promise<z.infer<Schema>> => {
    const res = await fetch(url, {
      method,
      body: payload ? JSON.stringify(payload) : undefined,
      headers: {
        ...(payload ? { 'Content-Type': 'application/json' } : {}),
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await res.json();

    if (!res.ok) {
      logger.warn(res);
      throw new APIError(result.detail, res);
    }

    const parsedResult = schema.safeParse(result);

    if (!parsedResult.success) {
      logger.warn(
        parsedResult.error,
        `Response failed validation ${method}:${url}`,
      );
    }

    return parsedResult.success ? parsedResult.data : result;
  };
};

/**
 * Factory function to create a public API handler function
 */
export const createPublicAPI = <Payload, Schema extends z.ZodSchema = any>({
  url,
  method = 'GET',
  schema,
}: CreateApiArgs<Schema>) => {
  return async ({
    payload,
  }: ApiHandlerBaseArgs<Payload>): Promise<z.infer<Schema>> => {
    const res = await fetch(url, {
      method,
      body: payload ? JSON.stringify(payload) : undefined,
      headers: {
        ...(payload ? { 'Content-Type': 'application/json' } : {}),
      },
    });
    const result = await res.json();

    if (!res.ok) {
      logger.warn(res);
      throw new APIError(result.detail, res);
    }

    const parsedResult = schema.safeParse(result);

    if (!parsedResult.success) {
      logger.warn(
        parsedResult.error,
        `Response failed validation ${method}:${url}`,
      );
    }

    return parsedResult.success ? parsedResult.data : result;
  };
};
