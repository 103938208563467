/* eslint-disable max-classes-per-file */

export class APIError extends Error {
  status: number;
  url: string;

  constructor(message: string, res: Response) {
    super(message);

    this.status = res.status;
    this.url = res.url;
  }
}

export class FieldError extends Error {
  field: string;

  constructor(field: string, message = 'Invalid field') {
    super(message);

    this.field = field;
  }
}

export class SubmissionError extends Error {
  constructor(message = 'Something went wrong') {
    super(message);
  }
}

export class UnauthorizedError extends Error {
  constructor(message = 'User is not authenticated') {
    super(message);
  }
}

export class ValidationError extends Error {
  constructor(message = 'Invalid content') {
    super(message);
  }
}
