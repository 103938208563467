import type * as Braze from './braze-exports';

type EventMap = {
  JobSubmitted: {};
  AccountCreated: {};
  ViewedSearchResults: {};
  ViewedReviewForm: {};
};

type EventNames = keyof EventMap;
type EventData<T extends EventNames> = EventMap[T];

let _braze: typeof Braze | null = null;

export async function getBrazeInstance() {
  if (_braze) {
    return _braze;
  }

  _braze = await import('./braze-exports');
  return _braze;
}

export async function initialiseBraze() {
  const braze = await getBrazeInstance();

  if (!braze) return;
  braze.initialize(process.env.NEXT_PUBLIC_BRAZE_API_KEY as string, {
    baseUrl: process.env.NEXT_PUBLIC_BRAZE_SDK_ENDPOINT as string,
    enableLogging: !!localStorage.getItem('braze-debug') ?? false,
  });

  braze.automaticallyShowInAppMessages();
}

export async function logBrazeEvent<EventName extends EventNames>(
  name: EventName,
  data?: EventData<EventName>,
) {
  const braze = await getBrazeInstance();

  if (!braze) return;

  braze.logCustomEvent(name, {
    source: 'web',
    ...data,
  });
}

export async function changeBrazeUser(userId: string) {
  const braze = await getBrazeInstance();

  if (!braze) return;

  braze.changeUser(userId);
  braze.openSession();
}

export async function openSession(userId?: string) {
  const braze = await getBrazeInstance();

  if (!braze) return;

  userId && braze.changeUser(userId);
  braze.openSession();
}
