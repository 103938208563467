import type { Config } from '../types';

export const getServerConfig = (): Config => ({
  platform: process.env.PLATFORM === 'local' ? 'local' : 'gcp',
  strapiUrl: process.env.STRAPI_URL_SERVER
    ? new URL(process.env.STRAPI_URL_SERVER).toString()
    : '',
  reviewsApiUrl: '',
  searchApiUrl: process.env.SEARCH_API_URL_SERVER
    ? new URL(process.env.SEARCH_API_URL_SERVER).toString()
    : '',
  consumerAreaApiUrl: process.env.CONSUMER_AREA_API_URL_SERVER
    ? new URL(process.env.CONSUMER_AREA_API_URL_SERVER).toString()
    : '',
  consumerPublicApiUrl: process.env.NEXT_PUBLIC_CONSUMER_PUBLIC_URL
    ? new URL(process.env.NEXT_PUBLIC_CONSUMER_PUBLIC_URL).toString()
    : '',
});
