import pino from 'pino';

const formatters = {
  level: label => {
    if (label === 'warn') {
      return { severity: 'WARNING' };
    }

    return { severity: label.toUpperCase() };
  },
};

export const logger = pino({
  level: 'info',
  formatters,
  browser: {
    serialize: true,
    asObject: true,
  },
  base: undefined, // Setting this to undefined removes pid and hostname
});

export const logSource = 'pino';
