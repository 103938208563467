import { useEffect } from 'react';
import { useAuthContext } from './hooks';
import type { User } from 'firebase/auth';
import { useAuthStatus, useIsAuthenticated } from 'lib/auth/auth.hooks';

type SignInLevels = 'authenticated' | 'soft-authenticated';

type OnSignedInProps = {
  currentUser: User;
  signInLevel: SignInLevels;
};

type SignInCheckProps = {
  requireSignedIn?: boolean;
  requireSignedOut?: boolean;
  onSignedIn?: (args?: OnSignedInProps) => void;
  onSignedOut?: () => void;
  fallback: JSX.Element;
  children: JSX.Element;
};

type SoftSignInCheckProps = SignInCheckProps & {
  requireSoftSignedIn?: boolean;
};

const SignInCheck = ({
  requireSignedIn,
  requireSignedOut,
  onSignedIn,
  onSignedOut,
  fallback,
  children,
}: SignInCheckProps) => {
  const authStatus = useAuthStatus();
  const isAuthenticated = useIsAuthenticated();

  const { currentUser, auth } = useAuthContext();

  const loading = authStatus === 'loading';

  const isSoftAuthenticated = (currentUser && !currentUser.email) || false;

  useEffect(() => {
    (async () => {
      if (isSoftAuthenticated) {
        await auth.signOut();
      }
    })();
  }, [isSoftAuthenticated, auth]);

  useEffect(() => {
    if (onSignedIn && isAuthenticated && !isSoftAuthenticated) {
      onSignedIn();
    }
  }, [onSignedIn, isAuthenticated, isSoftAuthenticated]);

  useEffect(() => {
    if (onSignedOut && !isAuthenticated && !loading) {
      onSignedOut();
    }
  }, [onSignedOut, isAuthenticated, loading]);

  return loading ||
    isSoftAuthenticated ||
    (requireSignedOut && isAuthenticated) ||
    (requireSignedIn && !isAuthenticated)
    ? fallback
    : children;
};

const SoftSignInCheck = ({
  requireSoftSignedIn,
  requireSignedIn,
  requireSignedOut,
  onSignedIn,
  onSignedOut,
  fallback,
  children,
}: SoftSignInCheckProps) => {
  const { currentUser, loading } = useAuthContext();

  useEffect(() => {
    if (onSignedIn && currentUser) {
      onSignedIn({
        currentUser,
        signInLevel: currentUser.email ? 'authenticated' : 'soft-authenticated',
      });
    }
  }, [onSignedIn, currentUser]);

  useEffect(() => {
    if (onSignedOut && !loading && !currentUser) {
      onSignedOut();
    }
  }, [onSignedOut, currentUser, loading]);

  const isSignedIn = currentUser?.email || false;
  const isSoftAuthenticated = (currentUser && !currentUser.email) || false;

  return loading ||
    (requireSignedOut && currentUser) ||
    (requireSignedIn && !isSignedIn) ||
    (requireSoftSignedIn && !(isSoftAuthenticated || isSignedIn))
    ? fallback
    : children;
};

export { SignInCheck, SoftSignInCheck };
