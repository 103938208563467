export const getCookie = (name: string): string | null => {
  const match = document.cookie.match(new RegExp(`(?:^| )${name}=([^;]+)`));
  if (match) {
    return match[1];
  }

  return null;
};

export const setCookie = (
  name: string,
  value: string,
  options: {
    maxAgeSeconds?: number;
    domain?: string;
  },
) => {
  document.cookie = `${name}=${value}; path=/${
    typeof options.maxAgeSeconds !== 'undefined'
      ? `; max-age=${options.maxAgeSeconds}`
      : ''
  }${options.domain ? `; domain=${options.domain}` : ''};`;
};
