import { Amplify } from '@aws-amplify/core';
import { Analytics, AWSKinesisProvider } from '@aws-amplify/analytics';
import { Auth } from '@aws-amplify/auth';
import { debugLog } from 'lib/utils/debug';
import type { Event, Plugin } from '../../types';
import type { KinesisConfig } from './types';
import { formatEventData } from '../../formatter';

export const createKinesis = (config: KinesisConfig): Plugin => {
  Amplify.Logger.LOG_LEVEL = 'ERROR';
  Analytics.addPluggable(new AWSKinesisProvider());

  Auth.configure({
    identityPoolId: config.awsIdentityPoolId,
    region: config.awsRegion,
  });

  Amplify.configure({
    Auth: {
      identityPoolId: config.awsIdentityPoolId,
      region: config.awsRegion,
    },
  });

  Analytics.configure({
    AWSKinesis: {
      region: config.awsRegion,
      bufferSize: 10,
      flushSize: 10,
      flushInterval: 1000,
      resendLimit: 5,
    },
  });

  return (event: Event) => {
    const payload = {
      data: formatEventData(event),
      partitionKey: event.flowEnrollment
        ? config.awsPartitionKey
        : event.context.sessionId, // TODO: should this definitely be session id?
      streamName: event.flowEnrollment
        ? config.awsFlowStreamName
        : config.awsStreamName,
    };

    debugLog('Kinesis track:', payload);

    void Analytics.record(payload, 'AWSKinesis');
  };
};
