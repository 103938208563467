import { appendQuery } from 'lib/utils/url';

export type PaginatedResponseGcp<I> = {
  items: I[];
  meta: {
    currentPage: number;
    itemsPerPage: number;
    totalPages: number;
    totalResults: number;
  };
};

export interface PaginationOptions extends Record<string, string | number> {
  page: number;
  itemsPerPage: number;
  sortBy: string;
}

export interface PaginationOptionsGcp {
  page: number;
  size: number;
  sort: string;
  algorithm: string;
  injections: 'false';
}

export type V3SearchOptions = {
  excludeCompanyIds: number[];
} & PaginationOptionsGcp;

export const applyPaginationGcp = (
  url: string,
  paginationOptions: Partial<PaginationOptionsGcp>,
) => appendQuery(url, paginationOptions);
