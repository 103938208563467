import { setAppContext } from './context';
import { setPlugins, trackEvent } from './track';
import type { Config, EventName, CustomEventData } from './types';

export const setConfig = (config: Config) => {
  if (typeof window === 'undefined') {
    return;
  }

  setPlugins(config.plugins);
  setAppContext(
    config.appContext,
    config.waitForUserId,
    config.useCrossDomainCookie,
  );
};

export { setUserId } from './context';

export const track = (eventName: EventName, customData?: CustomEventData) => {
  trackEvent(eventName, customData);
};

export {
  startFlow,
  updateFlow,
  clearFlow,
  stopFlow,
  persistFlow,
  restoreFlow,
  updateFlowContext,
} from './flow';

export { FlowId } from './types';
export type { EventName, CustomEventData } from './types';
export * from './plugins';
