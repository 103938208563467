export * from 'lib/design-system/design-tokens/colors';

const colors = {
  darkBlue: '#101D41',
  darkBlueAccent1: '#4C5570',
  darkBlueAccent2: '#878EA0',
  darkBlueAccent3: '#C3C6CF',

  blue: '#0058a2',
  blueAccent1: '#1062A8',
  blueAccent2: '#4081B9',
  blueAccent3: '#8FB6D7',

  skyBlue: '#007eb4',
  skyBlueAccent1: '#409EC7',
  skyBlueAccent2: '#80BED9',
  skyBlueAccent3: '#BFDFEC',

  red: '#D10A38',
  redAccent1: '#DC476A',
  redAccent2: '#E8849B',
  redAccent3: '#F3C2CD',

  secondaryRed: '#EB0B40',
  redDarken: '#9E0005',

  // neutrals

  black: '#000000',
  dark: '#333333',
  medium: '#666666',
  regular: '#999999',
  light: '#CCCCCC',
  disabled: '#EDEDED',
  lightest: '#F8F7F5',
  white: '#FFFFFF',

  // to replace with proper name (not part of styleguide but needed)
  dropdownBackground: '#f2f7fa',
  reviewOrange: '#F7941D',

  // BELOW ARE GOING OFF OF STYLEGUIDE OR DEPRECATED

  // none style guide
  homepageCTA: '#D10A38',
  boxShadow: 'rgba(0, 0, 0, 0.16)', // use rgba for edge/ie11
  overlay: 'rgba(0, 0, 0, 0.4)',
  galleryImageBackground: '#b1b1b1',
  beforeYouUpload: '#E3F6FF',
  sortLabel: '#666666',
  verificationValue: '#A0D4FF',
  shortlistRemove: '#B9B9B9',
  toggleBackground: '#EAEAEA',
  toggleArrow: '#0856A2',
  reviewsBreakdown: '#E8F2FA',
  reviewsBreakdownBorder: '#D8E1E8',
  reviewsZeroStroke: '#E6E6E6',
  infoBarBackground: '#e7ebed',
  paginationChevronBackground: '#e7ebed',
  disabledChevron: '#898989',
  moreInfoChevron: '#3892DE',
  secureLock: '#00BBD6',
  secureContactForeground: '#5B9BD9',
  changingBlue: '#A4CAED',
  changingLight: '#F4F4F4',
  textAreaPlaceholder: '#C6C6C6',
  scrollbarBackground: 'rgb(20,99,166)',
  courtesyRating: '#E0C348',
  tidinessRating: '#7ACC6F',
  workmanshipRating: '#77D5CD',
  quoteAccuracyRating: '#146BA2',
  activeStepBorder: '#e0e0e0',
  newMemberBadgeBackground: '#ea983e',

  blue50: 'rgba(0, 88, 162, 0.5)',
  blue45: 'rgba(0, 88, 162, 0.45)',

  lightblue45: 'rgba(6, 146, 206, 0.45)',
  lightblueGradient: '#0078AC', // button gradient end value
  lightblueGradientDarkened: '#005479',
  lightestBlue: '#C6DFF4',

  white65: 'rgba(255, 255, 255, 0.65)',
  background: '#EAEAEA',

  // secondary accent colours
  green: '#75B843',
  orange: '#FFCC00',
  turquoise: '#24BF93',
};

export default colors;

// Adapted from https://stackoverflow.com/a/13532993
export const darken = color => {
  const R = parseInt(color.substring(1, 3), 16);
  const G = parseInt(color.substring(3, 5), 16);
  const B = parseInt(color.substring(5, 7), 16);

  return `#${[R, G, B]
    .map(colour => {
      let newColour = parseInt(colour * 0.9, 10);
      newColour = newColour < 255 ? newColour : 255;

      return newColour.toString(16).length === 1
        ? `0${newColour.toString(16)}`
        : newColour.toString(16);
    })
    .join('')}`;
};
