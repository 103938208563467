import * as React from 'react';
import styled from 'styled-components';
import {
  colorTextLinkHover,
  colorSecondary100Default,
} from '@toolshed/styleguide/styles/colors';
import spacing from '@toolshed/styleguide/styles/spacing';
import { regular, sizes } from '@toolshed/styleguide/styles/typography';

interface BadgeComponentProps {
  color: string;
}

interface BadgeProps {
  secondary?: boolean;
  value: number | string;
}

export const BadgeComponent = styled.div<BadgeComponentProps>`
  ${regular('small')}
  background-color: ${props => props.color};
  border-radius: 50%;
  box-sizing: border-box;
  color: white;
  font-size: ${sizes.small}px;
  height: ${spacing.newLarge}px;
  line-height: ${spacing.newLarge}px;
  text-align: center;
  width: ${spacing.newLarge}px;
`;

const Badge = ({ secondary = false, value }: BadgeProps) => {
  const props = {
    color: secondary ? colorTextLinkHover : colorSecondary100Default,
    size: spacing.newLarge,
    textSize: sizes.small,
  };
  const numericValue: number = parseInt(`${value}`, 10);

  return (
    <BadgeComponent {...props}>
      {numericValue <= 99 ? numericValue : 99}
    </BadgeComponent>
  );
};

export default Badge;
