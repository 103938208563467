import type { PaginatedResponseGcp } from 'lib/utils/pagination';
export type SingleCategory = {
  id: number;
  name: string;
  vanityCategory?: string;
  label?: string;
  fullname?: string;
  parent?: SingleCategory;
};

export type Category = SingleCategory & {
  id: number;
  subcategories?: SingleCategory[];
};

export type PaginatedCategories = PaginatedResponseGcp<Category>;

export type Location = {
  label: string;
  postcode: string;
  county: string;
};

export type Skill = {
  id: number;
  label: string;
  iconName: string;
  subSkills: {
    id: number;
    label: string;
    iconName: string;
  }[];
};

export type TradeBasic = {
  id: string;
  location: string;
  name: string;
  ttrid: string;
  unique_name: string;
};

export type TradeBasicFormatted = {
  traderId: string;
  ttrId: string;
  label: string;
  value: string;
  location: string;
};

export type Badge = {
  key: string;
  label: string;
  type: string | null;
  trackingEventName: string;
};

export type Contact = {
  label: string;
  number?: string; // For Search @TODO change to href
  secure: boolean;
  calloutable24hr: boolean;
  // To handle links
  websiteUrl?: string; // For Search @TODO change to href
  href?: string; // For trade profiles
  icon?: string | null;
  id: number;
};

export type SearchTrade = {
  companyId: number;
  name: string;
  summary: string;
  operatingLocationText: string;
  displayState: {
    text: string;
    useModal: boolean;
    warning: boolean;
    catIcon: boolean;
    modalContent: string | null;
    modalKey?: string;
  };
  groupId?: string;
  logoUrl: string;
  logoUrlTemplate: string;
  address: {
    locality: string;
    region: string;
  };
  reviewsSummary: {
    recentMeanScore: number;
    totalReviews: number;
    totalRecentReviews: number;
  };
  contact: {
    // TODO CATS-1056 to address the shape in the pacts
    name: string; // Personal name
    primary: Contact | null;
    contacts: Contact[];
  };
  view: {
    showClaim: boolean;
    showMessage: boolean;
    showNewToCheckatrade: boolean;
    showLogo: boolean;
  };
  uniqueName: string;
  trackingBadges: string[];
  // eslint-disable-next-line camelcase
  request_id?: string;
  badges: Badge[];
};

export type PaginatedV3SearchTrades = PaginatedResponseGcp<SearchTrade> & {
  // eslint-disable-next-line camelcase
  request_id?: string;
  meta: {
    category: {
      id: number;
      referenceId: number;
      label: string;
      parentId: number;
      isAlias: boolean;
    };
  };
};

export type TradeBasicGcp = {
  companyId: number;
  name: string;
  uniqueName: string;
  tradeId: string;
  location: string | null;
  searchByCategory: boolean;
  tradeProfileUrl: string;
  aliases: string[];
  basedInAddress: string;
  logoUrl: string;
  logoUrlTemplate: string;
  skills: Skill[];
};

export type PaginatedTradesBasicGcp = PaginatedResponseGcp<TradeBasicGcp>;

export type PaginatedTrades = PaginatedResponseGcp<SearchTrade> & {
  // eslint-disable-next-line camelcase
  request_id?: string;
  meta: {
    requestId: string;
    seoData: {
      metaTitle: string;
      metaDescription: string;
      noIndex: boolean;
      noFollow: boolean;
      localBusiness: LocalBusiness;
    };
    category: {
      id: number;
      isAlias: boolean;
      isParent: boolean;
      label: string;
      name: string;
      parentId: number;
      referenceId: number;
    };
  };
  view: {
    /**
     * Used to determine if RAQ message form shows as Inline form variant
     *  due to national accounts being present in search category
     */
    showMessage: boolean;
  };
};

export type LocalBusiness = {
  '@type': string;
  '@context': string;
  name: string;
  address: {
    '@type': string;
    '@context': string;
    streetAddress: string | null;
    addressLocality: string | null;
    addressRegion: string | null;
    postalCode: string | null;
    addressCountry: string | null;
  };
  telephone: string | null;
  url: string;
  logo: string | null;
  image: string | null;
  priceRange: string | null;
  aggregateRating: {
    '@type': string;
    ratingValue: number | string;
    ratingCount: number;
    bestRating: number | string;
    worstRating: number | string;
  };
  description: string | null;
  areaServed: string | null;
  knowsAbout: string | null;
  openingHours: string | null;
};

export enum MemberBadge {
  ONE_YEAR = 'oneYear', // Deprecated
  THREE_YEAR = 'threeYear', // Deprecated
  FIVE_YEAR = 'fiveYear',
  TEN_YEAR = 'tenYear',
  FIFTEEN_YEAR = 'fifteenYear',
  TWENTY_YEAR = 'twentyYear',
  NONE = 'none',
}

export type AltTrades = {
  postcode: string;
  items: SearchTrade[];
  meta: {
    currentPage: number;
    itemsPerPage: number;
    totalPages: number;
    totalResults: number;
    requestId: string;
  };
};

export type GalleryItem = {
  id: number | string;
  type: 'PHOTO' | 'VIDEO';
  title: string;
  description: string;
  label: string; // before, during, after
  urlTemplate: string;
  dateFormatted: string | null;
  youTubeId?: string;
};

export type Gallery = {
  id: number | string;
  title: string;
  items: GalleryItem[];
};

export type RelatedSearchesData = {
  relatedSearchTown: string;
  relatedSearchTownUrlFormat: string;
  searches: {
    label: string;
    labelUrlFormat: string;
  }[];
};

export type Accreditation = {
  id: number;
  label: string;
  logoUrl: string | null;
  url: string | null;
};

export type Service = {
  id: number;
  icon: string;
  label: string;
};

export type Review = {
  reviewId: string;
  title: string;
  score: number;
  scorePercent: number;
  verified: boolean;
  body: string;
  location: string;
  reviewedDate: string; // NOT DATE OBJECT - but human readable Month + Year
  tradeResponse?: string;
  openOnLoad: boolean;
  showBreakdowns: boolean;
  breakdowns: {
    label: string;
    value: number;
  }[];
};

export type ReviewsSummary = {
  recentMeanScorePercent: number | string; // To handle the toFixed(2) JS bug issue....
  recentMeanScore: number;
  totalRecentReviews: number;
  totalReviews: number;
  recentMeanScoreDescription: string;
  breakdowns: TradeReviewsBreakdown[];
};

export type TradeReviewsBreakdown = {
  key: string;
  label: string;
  score: number;
  scorePercent: number; // To show the completeness of the circle.
  subLabel?: string;
  color: string; // Hex Value
};

export type Trade = Omit<SearchTrade, 'groupId'> & {
  description: string;
  reviewsSummary: ReviewsSummary;
  view: {
    showAltTrades: boolean;
    showMemberBadge: boolean;
    showGallery: boolean;
    showMessage: boolean;
    showShortlist: boolean;
    showReviewsList: boolean;
    showReviewsBreakdown: boolean;
    showReviewsHeroBreakdown: boolean;
    showReviewsDisclaimer: boolean;
    showChecklists: boolean;
    showNewToCheckatrade: boolean;
    showContactName: boolean;
    showLogo: boolean;
    showDescription: boolean;
    showContactDetailsBlock: boolean;
    showContactDetailsHero: boolean;
    showShareAndSave: boolean;
    showPliExpiredWarning: boolean;
    showClaim: boolean; // On the trade page - show the 'Claim this trade' button
    showVettingExpired: boolean;
    showRelatedSearches: boolean;
    heroWebsiteUrl: string | null;
    showWebsiteUrl: boolean;
  };

  // For the Search Bar
  primaryCategory: {
    id: number;
    label: string;
  };

  // Renamed categories to make sure semantically correct
  skills: Skill[];

  relatedSearches?: RelatedSearchesData;

  reviews: Review[];

  // Southampton, Eastleigh, Romsey, Hampshire (All Areas) etc
  worksInText: string;

  // For the full address (Hedge End, Southampton, Hampshire)
  basedInAddress: string;

  // Use the ID's for react/knowing what is being displayed
  // Also do we deep link to gallery items?
  galleries: Gallery[];

  heroBannerUrl: string | null;

  accreditation: {
    summary: string;
    accreditations: Accreditation[];
  };

  // Ensure that this is mapped to the trader.verification_show
  // Also make sure that the label matches the `block_name` as label will be
  // human readable and the block_name looks like it's snake....
  vettingInformation: {
    // The 3 expandable sections
    // Member Details | Company Status | Insurance Details or Member since YYYY
    label: string;

    // Used for Freemium - won't allow the section to be opened/show any info.
    // Not sure whether to put this in the 'view' block? (but then each vetting information
    // would need to be known about ahead of time.
    active: boolean; // Some way of 'locking' the expand with freemium
    status: string | null; // 'To Be Confirmed' for freemium

    // NEW FOR TRACKING
    key: 'interview' | 'status' | 'insurance';

    error: boolean;
    //
    infos: {
      alert: boolean; // blue/red // use for valid value too
      label: string;
      passed: boolean; // tick/cross // Need to use the passed + error
      value: string;
    }[];
  }[];

  // The block of services and icons
  services: Service[];

  // Optional block - but the text can be at times, one of 3 values - so can't be boolean based
  verificationSummary: {
    // This Trade Profile is not a Checkatrade member
    // This Trade is no longer a Checkatrade Member
    headerTitle?: string;
    fullyVetted: boolean; // Handles the icon or not
    hasWarning: boolean; // red/blue highlight
    titleOnly: boolean; // Handles if there should be a content block
    // No longer vetted by Checkatrade
    // Fully Vetted Trade
    // Not vetted by Checkatrade
    // Partially Vetted Trade
    summaryTitle: string;

    // Accreditations / Insurance / Company Type / Address / ID / References / Checkatrade T&C's
    items: {
      label: string;
      value: string;
    }[];
  };

  // Needs a better name but it's to cover off the block of json that we generate for SEO
  // purposes.
  seoData: {
    // This is currently generated on the Client - but should be on the API
    schema: string;
    breadcrumb: string; // Is this only needed on Search Page?
    seo: {
      topServices: string;
      topService: string;
      town?: string;
      address: string;
    };
    pageTitle: string;
    description: string;
    canonical: string;
  };

  memberDetails?: {
    memberSince: string;
    acquiredBadge: MemberBadge;
  };

  altTrades?: AltTrades;
};

export type getTradeWorkRadiusArgs = {
  categoryId: number;
  companyId: string;
  postcode: string;
};

export type tradeWorkRadiusRes = {
  type: string;
  title: string;
  status: number;
  traceId: string;
};

export type PaginatedReviews = PaginatedResponseGcp<Trade['reviews'][0]>;

export type searchCategoriesOptions = {
  category: string;
  abortSignal?: AbortSignal;
};
