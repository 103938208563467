import styled from 'styled-components';
import colors from '@toolshed/styleguide/styles/colors';
import { regular } from '@toolshed/styleguide/styles/typography';
import Link from 'next/link';
import A from '@toolshed/cat-com-wrapper/components/A';
import { Section, SectionWithNav, Item, Content, Inner } from './styles';
import X from 'public/static/icon-x-logo.svg';
import Facebook from 'public/static/icon-facebook-logo.svg';
import LinkedIn from 'public/static/icon-linkedin-logo.svg';
import YouTube from 'public/static/icon-youtube-logo.svg';
import Instagram from 'public/static/icon-instagram-logo.svg';
import {
  default900,
  default700,
  neutral0,
  spacing3,
  spacing5,
} from 'lib/mortar';
import type { OnTrackEventArgs } from 'containers/AppContainer/AppContainer.interfaces';

type FooterLink = {
  label: string;
  url: string;
  isExternal?: boolean;
};

const footerLinks = [
  {
    url: '/consumer',
    label: 'About us',
  },
  {
    label: 'Terms of use',
    url: '/platform-terms-of-use',
  },
  {
    url: '/privacy',
    label: 'Privacy',
  },
  {
    url: '/cookie-policy',
    label: 'Cookies',
  },
  {
    label: 'Contact us',
    url: 'https://support.checkatrade.com/',
    isExternal: true,
  },
  {
    url: '/sitemap',
    label: 'Sitemap',
  },
  {
    url: 'https://careers.checkatrade.com/',
    label: 'Careers',
    isExternal: true,
  },
] satisfies FooterLink[];

export const Wrapper = styled.footer`
  ${regular('small')};
  border-top: 4px solid ${colors.skyBlue};
  background-color: ${colors.lightest};
  content-visibility: auto;
  contain-intrinsic-size: 339px; // Half way between 193px (on desktop) and 485 on mobile
  flex-shrink: 0;

  a:link,
  a:hover,
  a:visited {
    text-decoration: none;
    cursor: pointer;
  }

  a:hover ${Item} {
    color: ${default900};
  }
`;

export const Social = styled.nav`
  display: flex;
  gap: ${spacing5};
  margin-bottom: ${spacing3};
  justify-content: center;
`;

export const SocialLink = styled(A)`
  display: flex;
  flex-direction: row;
  height: 30px;
  width: 30px;
  box-sizing: border-box;
  background-color: ${default700};
  padding: 7px;
  border-radius: 50%;

  path {
    fill: ${neutral0};
  }

  &:hover {
    background-color: ${neutral0};

    path {
      fill: ${default700};
    }
  }
`;

export const Copyright = styled.p`
  ${regular('base')};
  text-align: center;
  color: ${colors.medium};
  box-sizing: border-box;
  padding-bottom: ${spacing5};
  margin: 0 ${spacing3};
`;

type FooterProps = {
  onTrackEvent: (event: OnTrackEventArgs) => void;
};

export const Footer = ({ onTrackEvent }: FooterProps) => (
  <Wrapper data-guid="FooterWrapper">
    <Inner>
      <Content>
        <SectionWithNav aria-label="About Company">
          {footerLinks.map(({ url, label, isExternal }) =>
            isExternal ? (
              <A href={url} onTrackEvent={onTrackEvent} key={label}>
                <Item>{label}</Item>
              </A>
            ) : (
              <Link href={url} key={label} prefetch={false}>
                <Item>{label}</Item>
              </Link>
            ),
          )}
        </SectionWithNav>
        <Section>
          <Social aria-label="Social Media">
            <SocialLink
              href="https://x.com/Checkatrade"
              onTrackEvent={onTrackEvent}
              aria-label="X"
            >
              <X />
            </SocialLink>

            <SocialLink
              href="https://www.facebook.com/VettedLtd"
              onTrackEvent={onTrackEvent}
              aria-label="Facebook"
            >
              <Facebook />
            </SocialLink>

            <SocialLink
              href="https://www.instagram.com/Checkatrade/"
              onTrackEvent={onTrackEvent}
              aria-label="Instagram"
            >
              <Instagram />
            </SocialLink>

            <SocialLink
              href="https://www.youtube.com/user/checkatrade"
              onTrackEvent={onTrackEvent}
              aria-label="YouTube"
            >
              <YouTube />
            </SocialLink>

            <SocialLink
              href="https://www.linkedin.com/company/checkatrade/"
              onTrackEvent={onTrackEvent}
              aria-label="LinkedIn"
            >
              <LinkedIn />
            </SocialLink>
          </Social>
        </Section>
      </Content>

      <Copyright>© Checkatrade.com all rights reserved</Copyright>
    </Inner>
  </Wrapper>
);

export default Footer;
