// pages/_app.js
import '../global.css';
import React from 'react';
import { Provider } from 'react-redux';
import { compose } from 'redux';
import App from 'next/app';
import Head from 'next/head';
import withRedux from 'next-redux-wrapper';
import makeStore from '@redux/make-store';
import CreateGlobalStyle from '@styles/global-style';
import AppContainer from 'containers/AppContainer/AppContainer';
import Script from 'next/script';

import { headerHeight } from 'lib/mortar/design-tokens/index.ts';

const GlobalStyle = CreateGlobalStyle();

class MyApp extends App {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static async getInitialProps({ Component, ctx }) {
    const appProps = AppContainer.getInitialProps
      ? await AppContainer.getInitialProps(ctx)
      : {};
    if (typeof Component.injectReducer === 'function') {
      Component.injectReducer(ctx);
    }

    if (typeof Component.injectSaga === 'function') {
      Component.injectSaga(ctx);
    }

    const pageProps = Component.getInitialProps
      ? await Component.getInitialProps(ctx)
      : {};

    await ctx.store.done(ctx.isServer);

    return {
      ...pageProps,
      appProps,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { Component, appProps, pageProps = {}, store, ...rest } = this.props;

    if (this.state.hasError) {
      // Needs static error page
      return null;
    }

    // https://github.com/vercel/next.js/issues/13230
    return (
      <>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, minimum-scale=1"
          />
          <style>
            {`
        html {
          scroll-padding-top: calc(${headerHeight} * 2);
          scroll-behavior: smooth;
        }
        `}
          </style>
        </Head>
        {/* Can be removed once more of our users are supported by webp */}
        <Script id="check-webp">
          {`
            var webpImg = new Image();
            webpImg.onerror = function() {
              document.body.classList.remove('webp-supported');
              document.body.classList.add('no-webp-supported');
            };
            webpImg.src = "data:image/webp;base64,UklGRjIAAABXRUJQVlA4ICYAAACyAgCdASoBAAEALmk0mk0iIiIiIgBoSygABc6zbAAA/v56QAAAAA==";
          `}
        </Script>
        <GlobalStyle />
        <Provider store={store}>
          <AppContainer
            Component={Component}
            pageProps={pageProps}
            appProps={appProps}
            store={store}
            {...rest}
          />
        </Provider>
      </>
    );
  }
}

export default compose(withRedux(makeStore, { storeKey: 'cat-next' }))(MyApp);
