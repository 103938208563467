import getUniqueNameFromTrade from '@utils/get-unique-name-from-trade';
import type { AppState } from './initial-state';
import initialState from './initial-state';

import {
  LOCATION_CHANGE,
  SET_MODAL,
  SET_GLOBAL_ERROR,
  ADD_TO_SHORTLIST,
  REMOVE_FROM_SHORTLIST,
  LOCAL_STORAGE_CHANGE,
  LOCAL_STORAGE_LOADED,
  LOCAL_STORAGE_NO_ACCESS,
  CLEAR_SHORTLIST,
  GET_CATEGORIES_INIT,
  GET_CATEGORIES_SUCCESS,
  SET_MESSAGE_TRADER_SENT,
  SEND_A_JOB_SET_STEP,
  ADD_RECENT_SEARCH,
  REMOVE_RECENT_SEARCH,
  MAX_RECENT_SEARCHES_LIMIT,
  GET_TRADE_BY_NAME_INIT,
  GET_TRADE_BY_NAME_SUCCESS,
  GET_TRADE_BY_NAME_ERROR,
  SET_MOST_RECENT_DESCRIPTION,
} from './constants';
import type { Actions as AppActionTypes } from './actions';
import formatLocation from '@utils/format-location';

const categoryAndLocationFilter =
  ({
    categoryId: categoryIdToMatch,
    location: locationToMatch,
  }: {
    categoryId: number;
    location: string;
  }) =>
  ({ categoryId, location }: { categoryId: number; location: string }) => {
    const sameLocation =
      formatLocation(locationToMatch) === formatLocation(location);
    const sameCategory = categoryIdToMatch === categoryId;

    return !(sameLocation && sameCategory);
  };

const appReducerFactory = (
  { query, isServer }: { query: { user?: unknown }; isServer: boolean } = {
    query: {},
    isServer: false,
  },
) => {
  const initialStateWithUser =
    isServer && query
      ? {
          ...initialState,
          user: query.user,
        }
      : initialState;

  return (state = initialStateWithUser, action: AppActionTypes): AppState => {
    switch (action.type) {
      case SET_MODAL: {
        const { name } = action.payload;

        let newState = { ...state, modal: action.payload };

        if (name === 'shortlist') {
          newState = {
            ...newState,
            shortlist: { ...newState.shortlist, modalShown: true },
          };
        }

        return newState;
      }

      case SET_GLOBAL_ERROR: {
        return {
          ...state,
          error: {
            error: action.payload,
            status: action.payload.status,
          },
        };
      }

      case LOCATION_CHANGE: {
        if (state.url && state.url !== action.payload.url) {
          return {
            ...state,
            url: action.payload.url,
            error: undefined,
          };
        }

        return {
          ...state,
          url: action.payload.url,
        };
      }

      // SHORTLIST
      case ADD_TO_SHORTLIST: {
        const data = state.shortlist.data.concat([action.payload]);

        return {
          ...state,
          shortlist: {
            ...state.shortlist,
            data,
          },
        };
      }

      case REMOVE_FROM_SHORTLIST: {
        const data = state.shortlist.data.filter(
          item => getUniqueNameFromTrade(item) !== action.payload.uniqueName,
        );

        return {
          ...state,
          shortlist: {
            ...state.shortlist,
            data,
          },
        };
      }

      case CLEAR_SHORTLIST: {
        return {
          ...state,
          shortlist: {
            ...state.shortlist,
            data: [],
          },
        };
      }

      case LOCAL_STORAGE_CHANGE: {
        const {
          payload: {
            global: {
              shortlist: { data, modalShown },
              messageTraderHistory,
              recentSearches,
            },
            meta: { time },
          },
        } = action;

        return {
          ...state,
          localStorageAccess: true,
          recentSearches: recentSearches || state.recentSearches,
          shortlist: {
            ...state.shortlist,
            data,
            modalShown,
          },
          messageTraderHistory: (messageTraderHistory || []).filter(
            ({ expires }) => expires > time,
          ),
        };
      }

      case LOCAL_STORAGE_LOADED: {
        return {
          ...state,
          localStorageLoaded: true,
        };
      }

      case LOCAL_STORAGE_NO_ACCESS: {
        return {
          ...state,
          localStorageAccess: false,
          modal: { name: 'localStorage' },
        };
      }

      // SEARCH
      case GET_CATEGORIES_INIT:
        return { ...state, categoriesLoading: true };

      case GET_CATEGORIES_SUCCESS: {
        const { data } = action.payload;

        return {
          ...state,
          categories: data,
          categoriesLoading: false,
        };
      }

      // MESSAGES
      case SET_MOST_RECENT_DESCRIPTION: {
        return {
          ...state,
          mostRecentDescription: action.payload.description,
        };
      }

      case SET_MESSAGE_TRADER_SENT: {
        const { companyId, uniqueName } = state.messageTrader;
        return {
          ...state,
          messageTrader: {
            companyId: null,
            uniqueName: null,
          },
          messageTraderHistory: [
            ...state.messageTraderHistory,
            {
              companyId,
              uniqueName,
              correlationId: action.payload.correlationId,
            },
          ],
        };
      }

      // SEND A JOB
      case SEND_A_JOB_SET_STEP: {
        return {
          ...state,
          sendAJob: {
            ...state.sendAJob,
            step: action.payload.step,
          },
        };
      }

      case GET_TRADE_BY_NAME_INIT: {
        return {
          ...state,
          traderByName: {
            ...state.traderByName,
            loading: true,
          },
        };
      }

      case GET_TRADE_BY_NAME_SUCCESS: {
        const { data } = action.payload;

        return {
          ...state,
          traderByName: {
            ...state.traderByName,
            loading: false,
            data,
            error: undefined,
          },
        };
      }

      case GET_TRADE_BY_NAME_ERROR: {
        return {
          ...state,
          traderByName: {
            ...state.traderByName,
            loading: false,
            data: undefined,
            error: action.payload,
          },
        };
      }

      case ADD_RECENT_SEARCH: {
        const newSearch = action.payload;
        const filteredSearchResults = state.recentSearches.filter(
          categoryAndLocationFilter(newSearch),
        );

        return {
          ...state,
          recentSearches: [newSearch, ...filteredSearchResults].slice(
            0,
            MAX_RECENT_SEARCHES_LIMIT,
          ),
        };
      }

      case REMOVE_RECENT_SEARCH: {
        const filteredSearchResults = state.recentSearches.filter(
          categoryAndLocationFilter(action.payload),
        );

        return {
          ...state,
          recentSearches: filteredSearchResults,
        };
      }

      default: {
        return state;
      }
    }
  };
};

export default appReducerFactory;
