import { useContext, useMemo } from 'react';
import type { FirestoreError } from 'firebase/firestore';
import {
  doc,
  collection,
  setDoc,
  query,
  orderBy,
  serverTimestamp,
} from 'firebase/firestore';
import { formatPostcodeForFirebase } from '@utils/postcode-validation';
import { FirestoreContext } from './context';
import type {
  FirebaseUser,
  FirestoreJob,
  Job,
  Review,
  UserProfile,
} from './types';
import { useAuthContext, useCurrentUser } from '../auth';
import { useCollectionData, useDocumentData } from './utils/hooks';

export const useFirestore = () => {
  const firestore = useContext(FirestoreContext);
  if (!firestore) {
    throw new Error(
      'useFirestore must be used within Firestore context provider',
    );
  }

  return firestore;
};

export const useUser = (userId: string | null) => {
  const firestore = useFirestore();

  const ref = useMemo(() => {
    if (!userId) {
      return null;
    }

    return doc(firestore, `users/${userId}`);
  }, [firestore, userId]);

  return useDocumentData<FirebaseUser>(firestore, ref);
};

export const useUserProfile = (): [UserProfile | null, boolean] => {
  const { currentUser: authUser, loading: authUserLoading } = useAuthContext();
  const [user, userLoading] = useUser(authUser?.uid || null);

  const userProfile = useMemo(
    () =>
      authUser && user
        ? {
            ...user,
            email: authUser.email,
            emailVerified: authUser.emailVerified,
            uid: authUser.uid,
            displayName: authUser.displayName,
          }
        : null,
    [authUser, user],
  );

  return [userProfile, authUserLoading || userLoading];
};

export const useUpsertUserDocument = () => {
  const firestore = useFirestore();
  const {
    callbacks: { onAccountUpdated },
  } = useAuthContext();

  return async ({
    email,
    userId,
    emailVerified,
    firstName,
    lastName,
    postcode,
    phoneNumber,
    isCreate,
  }: {
    userId: string;
    firstName?: string;
    lastName?: string;
    email?: string | null;
    emailVerified?: boolean;
    postcode?: string;
    phoneNumber?: string;
    isCreate?: boolean;
  }) => {
    await setDoc(
      doc(firestore, `users/${userId}`),
      {
        ...(typeof email === 'undefined' ? {} : { email }),
        ...(typeof emailVerified === 'undefined' ? {} : { emailVerified }),
        ...(typeof firstName === 'undefined' || typeof lastName === 'undefined'
          ? {}
          : {
              name: {
                first: firstName.trim(),
                last: lastName.trim(),
              },
            }),
        ...(typeof postcode === 'undefined'
          ? {}
          : { postcode: formatPostcodeForFirebase(postcode) }),
        ...(typeof phoneNumber === 'undefined'
          ? {}
          : { phoneNumber: phoneNumber.trim() }),
        ...(isCreate
          ? {
              dateCreated: serverTimestamp(),
            }
          : {}),
        dateUpdated: serverTimestamp(),
      },
      {
        merge: true,
      },
    );
    onAccountUpdated();
  };
};

const getJobFromFirestoreJob = (firestoreJob: FirestoreJob): Job => ({
  ...firestoreJob,
  leads:
    firestoreJob.leads?.map(firestoreLead => ({
      companyId: firestoreLead.tradeId,
      status: firestoreLead.status,
      dateUpdated: firestoreLead.dateUpdated,
      dateCreated: firestoreLead.dateCreated,
    })) || [],
});

export const useVerifiedJobs = (): [
  jobs: Job[],
  loading: boolean,
  error: FirestoreError | undefined,
] => {
  const firestore = useFirestore();
  const currentUser = useCurrentUser();

  const ref = useMemo(() => {
    if (!currentUser) {
      return null;
    }

    return query(
      collection(firestore, `users/${currentUser.uid}/jobs`),
      orderBy('dateCreated', 'desc'),
    );
  }, [firestore, currentUser]);

  const [fireStoreJobs, loading, error] = useCollectionData<FirestoreJob>(
    firestore,
    ref,
  );

  return [fireStoreJobs.map(getJobFromFirestoreJob), loading, error];
};

export const useUnverifiedJobs = (): [
  jobs: Job[],
  loading: boolean,
  error: FirestoreError | undefined,
] => {
  const firestore = useFirestore();
  const currentUser = useCurrentUser();

  const ref = useMemo(() => {
    if (!currentUser || !currentUser.emailVerified) {
      return null;
    }

    return query(
      collection(firestore, `users/${currentUser.uid}/unverified-jobs`),
      orderBy('dateCreated', 'desc'),
    );
  }, [firestore, currentUser]);

  const [fireStoreJobs, loading, error] = useCollectionData<FirestoreJob>(
    firestore,
    ref,
  );

  return [fireStoreJobs.map(getJobFromFirestoreJob), loading, error];
};

export const useJobForSoftSignedInUser = () => {
  const firestore = useFirestore();
  const currentUser = useCurrentUser();

  const ref = useMemo(() => {
    if (!currentUser?.uid || currentUser.uid.length <= 4) {
      return null;
    }

    const jobId = currentUser.uid.substring(4);
    return doc(firestore, `jobs/${jobId}`);
  }, [firestore, currentUser]);

  return useDocumentData<Job>(firestore, ref);
};

export const useJobs = (): [
  jobs: Job[],
  loading: boolean,
  error: FirestoreError | undefined,
] => {
  const [jobs, jobsLoading, jobsError] = useVerifiedJobs();
  const [unverifiedJobs, unverifiedJobsLoading] = useUnverifiedJobs();

  if (jobsLoading || unverifiedJobsLoading) {
    return [[], true, undefined];
  }

  if (jobsError) {
    return [[], false, jobsError];
  }

  const mergedJobs = [...jobs, ...unverifiedJobs].reduce(
    (map: Map<string, Job>, job) => {
      if (!map.has(job.id)) {
        map.set(job.id, job);
      }

      return map;
    },
    new Map(),
  );

  return [
    Array.from(mergedJobs.values()).sort(
      (a: Job, b: Job) =>
        (b.dateCreated?.seconds || 0) - (a.dateCreated?.seconds || 0),
    ),
    false,
    undefined,
  ];
};

export const useReviewsByUser = (
  userId?: string,
): [Review[] | undefined, boolean, Error | undefined] => {
  const firestore = useFirestore();

  const ref = useMemo(() => {
    if (!userId) {
      return null;
    }

    return query(
      collection(firestore, `users/${userId}/reviews`),
      orderBy('dateCreated', 'desc'),
    );
  }, [firestore, userId]);

  return useCollectionData<Review>(firestore, ref);
};
