import { debugLog } from 'lib/utils/debug';
import type { Plugin, EventName } from '../../types';

declare global {
  interface Window {
    ga?: (
      method: string,
      payload: string | Record<string, any>,
      extra?: string | number,
    ) => void;
  }
}

const ga: typeof window.ga = (...args) => {
  debugLog('GA: ', ...args);
  if (window.ga) {
    window.ga(...args);
  }
};

export const GA: Plugin = event => {
  if (!window.ga) {
    return;
  }

  if (
    (['started', 'step_changed', 'stopped', 'cleared'] as EventName[]).includes(
      event.name,
    )
  ) {
    return;
  }

  if (event.name === 'global.page_view') {
    const experimentString = (event.context.app.experiments || [])
      .map(experiment => {
        return `${experiment.id}.${experiment.variant}`;
      })
      .join('!');

    ga('set', 'exp', experimentString);

    ga('send', {
      hitType: 'pageview',
      page: `${event.pageUrl.pathname}${event.pageUrl.search || ''}`,
    });
    return;
  }

  ga('send', {
    hitType: 'event',
    eventCategory: 'All',
    eventAction: event.description,
    // TODO: check if its ok to use event name here instead of description,
    // this is the only thing description is used for and it would be good to remove it
  });
};
