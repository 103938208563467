import { useSignOut } from '@fire/auth';
import { useSession as useNextAuthSession } from 'next-auth/react';
import { logout } from './auth';

/**
 * Gets the session data for the current user
 */
export const useSession = () => {
  const session = useNextAuthSession();

  return session;
};

/**
 * Gets the current authentication status of the user
 */
export const useAuthStatus = () => {
  const session = useSession();

  return session.status;
};

/**
 * Returns true if the user is authenticated
 */
export const useIsAuthenticated = () => {
  const session = useSession();

  if (session.status !== 'authenticated') return false;

  if (session.data.error) {
    return false;
  }

  return !session.data.user.isNewUser;
};

export const useAuthToken = () => {
  const session = useSession();

  if (session.status !== 'authenticated') return null;

  return session.data.token.accessToken;
};

export const useLogout = () => {
  const logoutFirebase = useSignOut();

  return async () => {
    await logoutFirebase();
    logout();
  };
};

/**
 * Returns true if the user is a new user
 */
export const useIsNewUser = () => {
  const session = useSession();

  return !!session.data?.user.isNewUser;
};

/**
 * Returns The user id of the current session
 */
export const useSessionUserId = () => {
  const session = useSession();

  return session.data?.user.id;
};

/**
 * Returns The user id of the current session
 */
export const useSessionUserEmail = () => {
  const session = useSession();

  return session.data?.user.email;
};
