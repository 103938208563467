import type { ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';

import ArrowRight from './assets/arrow-right.svg';
import ArrowRightCircleFill from './assets/arrow-right-circle-fill.svg';
import Award from './assets/award.svg';
import Builder from './assets/builder.svg';
import Calendar from './assets/calendar.svg';
import CalendarMonthFill from './assets/calendar-month-fill.svg';
import CentralHeating from './assets/central-heating.svg';
import ChatText from './assets/chat-text.svg';
import ChatTextLong from './assets/chat-text-long.svg';
import CheckatradeTick from './assets/checkatrade-tick.svg';
import CheckCircleFill from './assets/check-circle-fill.svg';
import CheckMark from './assets/check-mark.svg';
import ChevronLeft from './assets/chevron-left.svg';
import ChevronRight from './assets/chevron-right.svg';
import ChevronUp from './assets/chevron-up.svg';
import Clock from './assets/clock.svg';
import ClockFill from './assets/clock-fill.svg';
import ClockLoader from './assets/clock-loader.svg';
import ClockThreeFill from './assets/clock-three-fill.svg';
import Close from './assets/close.svg';
import CloseCircle from './assets/close-circle.svg';
import CloseCircleFill from './assets/close-circle-fill.svg';
import Company from './assets/company.svg';
import ContactPage from './assets/contact-page.svg';
import DocumentTickFill from './assets/document-tick-fill.svg';
import Electrician from './assets/electrician.svg';
import EmailCircleFill from './assets/email-circle-fill.svg';
import EmailWithLink from './assets/email-with-link.svg';
import Envelope from './assets/envelope.svg';
import FancyCheck from './assets/fancy-check.svg';
import File from './assets/file.svg';
import FilterList from './assets/filter-list.svg';
import FireplacesStoves from './assets/fireplaces-stoves.svg';
import GuaranteedMark from './assets/guaranteed-mark.svg';
import HomeownerJobs from './assets/homeowner-jobs.svg';
import HouseholdWaterTreatment from './assets/household-watertreatment.svg';
import InfoFill from './assets/info-fill.svg';
import Leaf from './assets/leaf.svg';
import Loading from './assets/loading.svg';
import LocationPinFill from './assets/location-pin-fill.svg';
import LocationPinOutline from './assets/location-pin-outline.svg';
import LocationPinTickFill from './assets/pin-tick-fill.svg';
import LockKey from './assets/lock-key.svg';
import OpenLink from './assets/open-link.svg';
import PersonFill from './assets/person-fill.svg';
import PersonMagnifyFill from './assets/person-magnify-fill.svg';
import PhoneFill from './assets/phone-fill.svg';
import Plumber from './assets/plumber.svg';
import PoundSterling from './assets/pound-sterling.svg';
import Quote from './assets/quote.svg';
import Roofer from './assets/roofer.svg';
import Search from './assets/search.svg';
import SendMessage from './assets/send-message.svg';
import ShieldFill from './assets/shield-fill.svg';
import ShieldHalfFill from './assets/shield-half-fill.svg';
import ShieldTickFill from './assets/shield-tick-fill.svg';
import SMSCircleFill from './assets/sms-circle-fill.svg';
import Star from './assets/star.svg';
import ThumbsUpCircle from './assets/thumbs-up-circle.svg';
import ThumbsUpCircleFill from './assets/thumbs-up-circle-fill.svg';
import Tick from './assets/tick.svg';
import TickNoBorder from './assets/tick-no-border.svg';
import UserFill from './assets/user-fill.svg';
import WarningCircle from './assets/warning-circle.svg';
import WarningCircleFill from './assets/warning-circle-fill.svg';
import WarningTriangleFill from './assets/warning-triangle-fill.svg';
import Website from './assets/website.svg';

import {
  colorBackgroundRed,
  colorBlueGreyFull,
  colorError100Default,
  colorShadow,
  colorSuccess100Default,
} from '../../design-tokens';
import type { IconSize } from 'lib/mortar';
import {
  neutral600,
  default700,
  neutral0,
  primary700,
  attention500,
  tabbed500,
  Icon,
} from 'lib/mortar';

export interface IconProps {
  size?: IconSize;
  color?: string;
  className?: string;
  children?: ReactNode;
}

export const IconAward = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={default700}
    applyColorToStroke
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <Award />
  </Icon>
);

export const IconArrowRight = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={default700}
    applyColorToStroke
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <ArrowRight />
  </Icon>
);

export const IconArrowRightCircleFill = ({
  size,
  color,
  className,
}: IconProps) => (
  <Icon
    defaultColor={default700}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <ArrowRightCircleFill />
  </Icon>
);

export const IconCalendar = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={default700}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <Calendar />
  </Icon>
);
export const IconCalendarMonthFill = ({
  size,
  color,
  className,
}: IconProps) => (
  <Icon
    defaultColor={default700}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <CalendarMonthFill />
  </Icon>
);

export const IconCheckatradeTick = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={''}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <CheckatradeTick />
  </Icon>
);

export const IconChatText = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={default700}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <ChatText />
  </Icon>
);

export const IconChatTextLong = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={default700}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <ChatTextLong />
  </Icon>
);

export const IconCheckCircleFill = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={colorSuccess100Default}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <CheckCircleFill />
  </Icon>
);

export const IconCheckMark = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={colorBackgroundRed}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <CheckMark />
  </Icon>
);

export const IconChevronLeft = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={colorError100Default}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <ChevronLeft />
  </Icon>
);

export const IconChevronRight = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={colorError100Default}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <ChevronRight />
  </Icon>
);

export const IconChevronUp = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={colorError100Default}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <ChevronUp />
  </Icon>
);

export const IconClock = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={colorShadow}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <Clock />
  </Icon>
);
export const IconClockLoader = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={colorShadow}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <ClockLoader />
  </Icon>
);

export const IconClockFill = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={colorShadow}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <ClockFill />
  </Icon>
);

export const IconClockThreeFill = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={colorShadow}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <ClockThreeFill />
  </Icon>
);

export const IconCompany = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={colorShadow}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <Company />
  </Icon>
);

export const IconClose = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={colorShadow}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <Close />
  </Icon>
);

export const IconCloseCircleFill = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={colorShadow}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <CloseCircleFill />
  </Icon>
);

export const IconCloseCircle = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={colorShadow}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <CloseCircle />
  </Icon>
);

export const IconContactPage = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={attention500}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <ContactPage />
  </Icon>
);

export const IconDocumentTickFill = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={attention500}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <DocumentTickFill />
  </Icon>
);

export const IconEnvelope = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={attention500}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <Envelope />
  </Icon>
);

export const IconElectrician = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={colorBackgroundRed}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <Electrician />
  </Icon>
);

export const IconPlumber = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={colorBackgroundRed}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <Plumber />
  </Icon>
);

export const IconRoofer = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={colorBackgroundRed}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <Roofer />
  </Icon>
);

export const IconBuilder = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={colorBackgroundRed}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <Builder />
  </Icon>
);

export const IconCentralHeating = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={colorBackgroundRed}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <CentralHeating />
  </Icon>
);

export const IconEmailCircleFill = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={colorSuccess100Default}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <EmailCircleFill />
  </Icon>
);

export const IconEmailWithLink = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={default700}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <EmailWithLink />
  </Icon>
);

export const IconFancyCheck = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={primary700}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <FancyCheck />
  </Icon>
);

export const IconFile = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={default700}
    applyColorToStroke
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <File />
  </Icon>
);

export const IconFilterList = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={default700}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <FilterList />
  </Icon>
);

export const IconFireplacesStoves = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={colorBackgroundRed}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <FireplacesStoves />
  </Icon>
);

export const IconGuaranteedMark = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor=""
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <GuaranteedMark />
  </Icon>
);

export const IconHomeownerJobs = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={default700}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <HomeownerJobs />
  </Icon>
);

export const IconHouseholdWaterTreatment = ({
  size,
  color,
  className,
}: IconProps) => (
  <Icon
    defaultColor={colorBackgroundRed}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <HouseholdWaterTreatment />
  </Icon>
);

export const IconInfoFill = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={default700}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <InfoFill />
  </Icon>
);

export const IconLeaf = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={colorBackgroundRed}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <Leaf />
  </Icon>
);

export const IconLoading = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={default700}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <Loading />
  </Icon>
);

export const IconLocationPinFill = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={colorError100Default}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <LocationPinFill role="presentation" />
  </Icon>
);

export const IconLocationPinOutline = ({
  size,
  color,
  className,
}: IconProps) => (
  <Icon
    defaultColor={neutral600}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <LocationPinOutline />
  </Icon>
);

export const IconLocationPinTickFill = ({
  size,
  color,
  className,
}: IconProps) => (
  <Icon
    defaultColor={colorError100Default}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <LocationPinTickFill />
  </Icon>
);

export const IconLockKey = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={attention500}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <LockKey />
  </Icon>
);

export const IconOpenLink = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={neutral600}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <OpenLink />
  </Icon>
);

export const IconPhoneFill = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={default700}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <PhoneFill />
  </Icon>
);

export const IconPersonFill = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={neutral600}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <PersonFill />
  </Icon>
);

export const IconPersonMagnifyFill = ({
  size,
  color,
  className,
}: IconProps) => (
  <Icon
    defaultColor={colorBlueGreyFull}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <PersonMagnifyFill />
  </Icon>
);
export const IconPoundSterling = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={neutral600}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <PoundSterling />
  </Icon>
);

export const IconQuote = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={colorShadow}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <Quote />
  </Icon>
);

export const IconSearch = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={default700}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <Search />
  </Icon>
);

export const IconSendMessage = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={default700}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <SendMessage />
  </Icon>
);

const IconText = styled.div({
  position: 'absolute',
  width: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  marginTop: '4px',
  color: 'white',
  fontSize: '10px',
  fontWeight: '600',
});

export const IconShieldFill = ({
  size,
  color,
  className,
  children,
}: IconProps) => (
  <Icon
    defaultColor={default700}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <ShieldFill />
    <IconText>{children}</IconText>
  </Icon>
);

export const IconShieldHalfFill = ({
  size,
  color,
  className,
  children,
}: IconProps) => (
  <Icon
    defaultColor={default700}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <ShieldHalfFill />
    <IconText>{children}</IconText>
  </Icon>
);

export const IconShieldTickFill = ({
  size,
  color,
  className,
  children,
}: IconProps) => (
  <Icon
    defaultColor={default700}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <ShieldTickFill />
    <IconText>{children}</IconText>
  </Icon>
);

export const IconSMSCircleFill = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={colorSuccess100Default}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <SMSCircleFill />
  </Icon>
);

export const IconStar = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={default700}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <Star />
  </Icon>
);

export const IconThumbsUpCircle = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={default700}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <ThumbsUpCircle />
  </Icon>
);

export const IconThumbsUpCircleFill = ({
  size,
  color,
  className,
}: IconProps) => (
  <Icon
    defaultColor={default700}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <ThumbsUpCircleFill />
  </Icon>
);

export const IconUserFill = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={default700}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <UserFill />
  </Icon>
);

export const IconTick = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={colorBackgroundRed}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <Tick />
  </Icon>
);

export const IconTickNoBorder = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={neutral0}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <TickNoBorder />
  </Icon>
);

export const IconWarningCircle = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={attention500}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <WarningCircle />
  </Icon>
);

export const IconWarningCircleFill = ({
  size,
  color,
  className,
}: IconProps) => (
  <Icon
    defaultColor={tabbed500}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <WarningCircleFill />
  </Icon>
);

export const IconWarningTriangleFill = ({
  size,
  color,
  className,
}: IconProps) => (
  <Icon
    defaultColor={colorError100Default}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <WarningTriangleFill />
  </Icon>
);

export const IconWebsite = ({ size, color, className }: IconProps) => (
  <Icon
    defaultColor={colorError100Default}
    applyColorToFill
    size={size}
    color={color}
    className={className}
  >
    <Website />
  </Icon>
);

export type IconTypes =
  | typeof IconArrowRight
  | typeof IconArrowRightCircleFill
  | typeof IconChatText
  | typeof IconCheckCircleFill
  | typeof IconCheckMark
  | typeof IconChevronLeft
  | typeof IconChevronRight
  | typeof IconChevronUp
  | typeof IconClose
  | typeof IconCloseCircle
  | typeof IconCloseCircleFill
  | typeof IconContactPage
  | typeof IconElectrician
  | typeof IconEmailCircleFill
  | typeof IconEmailWithLink
  | typeof IconEnvelope
  | typeof IconFireplacesStoves
  | typeof IconGuaranteedMark
  | typeof IconHouseholdWaterTreatment
  | typeof IconInfoFill
  | typeof IconLeaf
  | typeof IconLocationPinFill
  | typeof IconLockKey
  | typeof IconPhoneFill
  | typeof IconStar
  | typeof IconTick
  | typeof IconWarningCircleFill
  | typeof IconWarningTriangleFill;
