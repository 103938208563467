/* eslint-disable camelcase */
import type { TradeBasic, TradeBasicFormatted } from 'lib/sdks/search';

const formatTraderByName = (trades: TradeBasic[]): TradeBasicFormatted[] =>
  trades.map(({ id, ttrid, name, location, unique_name }) => ({
    traderId: id,
    ttrId: ttrid,
    label: `${name} (${ttrid})`,
    value: unique_name,
    location,
  }));

export default formatTraderByName;
