export const LOCATION_CHANGE = 'CAT/NEXT/APP/LOCATION_CHANGE';

export const LOCAL_STORAGE_CHANGE = 'CAT/NEXT/APP/LOCAL_STORAGE_CHANGE';
export const LOCAL_STORAGE_LOADED = 'CAT/NEXT/APP/LOCAL_STORAGE_LOADED';
export const LOCAL_STORAGE_NO_ACCESS = 'CAT/NEXT/APP/LOCAL_STORAGE_NO_ACCESS';

export const SET_MODAL = 'CAT/NEXT/APP/SET_MODAL';

export const SET_GLOBAL_ERROR = 'CAT/NEXT/APP/SET_GLOBAL_ERROR';

// Search / Categories
export const GET_CATEGORIES_INIT = 'CAT/NEXT/APP/GET_CATEGORIES_INIT';
export const GET_CATEGORIES_BY_NAME_INIT =
  'CAT/NEXT/GET_CATEGORY/GET_CATEGORIES_BY_NAME_INIT';
export const GET_CATEGORIES_SUCCESS = 'CAT/NEXT/APP/GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = 'CAT/NEXT/APP/GET_CATEGORIES_ERROR';

// Shortlist

export const SHORTLIST_CLICK_INIT =
  'CAT/NEXT/APP/SHORTLIST/SHORTLIST_CLICK_INIT';
export const ADD_TO_SHORTLIST = 'CAT/NEXT/APP/SHORTLIST/ADD_TO_SHORTLIST';
export const REMOVE_FROM_SHORTLIST =
  'CAT/NEXT/APP/SHORTLIST/REMOVE_FROM_SHORTLIST';
export const CLEAR_SHORTLIST = 'CAT/NEXT/APP/SHORTLIST/CLEAR_SHORTLIST';

export const GET_SHORTLISTS_INIT =
  'CAT/NEXT/APP/SHORTLISTS/GET_SHORTLISTS_INIT';
export const GET_SHORTLISTS_SUCCESS =
  'CAT/NEXT/APP/SHORTLISTS/GET_SHORTLISTS_SUCCESS';
export const GET_SHORTLISTS_ERROR =
  'CAT/NEXT/APP/SHORTLISTS/GET_SHORTLISTS_ERROR';

// Messages
export const SET_MESSAGE_TRADER_SENT = 'CAT/NEXT/APP/SET_MESSAGE_TRADER_SENT';

export const SET_MOST_RECENT_DESCRIPTION =
  'CAT/NEXT/APP/SET_MOST_RECENT_DESCRIPTION';

// SEND A JOB
export const SEND_A_JOB_SET_STEP = 'CAT/NEXT/SEND_A_JOB/SET_STEP';

// GET TRADE

export const GET_TRADE_BY_NAME_INIT =
  'CAT/NEXT/GET_TRADE/GET_TRADE_BY_NAME_INIT';
export const GET_TRADE_BY_NAME_SUCCESS =
  'CAT/NEXT/GET_TRADE/GET_TRADE_BY_NAME_SUCCESS';
export const GET_TRADE_BY_NAME_ERROR =
  'CAT/NEXT/GET_TRADE/GET_TRADE_BY_NAME_ERROR';

// Recent Searches
export const ADD_RECENT_SEARCH = 'CAT/NEXT/APP/ADD_RECENT_SEARCH';
export const REMOVE_RECENT_SEARCH = 'CAT/NEXT/APP/REMOVE_RECENT_SEARCH';
export const MAX_RECENT_SEARCHES_LIMIT = 3;
