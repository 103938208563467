let ready = false;
let queue: (() => void)[] = [];

export const setAsReady = () => {
  if (ready) {
    return;
  }

  ready = true;
  queue.forEach(fn => {
    fn();
  });

  queue = [];
};

export const runWhenReady = (fn: () => void) => {
  if (ready) {
    fn();
    return;
  }

  queue.push(fn);
};
