import styled from 'styled-components';
import colors from '@toolshed/styleguide/styles/colors';
import spacing from '@styles/spacing';
import tablet from '@styles/breakpoint/tablet';
import laptop from '@styles/breakpoint/laptop';

import { semibold, regular } from '@toolshed/styleguide/styles/typography';
import { spacing1, spacing2, spacing5 } from 'lib/mortar';

export const Section = styled.div`
  margin: ${spacing5};
  margin-bottom: 0;
  border-right: 0px solid ${colors.blue};
  justify-content: center;

  &:last-child {
    border-bottom: none;
    border-right: none;
    padding-right: 0;
    padding-bottom: 0;
  }

  ${tablet(`
    margin: ${spacing5} 0;
    padding: 0 ${spacing5}
  `)};

  ${laptop(`
    margin: ${spacing.medium}px 0 0;
    padding: 0;
  `)};
`;

export const SectionWithNav = styled(Section.withComponent('nav'))`
  a {
    width: 100%;
  }

  ${tablet(`
  a {
    width: auto;

    &::after {
      content: ' ';
      border-right: 1px solid ${colors.medium};
    }

    &:last-child::after {
      border-right: none;
    }
  }
  `)};
`;

export const Heading = styled.div`
  ${semibold('medium')};
  color: ${colors.blue};
  margin-bottom: ${spacing2};

  ${tablet(semibold('lbase'))};
`;

export const Item = styled.div`
  display: block;
  ${regular('base')};
  color: ${colors.medium};
  margin-bottom: ${spacing1};

  ${tablet(`
    display: inline-block;
    padding: 0 ${spacing2};
    width: auto;
  `)};
`;

export const Content = styled.div`
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  box-sizing: border-box;

  ${tablet(`
    padding: ${spacing.medium}px 0 0;
    flex-direction: row;
  `)};
`;

export const Inner = styled.div`
  flex-direction: column;

  ${tablet(`
    display: flex;
    padding-bottom: ${spacing.medium}px;
    max-width: 1050px;
    margin: 0 auto;
  `)};
`;
