import { takeLatest, put, call, cancelled } from 'redux-saga/effects';
import type { Category } from 'lib/sdks/search';
import { searchCategoriesByName } from 'lib/sdks/search';

import { GET_CATEGORIES_BY_NAME_INIT } from '../constants';
import { getCategoriesSuccess, getCategoriesError } from '../actions';

export function* callGetCategoryByNameInit({
  payload: { category },
}: {
  payload: { category: string };
}) {
  let controller;

  try {
    controller = window.AbortController ? new AbortController() : undefined;
    const abortSignal = controller?.signal || undefined;

    const data: Category[] = yield call(searchCategoriesByName, {
      category,
      abortSignal,
    });
    yield put(getCategoriesSuccess({ data: data }));
  } catch (error) {
    yield put(getCategoriesError(error));
  } finally {
    const isCancelled: boolean = yield cancelled();
    if (isCancelled) {
      if (controller?.abort) {
        controller.abort();
      }
    }
  }
}

export default function* watcher() {
  // @ts-ignore
  yield takeLatest(GET_CATEGORIES_BY_NAME_INIT, callGetCategoryByNameInit);
}
