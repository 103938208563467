const sortByItem = itemKey => (itemA, itemB) => {
  const { [itemKey]: A } = itemA || {};
  const { [itemKey]: B } = itemB || {};

  if (A === B) return 0;

  // Ensure that null/undefined values come last
  if (A === null || A === undefined) {
    return 1;
  }

  if (B === null || B === undefined) {
    return -1;
  }

  // Normal sorting
  return A < B ? -1 : 1;
};

export default sortByItem;
