const { OUT_OF_TEST_VALUE } = require('./ab-tests.constants');
const abTestsConfig = require('./ab-tests.config');
const {
  getTestValueFactory,
} = require('./server.get-ab-tests.get-test-value-factory');

const sortVariants = array =>
  array.sort(({ distribution: a }, { distribution: b }) => a - b);

const fourWeeksInSeconds = 2.419e6;
const parseNumericValue = v => Number(v);

const tests = abTestsConfig.map(t => ({
  // Default values unless over-ridden
  cookieMaxAge: fourWeeksInSeconds,
  parseValue: parseNumericValue,
  ...t,
  variants: sortVariants(t.variants),
}));

module.exports = (req, res) => {
  const abTests = {};
  const getTestValue = getTestValueFactory(req, res);
  const len = tests.length;
  for (let i = 0; i < len; i += 1) {
    const test = tests[i];
    const { name, experimentId, parseValue, rollout, variants, cookieMaxAge } =
      test;

    const value = getTestValue({
      name,
      experimentId,
      parseValue,
      rollout,
      variants,
      cookieMaxAge,
    });

    const variationId = parseValue(value);
    if (variationId !== OUT_OF_TEST_VALUE) {
      abTests[name] = { experimentId, variationId };
    }
  }

  return abTests;
};
