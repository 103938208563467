export const iconSizeXXS = '8px';
export const iconSizeXS = '12px';
export const iconSizeSmall = '16px';
export const iconSizeSMedium = '20px';
export const iconSizeMedium = '24px';
export const iconSizeLMedium = '28px';
export const iconSizeLarge = '32px';
export const iconSizeXLarge = '40px';
export const iconSizeXXLarge = '48px';
export const iconSizeXXXLarge = '56px';
export const iconSizeExtreme = '70px';
