import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { formatNamedLocation, formatPostcode } from '../format-location';
import { isPostcode } from '../postcode-validation';
import jonify from '../jonify';

dayjs.extend(relativeTime);

const getDateFromNow = date => dayjs(date).fromNow();

const formatRecentSearchData = ({
  categoryId,
  categoryLabel,
  location,
  searchedOn,
}) => {
  const formattedLocation = isPostcode(location)
    ? formatPostcode(location)
    : formatNamedLocation(location);

  return {
    categoryId,
    categoryLabel,
    location: formattedLocation,
    date: getDateFromNow(searchedOn),
    searchUrl: `/Search/${jonify(categoryLabel)}/in/${jonify(
      formattedLocation,
    )}`,
  };
};

export default formatRecentSearchData;
