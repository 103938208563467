import {
  iconSizeLarge,
  iconSizeMedium,
  iconSizeSmall,
  iconSizeXL,
  iconSizeXS,
  iconSizeXXL,
  iconSizeXXXL,
  iconSizeXXXXL,
} from '../../design-tokens';
import styled from 'styled-components';

export type IconSize =
  | 'xs'
  | 'small'
  | 'medium'
  | 'large'
  | 'xl'
  | 'xxl'
  | 'xxxl'
  | 'xxxxl';

interface IconSpanProps {
  defaultColor: string;
  applyColorToFill?: boolean;
  applyColorToStroke?: boolean;
  size?: IconSize;
  color?: string;
}

const IconSizeMap: Record<IconSize, string> = {
  xs: iconSizeXS,
  small: iconSizeSmall,
  medium: iconSizeMedium,
  large: iconSizeLarge,
  xl: iconSizeXL,
  xxl: iconSizeXXL,
  xxxl: iconSizeXXXL,
  xxxxl: iconSizeXXXXL,
};

const widthAndHeight = (size: string) => `
  width: ${size};
  min-width: ${size};
  height: ${size};
`;

export const Icon = styled.span<IconSpanProps>`
  ${props => `
    display: flex;
    ${widthAndHeight(IconSizeMap[props.size || 'medium'] || iconSizeMedium)};
    svg {
      width: 100%;
      height: 100%;
      &, & path {
        fill: ${
          props.applyColorToFill ? props.color || props.defaultColor : 'none'
        };
        stroke: ${
          props.applyColorToStroke ? props.color || props.defaultColor : 'none'
        };
      }
    }
  `}
`;
