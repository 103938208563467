// This component is used to render links to external URLs (URLs not in the SPA)
// The business wants to track such clicks which requires delaying the redirect while the click is recorded.
import type { ReactNode, SyntheticEvent } from 'react';
import React from 'react';
import {
  LinkBase,
  LinkBaseInner,
} from '@toolshed/styleguide/components/Button/ButtonBase';

interface AProps {
  className?: string;
  children: ReactNode;
  onTrackEvent?: Function;
  onClick?: Function;
  trackingEvent?: string;
  trackingContextMenuEvent?: string;
  trackingData?: Object;
  href: string;
  horz?: boolean;
  target?: string;
}

export default class A extends React.PureComponent<AProps> {
  constructor(props: AProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleContextMenu = this.handleContextMenu.bind(this);
  }

  static defaultProps = {
    onClick: () => null,
  };

  handleClick(e: SyntheticEvent) {
    const {
      trackingEvent,
      trackingData,
      onClick = /* istanbul ignore next */ () => null,
      onTrackEvent,
      href,
      target,
    } = this.props;
    if (typeof onTrackEvent === 'function') {
      onTrackEvent({
        key: trackingEvent || 'global.url_redirect',
        data: { url: href, ...trackingData },
      });

      // if link has a target no need for time out
      if (target) {
        onClick(e);
        return;
      }

      e.preventDefault();
      e.stopPropagation();

      setTimeout(() => {
        window.location.href = href;
      }, 750);
    }

    onClick(e);
  }

  handleContextMenu() {
    const { trackingContextMenuEvent, trackingData, onTrackEvent, href } =
      this.props;

    if (typeof onTrackEvent === 'function' && trackingContextMenuEvent) {
      onTrackEvent({
        key: trackingContextMenuEvent,
        data: { url: href, ...trackingData },
      });
    }
  }

  render() {
    const {
      // eslint-disable-next-line
      onTrackEvent,
      // eslint-disable-next-line
      trackingContextMenuEvent,
      // eslint-disable-next-line
      trackingEvent,
      // eslint-disable-next-line
      trackingData,
      // eslint-disable-next-line
      children,
      // eslint-disable-next-line
      horz,
      ...other
    } = this.props;
    return (
      <LinkBase
        {...other}
        onClick={this.handleClick}
        onContextMenu={this.handleContextMenu}
      >
        <LinkBaseInner>{children}</LinkBaseInner>
      </LinkBase>
    );
  }
}
