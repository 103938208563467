import React, { useState } from 'react';
import Link from 'next/link';
import { primary700 } from 'lib/mortar';
import colors from '@toolshed/styleguide/styles/colors';
import formatAbsoluteUrl, { JOIN } from '@utils/formatters/absolute-url';

import {
  IconHamburger,
  IconHamburgerClose,
} from '@toolshed/styleguide/components/AppIcon/AppIcons';
import MegaLink, { homeOwnerType, tradesType } from 'components/Nav/MegaLink';
import {
  Image,
  Inner,
  Links,
  Logo,
  Menu,
  ActionButtonWrapper,
  Wrapper,
  LogoLink,
  HamburgerBadgeContainer,
  NavButton,
} from 'components/Nav/styles';

import NotificationDot from 'components/NotificationDot/NotificationDot';
import { LoginButton } from 'components/Nav/LoginButton/login-button';
import NavLeft from './NavLeft';
import { track } from 'lib/sdks/analytics';
import type { OnTrackEventArgs } from 'containers/AppContainer/AppContainer.interfaces';
import { spacing5 } from 'lib/mortar';
import useRecentlyContactedCompanyIds from '@hooks/use-recently-contacted-company-ids';

export const hoHighlightStyle = {
  backgroundColor: colors.skyBlue,
  color: colors.white,
};

export const tradesHighlightStyle = {
  backgroundColor: colors.blue,
  color: colors.white,
};

const joinNowUrl = formatAbsoluteUrl('/overview', JOIN);
const leaveReviewUrl = '/give-feedback';

export type NavProps = {
  homeownerOpen?: boolean;
  tradesOpen?: boolean;
  className?: string;
  onTrackEvent: (tracking: OnTrackEventArgs) => void;
  shortlist?: {
    uniqueName: string;
  }[];
};

const Nav = (props: NavProps) => {
  const { className, shortlist, onTrackEvent } = props;
  const [open, setOpen] = useState(false);
  const [megaType, setMegaType] = useState<string | undefined>();

  const shortlistLength = shortlist?.length || 0;
  const homeownerOpen = megaType === homeOwnerType;
  const tradesOpen = megaType === tradesType;
  const hasShortlist = shortlistLength > 0;
  const showDot = !open && hasShortlist;
  const HamburgerIcon = open ? IconHamburgerClose : IconHamburger;

  const contactedCompanyIds = useRecentlyContactedCompanyIds(
    {
      account: true,
      local: true,
      fallbackToLocal: false,
    },
    false,
  );
  const showLeaveReviewButton = contactedCompanyIds?.length > 0;

  const handleMegaClickOutside = () => {
    setOpen(false);
    setMegaType(undefined);
  };

  return (
    <>
      <Wrapper
        className={className}
        onClick={handleMegaClickOutside}
        data-guid="TopNav"
      >
        <Inner>
          <Menu
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setMegaType(undefined);
              setOpen(value => !value);
            }}
          >
            <HamburgerIcon color={colors.blue} />

            {showDot && (
              <HamburgerBadgeContainer>
                <NotificationDot color={primary700} />
              </HamburgerBadgeContainer>
            )}
          </Menu>
          <Logo data-guid="TopNavLogo" onClick={handleMegaClickOutside}>
            <Link href="/" passHref prefetch={false} legacyBehavior>
              <LogoLink aria-label="Homepage">
                <Image alt="Checkatrade Logo" />
              </LogoLink>
            </Link>
          </Logo>
          <Links onClick={handleMegaClickOutside}>
            <MegaLink
              data-guid="TopNavHomeowner"
              isOpen={homeownerOpen}
              linkText="Homeowner"
              onLinkClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setMegaType(homeownerOpen ? undefined : homeOwnerType);
                setOpen(false);
              }}
              openStyle={hoHighlightStyle}
              type={homeOwnerType}
              onClickOutside={handleMegaClickOutside}
              hasBadge={hasShortlist}
              shortlistLength={shortlistLength}
            />
            <div
              style={{
                height: `calc(100% - ${spacing5})`,
                width: 1,
                backgroundColor: megaType ? 'transparent' : colors.light,
              }}
            />
            <MegaLink
              data-guid="TopNavTrade"
              isOpen={tradesOpen}
              linkText="Trades"
              onLinkClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setMegaType(tradesOpen ? undefined : tradesType);
                setOpen(false);
              }}
              openStyle={tradesHighlightStyle}
              type={tradesType}
              onClickOutside={handleMegaClickOutside}
              hasBadge={false}
            />
          </Links>
          <ActionButtonWrapper>
            {showLeaveReviewButton ? (
              <Link href={leaveReviewUrl} passHref legacyBehavior>
                <NavButton data-guid="TopNavLeaveReview">
                  Leave a review
                </NavButton>
              </Link>
            ) : (
              <NavButton href={joinNowUrl} data-guid="TopNavTradeSignUp">
                Trade sign up
              </NavButton>
            )}

            <LoginButton handleMegaClickOutside={handleMegaClickOutside} />
          </ActionButtonWrapper>
        </Inner>
      </Wrapper>
      <NavLeft
        open={open}
        onTrackEvent={onTrackEvent}
        onClose={() => setOpen(false)}
        onOpen={() => track('global.hamburger_open')}
        shortlistLength={shortlistLength}
      />
    </>
  );
};

export default Nav;
