import { useJobs } from '@fire/firestore/hooks';
import { getTradeContactHistory } from '@utils/trade-contact-history';
import { useEffect, useState } from 'react';
import type { Job } from '@fire/firestore/types';
import { useCurrentUser, useLoading } from '@fire/auth';

type accountContacts = { account: true; local: false; fallbackToLocal: false };
type localContacts = { account: false; local: true; fallbackToLocal: false };
type allContacts = { account: true; local: true; fallbackToLocal: false };

type fallbackToLocalContacts = {
  account: true;
  local: false;
  fallbackToLocal: true;
};

// Get an array of contacted companyIds, sorted most recent first
// Can come from firestore, local storage, both or firestore falling back to local storage
// Can optionally remove RAQ contacts.
const useRecentlyContactedCompanyIds = (
  {
    account = true,
    local = true,
    fallbackToLocal = true,
  }: accountContacts | localContacts | allContacts | fallbackToLocalContacts,
  restrictToDirectContacts: boolean = false,
): number[] => {
  const loading = useLoading();
  const currentUser = useCurrentUser();
  const [companyIds, setCompanyIds] = useState<number[]>([]);
  const [jobs, jobsLoading] = useJobs();

  const getCompanyIdsForLoggedInUser = (): number[] => {
    if (!jobs || !jobs.length) {
      return [];
    }

    const sortedJobs = jobs.sort((jobA: Job, jobB: Job) => {
      if (
        jobA.dateCreated &&
        jobB.dateCreated &&
        jobA.dateCreated < jobB.dateCreated
      ) {
        return 1;
      }

      return -1;
    });

    let ids: number[] = [];

    if (restrictToDirectContacts) {
      ids = sortedJobs
        .filter(job => job.type === 'DirectMessage')
        .map(job => job?.leads?.[0]?.companyId);
    } else {
      ids = sortedJobs.map(job => job?.leads?.[0]?.companyId);
    }

    return Array.from(new Set(ids));
  };

  const getCompanyIdsForLoggedOutUser = () => {
    return Array.from(new Set(getTradeContactHistory()));
  };

  const getCompanyIdsForBothStates = () => {
    return Array.from(
      new Set([
        ...getCompanyIdsForLoggedInUser(),
        ...getCompanyIdsForLoggedOutUser(),
      ]),
    );
  };

  useEffect(() => {
    if (!loading) {
      if ((!account && local) || (!currentUser && fallbackToLocal)) {
        setCompanyIds(getCompanyIdsForLoggedOutUser());
      } else if ((account && !local) || (currentUser && fallbackToLocal)) {
        setCompanyIds(getCompanyIdsForLoggedInUser());
      } else if (account && local && !fallbackToLocal) {
        setCompanyIds(getCompanyIdsForBothStates());
      }
    }
  }, [
    currentUser,
    loading,
    jobsLoading,
    account,
    local,
    fallbackToLocal,
    restrictToDirectContacts,
  ]);

  return companyIds;
};

export default useRecentlyContactedCompanyIds;
