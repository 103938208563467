import { TokenSchema } from './auth.schemas';

export const refreshTokens = async (refreshToken: string) => {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_IDENTITY_SERVICE_URL}/auth/realms/consumer/token`,
    {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        client_id: process.env.SLASH_ID_AUTH_ID as string,
        client_secret: process.env.SLASH_ID_AUTH_SECRET as string,
        grant_type: 'refresh_token',
        refresh_token: refreshToken as string,
      }),
      method: 'POST',
    },
  );

  const tokens = await res.json();

  if (!res.ok) {
    return null;
  }

  const currentTimeSeconds = Math.floor(Date.now() / 1000);

  return TokenSchema.parse({
    idToken: tokens.id_token,
    accessToken: tokens.access_token,
    refreshToken: tokens.refresh_token,
    expiresAt: currentTimeSeconds + tokens.expires_in,
  });
};
