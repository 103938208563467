export const formatPostcodeForFirebase = (postcode: string) =>
  postcode
    .replace(/\s/g, '')
    .replace(/^(.*)(\d)/, '$1 $2')
    .toUpperCase();

export const isPostcode = (postcode: string) =>
  isFullPostcode(postcode) || isPartialPostcode(postcode);

export const isPartialPostcode = (partialPostcode: string) =>
  /^([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA)$/i.test(
    partialPostcode,
  );

// https://en.wikipedia.org/wiki/Postcodes_in_the_United_Kingdom#Validation
export const isFullPostcode = (postcode: string) =>
  /^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/i.test(
    postcode,
  );

const areaDistrictRegex =
  /^(([A-Z]{1,2})[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA)/i;
export const getPostcodeArea = (postcode: string) => {
  if (!isFullPostcode(postcode)) return;

  const match = postcode.match(areaDistrictRegex);
  if (match) {
    return match[2];
  }
};

export const getPostcodeDistrict = (postcode: string) => {
  if (!isFullPostcode(postcode)) return;

  const match = postcode.match(areaDistrictRegex);
  if (match) {
    return match[1];
  }
};
