import React from 'react';
import colors from '@toolshed/styleguide/styles/colors';
import type { IconProps } from './Icon';
import { Icon } from './Icon';

import Apple from './assets/app-icons/apple.svg';
import AccountActive from './assets/app-icons/account-active.svg';
import AccountInactive from './assets/app-icons/account-inactive.svg';
import Add from './assets/app-icons/add.svg';
import Business from './assets/app-icons/business.svg';
import Calendar from './assets/app-icons/calendar.svg';
import Call from './assets/app-icons/call-filled.svg';
import Check from './assets/app-icons/check.svg';
import ChevronDown from './assets/app-icons/chevron-down.svg';
import ChevronDownNew from './assets/app-icons/chevron-down-new.svg';
import ChevronLeft from './assets/app-icons/chevron-left.svg';
import ChevronRight from './assets/app-icons/chevron-right.svg';
import ChevronUp from './assets/app-icons/chevron-up.svg';
import ChevronUpNew from './assets/app-icons/chevron-up-new.svg';
import CircleLoader from './assets/app-icons/circle-loader.svg';
import Clock from './assets/app-icons/clock.svg';
import Close from './assets/app-icons/close.svg';
import DropDown from './assets/app-icons/drop-down.svg';
import DropUp from './assets/app-icons/drop-up.svg';
import Emergency from './assets/app-icons/emergency.svg';
import Facebook from './assets/app-icons/facebook.svg';
import Favicon from './assets/app-icons/favicon.svg';
import Hamburger from './assets/app-icons/hamburger.svg';
import HamburgerClose from './assets/app-icons/hamburger-close.svg';
import HammerTimeDown from './assets/app-icons/hammer-time-down.svg';
import HammerTimeUp from './assets/app-icons/hammer-time-up.svg';
import Heart from './assets/app-icons/heart.svg';
import HeartHollow from './assets/app-icons/heart-hollow.svg';
import Home from './assets/app-icons/home.svg';
import ImageCount from './assets/app-icons/image-count.svg';
import LinkIcon from './assets/app-icons/link.svg';
import LocationPin from './assets/app-icons/location-pin.svg';
import LocationPinTarget from './assets/app-icons/location-pin-target.svg';
import MessageSent from './assets/app-icons/message-sent.svg';
import MessagesActiveDashboard from './assets/app-icons/messages-active-dashboard.svg';
import MessagesFilled from './assets/app-icons/messages-filled.svg';
import MessagesInactiveDashboard from './assets/app-icons/messages-inactive-dashboard.svg';
import Minus from './assets/app-icons/minus.svg';
import Play from './assets/app-icons/play.svg';
import PlayStore from './assets/app-icons/play-store.svg';
import Plus from './assets/app-icons/plus.svg';
import ProjectsActive from './assets/app-icons/projects-active.svg';
import ProjectsInactive from './assets/app-icons/projects-inactive.svg';
import Remove from './assets/app-icons/remove.svg';
import SaveAndSend from './assets/app-icons/save-and-send.svg';
import ScrapBook from './assets/app-icons/scrap-book.svg';
import Search from './assets/app-icons/search.svg';
import Secure from './assets/app-icons/secure.svg';
import Send from './assets/app-icons/send.svg';
import SendMessage from './assets/app-icons/send-message.svg';
import Share from './assets/app-icons/share.svg';
import ShortlistActive from './assets/app-icons/shortlist-active.svg';
import ShortlistFilled from './assets/app-icons/shortlist-filled.svg';
import ShortlistInactive from './assets/app-icons/shortlist-inactive.svg';
import Star from './assets/app-icons/star.svg';
import StarOutline from './assets/app-icons/star-outline.svg';
import TakePhotoVideo from './assets/app-icons/take-photo-video.svg';
import Tick from './assets/app-icons/tick.svg';
import ToolboxFill from './assets/app-icons/toolbox-fill.svg';
import TwentyFourHourCallback from './assets/app-icons/twenty-four-hour-callback.svg';
import UploadPhotoVideo from './assets/app-icons/upload-photo-video.svg';
import Verified from './assets/app-icons/verified.svg';
import Warning from './assets/app-icons/warning.svg';
import Website from './assets/app-icons/website.svg';
import Whatsapp from './assets/app-icons/whatsapp.svg';
import WhiteTick from './assets/app-icons/white-tick.svg';

export const generateIconFill = (
  IconComponent: React.FC,
  { color, hoverColor, size, style }: IconProps,
) => (
  <Icon
    defaultColor={`${colors.regular}`}
    applyColorToFill
    color={color}
    hoverColor={hoverColor}
    size={size}
    style={style}
  >
    <IconComponent />
  </Icon>
);

export const IconApple = (props: IconProps) => generateIconFill(Apple, props);

export const IconAccountActive = (props: IconProps) =>
  generateIconFill(AccountActive, props);

export const IconAccountInactive = (props: IconProps) =>
  generateIconFill(AccountInactive, props);

export const IconAdd = (props: IconProps) => generateIconFill(Add, props);

export const IconBusiness = (props: IconProps) =>
  generateIconFill(Business, props);

export const IconCalendar = (props: IconProps) =>
  generateIconFill(Calendar, props);

export const IconCall = (props: IconProps) => generateIconFill(Call, props);

export const IconCheck = (props: IconProps) => generateIconFill(Check, props);

export const IconChevronDown = (props: IconProps) =>
  generateIconFill(ChevronDown, props);

export const IconChevronDownNew = (props: IconProps) =>
  generateIconFill(ChevronDownNew, props);

export const IconChevronLeft = (props: IconProps) =>
  generateIconFill(ChevronLeft, props);

export const IconChevronRight = (props: IconProps) =>
  generateIconFill(ChevronRight, props);

export const IconChevronUp = (props: IconProps) =>
  generateIconFill(ChevronUp, props);

export const IconChevronUpNew = (props: IconProps) =>
  generateIconFill(ChevronUpNew, props);

export const IconCircleLoader = (props: IconProps) =>
  generateIconFill(CircleLoader, props);

export const IconClock = (props: IconProps) => generateIconFill(Clock, props);

export const IconClose = (props: IconProps) => generateIconFill(Close, props);

export const IconDropDown = (props: IconProps) =>
  generateIconFill(DropDown, props);

export const IconDropUp = (props: IconProps) => generateIconFill(DropUp, props);

export const IconEmergency = (props: IconProps) =>
  generateIconFill(Emergency, props);

export const IconFacebook = (props: IconProps) =>
  generateIconFill(Facebook, props);

export const IconFavicon = (props: IconProps) =>
  generateIconFill(Favicon, props);

export const IconHamburger = (props: IconProps) =>
  generateIconFill(Hamburger, props);

export const IconHamburgerClose = (props: IconProps) =>
  generateIconFill(HamburgerClose, props);

export const IconHammerTimeDown = (props: IconProps) =>
  generateIconFill(HammerTimeDown, props);

export const IconHammerTimeUp = (props: IconProps) =>
  generateIconFill(HammerTimeUp, props);

export const IconHeart = (props: IconProps) => generateIconFill(Heart, props);

export const IconHeartHollow = (props: IconProps) =>
  generateIconFill(HeartHollow, props);

export const IconHome = (props: IconProps) => generateIconFill(Home, props);

export const IconImageCount = (props: IconProps) =>
  generateIconFill(ImageCount, props);

export const IconLink = (props: IconProps) => generateIconFill(LinkIcon, props);

export const IconLocationPin = (props: IconProps) =>
  generateIconFill(LocationPin, props);

export const IconLocationPinTarget = (props: IconProps) =>
  generateIconFill(LocationPinTarget, props);

export const IconMessageSent = (props: IconProps) =>
  generateIconFill(MessageSent, props);

export const IconMessagesActiveDashboard = (props: IconProps) =>
  generateIconFill(MessagesActiveDashboard, props);

export const IconMessagesFilled = (props: IconProps) =>
  generateIconFill(MessagesFilled, props);

export const IconMessagesInactiveDashboard = (props: IconProps) =>
  generateIconFill(MessagesInactiveDashboard, props);

export const IconMinus = (props: IconProps) => generateIconFill(Minus, props);

export const IconPlay = (props: IconProps) => generateIconFill(Play, props);

export const IconPlayStore = (props: IconProps) =>
  generateIconFill(PlayStore, props);

export const IconPlus = (props: IconProps) => generateIconFill(Plus, props);

export const IconProjectsActive = (props: IconProps) =>
  generateIconFill(ProjectsActive, props);

export const IconProjectsInactive = (props: IconProps) =>
  generateIconFill(ProjectsInactive, props);

export const IconRemove = (props: IconProps) => generateIconFill(Remove, props);

export const IconSaveAndSend = (props: IconProps) =>
  generateIconFill(SaveAndSend, props);

export const IconScrapBook = (props: IconProps) =>
  generateIconFill(ScrapBook, props);

export const IconSearch = (props: IconProps) => generateIconFill(Search, props);

export const IconSecure = (props: IconProps) => generateIconFill(Secure, props);

export const IconSend = (props: IconProps) => generateIconFill(Send, props);

export const IconSendMessage = (props: IconProps) =>
  generateIconFill(SendMessage, props);

export const IconShare = (props: IconProps) => generateIconFill(Share, props);

export const IconShortlistActive = (props: IconProps) =>
  generateIconFill(ShortlistActive, props);

export const IconShortlistFilled = (props: IconProps) =>
  generateIconFill(ShortlistFilled, props);

export const IconShortlistInactive = (props: IconProps) =>
  generateIconFill(ShortlistInactive, props);

export const IconStar = (props: IconProps) => generateIconFill(Star, props);

export const IconStarOutline = (props: IconProps) =>
  generateIconFill(StarOutline, props);

export const IconTakePhotoVideo = (props: IconProps) =>
  generateIconFill(TakePhotoVideo, props);

export const IconTick = (props: IconProps) => generateIconFill(Tick, props);

export const IconToolboxFill = (props: IconProps) =>
  generateIconFill(ToolboxFill, props);

export const IconTwentyFourHourCallback = (props: IconProps) =>
  generateIconFill(TwentyFourHourCallback, props);

export const IconUploadPhotoVideo = (props: IconProps) =>
  generateIconFill(UploadPhotoVideo, props);

export const IconVerified = (props: IconProps) =>
  generateIconFill(Verified, props);

export const IconWarning = (props: IconProps) =>
  generateIconFill(Warning, props);

export const IconWebsite = (props: IconProps) =>
  generateIconFill(Website, props);

export const IconWhatsapp = (props: IconProps) =>
  generateIconFill(Whatsapp, props);

export const IconWhiteTick = (props: IconProps) =>
  generateIconFill(WhiteTick, props);
