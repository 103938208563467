/* istanbul ignore file */

import type { PartnerPageData } from '@utils/formatters/format-partnerships';

/* istanbul ignore next */
export interface PartnershipState {
  loading: boolean;
  data: PartnerPageData[];
  error: unknown;
}

/* istanbul ignore next */
const initialState: PartnershipState = {
  loading: false,
  data: [],
  error: undefined,
};

/* istanbul ignore next */
export default initialState;
