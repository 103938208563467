const nav = 60;
const navBorder = 4;

// From toolshed
const newBase = 8;
const maxPageWidth = 1050;

const spacing = {
  medium: 15,
  xlarge: 25,
  xxlarge: 30,
  xxxlarge: 35,
  giant: 45,
  super: 50,
  nav,
  navBorder,
  navTotal: nav,
  squareLogo: 100,
  circleLogo: 65,

  // Duplicating styles from toolshed
  maxPageWidth,
  newBase,
};

export default spacing;
