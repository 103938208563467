import { all, fork } from 'redux-saga/effects';

import shortlistWatcher from './sagas/shortlist';
import getCategoriesWatcher from './sagas/get-categories';
import getTraderByNameWatcher from './sagas/get-trader-by-name';
import getCategoryByNameWatcher from './sagas/get-category-by-name';

export default function* sagas() {
  yield all([
    fork(shortlistWatcher),
    fork(getCategoriesWatcher),
    fork(getTraderByNameWatcher),
    fork(getCategoryByNameWatcher),
  ]);
}
