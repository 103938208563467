import type { ReactNode } from 'react';
import React, { useState, createContext } from 'react';
import type { FirebaseApp } from 'firebase/app';
import { initializeApp, getApps } from 'firebase/app';
import type { FirebaseConfig } from './types';

interface IFirebaseAppContext {
  app: FirebaseApp;
  config: FirebaseConfig;
}

export const FirebaseAppContext = createContext<IFirebaseAppContext | null>(
  null,
);

export const FirebaseAppProvider = ({
  config,
  children,
}: {
  config: FirebaseConfig;
  children: ReactNode;
}) => {
  const [app] = useState(() => getApps()[0] || initializeApp(config));

  return (
    <FirebaseAppContext.Provider value={{ app, config }}>
      {children}
    </FirebaseAppContext.Provider>
  );
};
