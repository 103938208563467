export const addToTradeContactHistory = (companyIdOrIds: number | number[]) => {
  if (typeof window === 'undefined') return;

  const tradeContactHistory: number[] = JSON.parse(
    window.localStorage.getItem('contactHistory') || '[]',
  );

  const companyIds = Array.isArray(companyIdOrIds)
    ? companyIdOrIds
    : [companyIdOrIds];

  const uniqueCompanyIds = companyIds.filter(
    companyId => !tradeContactHistory.includes(companyId),
  );

  if (uniqueCompanyIds.length === 0) return; // No new companyIds to add

  localStorage.setItem(
    'contactHistory',
    JSON.stringify([...tradeContactHistory, ...uniqueCompanyIds]),
  );
};

export const getTradeContactHistory = () => {
  const tradeContactHistory: number[] = JSON.parse(
    window.localStorage.getItem('contactHistory') || '[]',
  );

  return tradeContactHistory;
};
