import { compose } from 'redux';
import type { Category } from 'lib/sdks/search';
import flattenCategories from './formatters/flatten-categories';
import formatCategory from './formatters/company-category';

// As the state for categories starts as undefined - this has the potential (when
// used with a selector) to have undefined passed in for `cats`.
// As such - defaulting here to an empty array to negate the need
// to update the initial state - which may have negative consequences.
const formatCategoryMapper = (cats: Category[] = []) =>
  cats.map(formatCategory);

export const categoryFlattener = compose<Category[]>(
  flattenCategories,
  formatCategoryMapper,
);
