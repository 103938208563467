export const defaultFontSize = 16;
export const defaultFontSizeEm = `${defaultFontSize / 16}em`;

const sizes = {
  atom: 0.5,
  smicro: 0.5625,
  micro: 0.625,
  lmicro: 0.6875,
  small: 0.75,
  sbase: 0.8125,
  base: 0.875,
  lbase: 0.9375,
  smedium: 1,
  medium: 1.125,
  lmedium: 1.25,
  slarge: 1.375,
  large: 1.5,
  llarge: 1.625,
  xlarge: 1.75,
  xxlarge: 2,
  errorCode: 3.6875,
};

export default sizes;
