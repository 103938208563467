import { createSelector } from 'reselect';
import formatRecentSearchesData from '@utils/formatters/format-recent-searches';
import getKey from '@utils/get-key';
import getABVariationId from '@utils/get-ab-variation-id';
import { categoryFlattener } from '@utils/category-flattener';
import type { JOB_SEARCH_ALGORITHM } from 'lib/sdks/jobs-management/job.constants';
import { RANKING_ALGORITHMS } from '@redux/ab-tests.constants';

export const selectGlobal = (state: any) => getKey(state, 'global');
export const selectEnv = (state: any) => getKey(state, 'env');
export const selectABTests = (state: any) => getKey(state, 'abTests');
export const selectSearch = (state: any) => getKey(state, 'search') || {};

export const selectGlobalError = createSelector(selectGlobal, globalState =>
  getKey(globalState, 'error'),
);

export const makeSelectABTestVariationId = (experimentName: string) =>
  createSelector(selectABTests, abTests =>
    getABVariationId(abTests, experimentName),
  );

export const makeSelectGtmId = () =>
  createSelector(selectEnv, env => getKey(env, 'gtmId'));

export const makeSelectModal = () =>
  createSelector(selectGlobal, globalState => getKey(globalState, 'modal'));

export const makeSelectSendAJob = () =>
  createSelector(selectGlobal, globalState => getKey(globalState, 'sendAJob'));

export const selectShortlist = createSelector(selectGlobal, globalState =>
  getKey(globalState, 'shortlist'),
);

export const selectShortlistData = createSelector(
  selectShortlist,
  shortlistsState => getKey(shortlistsState, 'data'),
);

export const makeSelectShortlistModalShown = () =>
  createSelector(selectShortlist, shortlistsState =>
    getKey(shortlistsState, 'modalShown'),
  );

export const makeSelectShortlistFull = () =>
  createSelector(
    selectShortlist,
    shortlistsState => getKey(shortlistsState, 'data').length >= 10,
  );

export const makeSelectHasLocalStorageAccess = () =>
  createSelector(selectGlobal, globalState =>
    getKey(globalState, 'localStorageAccess'),
  );

export const selectCategories = createSelector(
  selectGlobal,
  state => state?.categories,
);

export const selectFlatCategories = createSelector(
  selectCategories,
  categoryFlattener,
);

export const selectMessageTraderHistory = createSelector(
  selectGlobal,
  s => s.messageTraderHistory,
);

export const selectMostRecentDescription = createSelector(
  selectGlobal,
  s => s.mostRecentDescription,
);

// SEND A JOB
export const makeSelectSendAJobStep = () =>
  createSelector(makeSelectSendAJob(), loginState =>
    getKey(loginState, 'step'),
  );

const selectUser = createSelector(selectGlobal, globalState =>
  getKey(globalState, 'user'),
);

export const selectUserToken = createSelector(selectUser, userState =>
  getKey(userState, 'access_token'),
);

export const selectFirebaseEnv = createSelector(selectEnv, envState => ({
  apiKey: getKey(envState, 'firebaseApiKey'),
  authDomain: getKey(envState, 'firebaseAuthDomain'),
  projectId: getKey(envState, 'firebaseProjectId'),

  appId: getKey(envState, 'firebaseAppId'),

  authEmulatorHost: getKey(envState, 'firebaseAuthEmulatorHost'),
  firestoreEmulatorHost: getKey(envState, 'firebaseFirestoreEmulatorHost'),

  region: getKey(envState, 'firebaseRegion'),
  consumerAreaApiUrl: getKey(envState, 'consumerAreaApiUrl'),
}));

// RECENT SEARCHES HISTORY
export const makeRecentSearchesData = () =>
  createSelector(selectGlobal, globalState =>
    getKey(globalState, 'recentSearches'),
  );

export const makeFormattedRecentSearchesData = () =>
  createSelector(makeRecentSearchesData(), (recentSearchesData = []) =>
    recentSearchesData.map(formatRecentSearchesData),
  );

export const selectRecentSearchLocation = createSelector(
  makeRecentSearchesData(),
  recentSearchesData =>
    recentSearchesData &&
    recentSearchesData[0] &&
    recentSearchesData[0].location,
);

// TRADER BY NAME
export const makeSelectTraderByName = () =>
  createSelector(selectGlobal, globalState =>
    getKey(globalState, 'traderByName'),
  );

export const makeSelectTradeByNameData = () =>
  createSelector(makeSelectTraderByName(), traderState =>
    getKey(traderState, 'data'),
  );

// CATEGORY FUNNELING
export const selectCategoryFunneling = createSelector(
  selectGlobal,
  globalState => getKey(globalState, 'categoryFunneling'),
);

export const makeSelectData = () =>
  createSelector(
    selectSearch,
    searchState => getKey(searchState, 'data') || {},
  );

const makeSelectMeta = () =>
  createSelector(makeSelectData(), data => getKey(data, 'meta') || {});

export const makeSelectSearchExperiments = () =>
  createSelector<
    [any],
    {
      algorithm?:
        | (typeof JOB_SEARCH_ALGORITHM)[keyof typeof JOB_SEARCH_ALGORITHM][]
        | undefined;
    }
  >(makeSelectMeta(), meta => meta.experiments || {});

export const selectABTestSearchAlgorithm =
  makeSelectABTestVariationId(RANKING_ALGORITHMS);
