import { z } from 'zod';

export const AddressLine1Schema = z
  .string()
  .min(1, 'First line of address is required');
export const AddressLine2Schema = z.string().nullable();
export const AddressLine3Schema = z.string().nullable();
export const AddressCitySchema = z.string().min(1, 'City is required');
export const AddressPostcodeSchema = z
  .string()
  .min(5, 'Postcode is invalid')
  .max(8, 'Postcode is invalid')
  .regex(
    /^[A-Z]{1,2}[0-9]{1,2}[A-Z]?\s?[0-9][A-Z]{2}$/i,
    'Postcode is invalid',
  );

export const AddressSchema = z.object({
  line1: AddressLine1Schema,
  line2: AddressLine2Schema,
  line3: AddressLine3Schema,
  city: AddressCitySchema,
  postcode: AddressPostcodeSchema,
});

export const AddressNullableSchema = z.object({
  line1: z.string().nullish(),
  line2: z.string().nullish(),
  city: z.string().nullish(),
  postcode: z.string(),
});

export const AddressRequestSchema = AddressSchema.extend({
  type: z.literal('JOB'),
});

export const LookupAddressRequestSchema = z.object({
  postcode: z.string(),
});

export const AddressResponseSchema = AddressSchema.extend({
  id: z.string(),
});

export const AddressSearchResponseSchema = z.array(
  z.object({
    id: z.string(),
    address: z.string(),
  }),
);

export type Address = z.infer<typeof AddressSchema>;
export type AddressRequest = z.infer<typeof AddressRequestSchema>;
export type AddressResponse = z.infer<typeof AddressResponseSchema>;
export type AddressSearchResponse = z.infer<typeof AddressSearchResponseSchema>;
