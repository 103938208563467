import type { Event, Plugin } from '../../types';
import { fetchJson } from 'lib/utils/fetch';

import {
  formatTrackEventData,
  formatTrackFlowEventData,
  isFlowEvent,
} from '../../formatter';
import { ApiQueue } from './api-queue';

type FlowEventType = ReturnType<typeof formatTrackFlowEventData>;
type EventType = ReturnType<typeof formatTrackEventData>;

const apiCall =
  (path: string) =>
  <T>(data: T) =>
    fetchJson<{ success: boolean }>(path, {
      method: 'POST',
      body: JSON.stringify(data),
      keepalive: true,
      headers: {
        'x-api-key': process.env.NEXT_PUBLIC_ANALYTICS_API_KEY || '',
      },
    });

const flowEventAPICall = apiCall(
  `${process.env.NEXT_PUBLIC_ANALYTICS_API_URL}/events/flow`,
);

const eventAPICall = apiCall(
  `${process.env.NEXT_PUBLIC_ANALYTICS_API_URL}/events`,
);

const flowQueue = new ApiQueue<FlowEventType>(flowEventAPICall);
const eventQueue = new ApiQueue<EventType>(eventAPICall);

export const catAnalytics: Plugin = async (event: Event) => {
  if (isFlowEvent(event)) {
    const formattedEvent = formatTrackFlowEventData(event);
    flowQueue.add(formattedEvent);
  } else {
    const formattedEvent = formatTrackEventData(event);
    eventQueue.add(formattedEvent);
  }
};
