import { EventMap } from './event-map';

export { EventMap };

export type EventName = keyof typeof EventMap;

export type CustomEventData = Record<string, any>;

export interface Experiment {
  id: string;
  name: string;
  variant: number | string;
}

export type Plugin = (event: Event) => void;

type Repo = string;
type Branch = string;
type CommitId = string;
type BuildId = string;
type BuildTimestamp = string;

export interface AppContext {
  version: `${Repo}:${Branch}:${CommitId}:${BuildId}:${BuildTimestamp}`;
  env: 'production' | 'staging' | 'development';
  platform: 'web' | 'ios' | 'android';
  lang: 'en-GB';
  experiments: Experiment[];
  ipAddress?: string; // TODO: look into removing this, as analytics server should have direct access itself
}

export interface Context {
  app: AppContext;
  userId?: string | null;
  sessionId: string;
  deviceId: string | null;
  userAgent: string; // TODO: look into removing this, as analytics server should have access via User-Agent request header
  browser: string | undefined; // TODO: look at removing this, as analytics server should be able to parse from userAgent
  browserVersion: string | undefined; // TODO: look at removing this, as analytics server should be able to parse from userAgent
  os: string | undefined; // TODO: look at removing this, as analytics server should be able to parse from userAgent
  osLanguage: string; // TODO: look at removing this, as analytics server should be able to parse from userAgent
  osVersion: string | undefined; // TODO: look at removing this, as analytics server should be able to parse from userAgent
}

export interface Event {
  name: EventName;
  description: EventMap;
  customData?: CustomEventData;
  flowEnrollment?: FlowEnrollment;
  context: Context;
  time: Date;
  pageUrl: URL;
  referrer: string;
  utm?: {
    source: string | null;
    medium: string | null;
    campaign: string | null;
  };
  browserWidth: number;
  browserHeight: number;
  // TODO: abTests appears on pageView - is it OK to remove?
  // TODO: url appears on pageView - is it OK to remove?
}

export interface EventWithFlow extends Event {
  flowEnrollment: FlowEnrollment;
}

export interface Config {
  appContext: AppContext;
  plugins: Plugin[];
  waitForUserId?: boolean;
  useCrossDomainCookie?: boolean;
}

export interface FlowEnrollment {
  flowId: FlowId;
  enrollmentId: string;
  previousStepId?: string | number;
  currentStepId?: string | number;
}

export enum FlowId {
  REVIEW = 1,
  SEARCH_FUNNELLING = 7,
  DIRECT_MESSAGE_FUNNELLING = 8,
  MESSAGE = 9,
}

export enum DirectMessageFunnelFlowSteps {
  CHOOSE_PRIMARY_CATEGORY = 200001,
  LOCATION_SCREEN = 200002,
  SEND_A_MESSAGE = 200003,
  NO_CATEGORY = 200004,
  SEND_TO_RAQ = 200005,
  SEND_TO_SEARCH = 200006,
  MESSAGE_ANYWAY = 200007,
  OUTSIDE_TRADE_RADIUS = 200008,
}

export enum MessageFormFlowSteps {
  JOB_START = 300001,
  DESCRIPTION = 300003,
  CONTACT_DETAILS = 300004,
  SEND_A_MESSAGE = 300005,
  SEND_TO_MORE_TRADES = 300006,
  DONT_SEND_TO_MORE = 300007,
  MESSAGES_SENT = 300008,
  FLOW_INITIALISED = 300009,
  REDIRECT_TO_MESSAGE_PAGE = 300010,
  CONTACT_TRADE_SCREEN = 300012,
}

export const ReviewFlowSteps = {
  REVIEW_STARTED: 1,
  WORK_CARRIED_OUT: 2,
  REVIEW_SUBMITTED: 15,
} as const;
