import { type Event, type EventWithFlow } from './types';
import { convertUTCDateToLocalDate } from '../../utils/date';

const formatCoreEvent = (event: Event) => ({
  event_name: event.name,
  ts: event.time.toISOString(),
  // TODO: this returns a timestamp in UTC format, check if this is desired
  ts_local: convertUTCDateToLocalDate(event.time).toISOString(),
  app_version: event.context.app.version,
  environment: event.context.app.env,
  user_id: event.context.userId,
  session: event.context.sessionId,
  device_id: event.context.deviceId,
});

export const formatTrackFlowEventData = (event: EventWithFlow) => ({
  ...formatCoreEvent(event),
  // TODO: check if it's necessary to have this level of divergence between flow and non-flow events
  flow_id: event.flowEnrollment.flowId,
  enrollment_id: event.flowEnrollment.enrollmentId,
  current_step_id: event.flowEnrollment.currentStepId,
  prev_step_id: event.flowEnrollment.previousStepId,
  extra_params: event.customData,
  app_platform: event.context.app.platform,
});

export const formatTrackEventData = (event: Event) => ({
  ...formatCoreEvent(event),
  page_url: `${event.pageUrl.pathname}${event.pageUrl.search || ''}`,
  product_language: event.context.app.lang,
  browser: event.context.browser,
  browser_version: event.context.browserVersion,
  browser_user_agent: event.context.userAgent,
  browser_width: event.browserWidth,
  browser_height: event.browserHeight,
  os: event.context.os,
  os_language: event.context.osLanguage,
  os_version: event.context.osVersion,
  ip_address: event.context.app.ipAddress,
  extra_params: {
    ...event.customData,
    resource_version: event.context.app.experiments.reduce(
      (acc, experiment) => ({
        ...acc,
        [experiment.name.toLowerCase().replace(/-/g, '_')]: experiment.variant,
      }),
      {},
    ),
    ...(event.name === 'global.page_view'
      ? {
          deprecated_browser: false,
          referrer: event.referrer,
          utm_source: event.utm?.source,
          utm_medium: event.utm?.medium,
          utm_campaign: event.utm?.campaign,
        }
      : {}),
  },
});

export const isFlowEvent = (e: Event): e is EventWithFlow => !!e.flowEnrollment;

export const formatEventData = (event: Event) => {
  if (isFlowEvent(event)) {
    return formatTrackFlowEventData(event);
  }

  return formatTrackEventData(event);
};
