import { css } from 'styled-components';

// Colors July 2023
export const primary700 = '#D10A38';
export const primary800 = '#BD0024';
export const default100 = '#F8F9FF';
export const default200 = '#EBF3FF';
export const default500 = '#0066FF';
export const default700 = '#0058A2';
export const default800 = '#014986';
export const default900 = '#00112C';
export const neutral0 = '#FFFFFF';
export const neutral200 = '#EDEDED';
export const neutral400 = '#D7D7D7';
export const neutral500 = '#999999';
export const neutral600 = '#62687A';
export const neutral800 = '#333333';
export const neutral900 = '#171717';
export const success500 = '#36C075';
export const attention500 = '#FF7E47';
export const tabbed500 = '#F8D241';

export const spacing0 = '0';
export const spacingInlineNarrow = '2px';
export const spacing1 = '4px';
export const spacing2 = '8px';
export const spacing3 = '12px';
export const spacing4 = '16px';
export const spacing5 = '20px';
export const spacing6 = '24px';
export const spacing7 = '28px';
export const spacing8 = '32px';
export const spacing9 = '36px';
export const spacing10 = '40px';
export const spacing12 = '48px';
export const spacing13 = '52px';
export const spacing14 = '56px';
export const spacing17 = '68px';

export const fontFamilyMain =
  '"Open Sans", "Open Sans-fallback", Arial, sans-serif';

export const buttonBorderRadius = '4px';
export const buttonHeight = '40px';
export const buttonFocusOutlineColor = tabbed500;
export const buttonFontSize = '1rem';
export const buttonFontWeight = 600;
export const buttonLineHeight = '1.25rem';
export const buttonPadding = spacing4;
export const buttonDesktopPadding = spacing6;
export const buttonIconSpacing = spacing2;
export const buttonBorderWidth = '1px';
export const buttonDisabledBackgroundColor = neutral400;
export const buttonDisabledFontColor = neutral600;
export const buttonPrimaryBackgroundColor = primary700;
export const buttonPrimaryFontColor = neutral0;
export const buttonPrimaryHoverBackgroundColor = primary800;
export const buttonSecondaryBackgroundColor = default700;
export const buttonSecondaryFontColor = neutral0;
export const buttonSecondaryHoverBackgroundColor = '#004682';
export const buttonTertiaryBackgroundColor = neutral0;
export const buttonTertiaryFontColor = default700;
export const buttonTertiaryBorderColor = default700;
export const buttonTertiaryHoverBackgroundColor = default700;
export const buttonTertiaryHoverFontColor = neutral0;
export const buttonHoverBoxShadow = '0px 4px 4px 0px rgba(98, 104, 122, 0.16)';
export const buttonOptionsBackGroundColor = default100;
export const buttonOptionsGroupBackgroundGroundColor = default800;

export const boxShadowDefault = '0px -6px 20px rgba(98, 104, 122, 0.1)';

export const fontSizeLabelSM = '10px';
export const fontSizeLabelsRegular = '12px';
export const fontSizeBodySM = '14px';
export const fontSizeBodyM = '16px';
export const fontSizeSubHeading = '18px';
export const fontSizeHeadingSM = '20px';
export const fontSizeHeadingL = '24px';

export const iconSizeXS = '12px';
export const iconSizeSmall = '16px';
export const iconSizeMedium = '24px';
export const iconSizeLarge = '32px';
export const iconSizeXL = '40px';
export const iconSizeXXL = '46px';
export const iconSizeXXXL = '56px';
export const iconSizeXXXXL = '72px';

export const breakpointSmallEnd = '599px';
export const breakpointMediumStart = '600px';
export const breakpointMediumEnd = '1023px';
export const breakpointLargeStart = '1024px';

export const pageWidthThin = '540px';
export const pageWidthDefault = '1200px';
export const stickyContactCtas = 10;

export const headerHeight = '60px';

export const logoSize = '48px';

export const fontWeightRegular = '400';
export const fontWeightSemibold = '600';
export const fontWeightBold = '700';

export const borderRadius = (factor: number = 1) => {
  return `${factor * 4}px`;
};

export const lineClamp = (lineCount: number) => css`
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: ${lineCount};
  -webkit-box-orient: vertical;
`;
