import slugify from '@utils/slugify';
import type { Category } from 'lib/sdks/search';

const formatCat = (cat: Category): Category => ({
  ...cat,
  name: slugify(cat.name),
  label: (cat.label || cat.fullname || '')
    .replace(/\//g, ' / ')
    .replace(/\s\s/g, ' '),
  ...(cat.subcategories
    ? { subcategories: cat.subcategories.map(formatCat) }
    : {}),
});

export default formatCat;
