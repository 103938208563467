import { refreshTokens } from 'lib/auth/refresh-tokens';
import { logger } from 'lib/utils/logger';
import { type AuthOptions } from 'next-auth';
import Credentials from 'next-auth/providers/credentials';
import { getTokens } from './get-tokens';
import { checkUserProfile } from './check-user-profile';
import { JWTSchema } from './auth.schemas';
import type { z } from 'zod';

declare module 'next-auth/jwt' {
  interface JWT extends z.infer<typeof JWTSchema> {}
}

declare module 'next-auth' {
  interface User {
    isNewUser: boolean;
    validProfile: boolean;
    id: string;
    email: string;
    accessToken?: string;
    refreshToken?: string;
    expiresAt?: number;
  }
  interface Session {
    token: {
      accessToken: string;
      expiresAt: number;
    };
    user: User;
    error?: string;
  }
}

const TOKEN_EXPIRY_BUFFER_MS = 60000;

const TOKEN_VERSION = 1;

export const authOptions: AuthOptions = {
  providers: [
    Credentials({
      id: 'custom-checkatrade',
      name: 'custom-checkatrade',
      credentials: {
        token: { label: 'token', type: 'text' },
      },
      authorize: async credentials => {
        if (!credentials?.token) {
          logger.warn('Session - No IDP token provided');
          return null;
        }

        logger.info('Session - Getting Tokens from IDP token');
        const res = await getTokens(credentials.token);

        if (!res) {
          logger.error('Session - Parse failed');
          return null;
        }

        const { isNewUser, validProfile } = await checkUserProfile({
          accessToken: res.accessToken,
          userId: res.id,
          email: res.email,
        });

        return { ...res, isNewUser, validProfile };
      },
    }),
    {
      id: 'checkatrade',
      name: 'Checkatrade',
      type: 'oauth',
      wellKnown: `${process.env.NEXT_PUBLIC_IDENTITY_SERVICE_URL}/auth/realms/consumer/.well-known/openid-configuration`,
      authorization: { params: { scope: 'openid email offline_access' } },
      idToken: true,
      checks: ['pkce', 'state'],
      clientId: process.env.SLASH_ID_AUTH_ID,
      clientSecret: process.env.SLASH_ID_AUTH_SECRET,
      async profile(profile, tokens) {
        return checkUserProfile({
          accessToken: tokens.access_token as string,
          userId: profile.sub,
          email: profile.email,
        });
      },
    },
  ],
  callbacks: {
    jwt: async ({ token, account, user, trigger, session }) => {
      if (account) {
        if (account.provider === 'custom-checkatrade') {
          logger.info(
            { userId: user.id, isNewUser: user.isNewUser },
            'Session - Login IDP token',
          );
          return JWTSchema.parse({
            version: TOKEN_VERSION,
            accessToken: user.accessToken,
            refreshToken: user.refreshToken,
            expiresAt: user.expiresAt,
            isNewUser: user.isNewUser || !user.validProfile,
            userId: user.id,
            email: user.email,
          });
        }

        logger.info(
          { userId: user.id, isNewUser: user.isNewUser },
          'Session - Login',
        );

        return JWTSchema.parse({
          version: TOKEN_VERSION,
          accessToken: account.access_token,
          refreshToken: account.refresh_token,
          expiresAt: account.expires_at,
          isNewUser: user.isNewUser || !user.validProfile,
          userId: user.id,
          email: user.email,
        });
      }

      const { userId, isNewUser } = token;

      if (trigger === 'update') {
        logger.info(
          { userId: userId, isNewUser: session.isNewUser },
          'Session - Update',
        );
        token.isNewUser = session.isNewUser;
      }

      const { expiresAt } = token;

      const expiryTime = expiresAt * 1000 - TOKEN_EXPIRY_BUFFER_MS;
      const expired = Date.now() >= expiryTime;

      if (process.env.NODE_ENV === 'development') {
        logger.info(
          { userId, isNewUser },
          `Session - Token expires at ${new Date(expiryTime).toLocaleString(
            'en-GB',
          )} and is ${expired ? 'expired ❌' : 'valid ✅'}`,
        );
      }

      if (token.version !== TOKEN_VERSION) {
        token.error = 'Session expired';
        return token;
      }

      if (!expired || !!token.error) {
        return token;
      }

      const refreshedTokens = await refreshTokens(token.refreshToken);

      if (refreshedTokens === null) {
        logger.warn(
          { userId, isNewUser },
          'Session - Failed to refresh tokens',
        );
        return { ...token, error: 'Session expired' };
      }

      logger.info({ userId, isNewUser }, 'Session - Tokens refreshed');
      return JWTSchema.parse({
        ...token,
        ...refreshedTokens,
      });
    },
    session: async ({ session, token }) => {
      session.error = token.error;

      session.user.isNewUser = token.isNewUser;
      session.user.id = token.userId;
      session.user.email = token.email as string;

      session.token = {
        accessToken: token.accessToken,
        expiresAt: token.expiresAt,
      };
      return session;
    },
  },
  jwt: {
    // Defaults to 90 days
    maxAge: process.env.AUTH_MAX_AGE
      ? parseInt(process.env.AUTH_MAX_AGE)
      : 7776000,
  },
};
