import { FetchError } from './errors';
import { getGoogleAuthHeaders } from '../authorization';

const parseJSON = async (res: Response) => {
  if (res.status === 204 || res.status === 205) {
    return null;
  }

  const body = await res.text();
  return body ? JSON.parse(body) : {};
};

const checkStatus = async (res: Response) => {
  if (res.status >= 200 && res.status < 300) {
    return;
  }

  const error = new FetchError(res.statusText);
  error.response = res;
  error.status = res.status;

  try {
    const body = await parseJSON(res);
    error.data = body;
  } catch (e: any) {
    if (!e.data) {
      error.parse_error_message = e.message;
    }

    error.data = e.data || { message: error.message };
  }

  throw error;
};

let requestId = 0;

const getCacheKey = (input: RequestInfo | URL) => {
  if (typeof input === 'string') {
    return input;
  }

  if (input instanceof URL) {
    return input.toString();
  }

  return `${input.url}-${input.method}`;
};

export const fetchJsonAndCache =
  (hrsToCache: number) =>
  async <T>(...[input, init]: Parameters<typeof fetch>): Promise<T> => {
    if (typeof window !== 'undefined') {
      return fetchJson(input, init);
    }

    const cache = await import('memory-cache');

    const key = getCacheKey(input as RequestInfo);
    const cachedResponse = cache.get(key);

    if (cachedResponse) {
      return Promise.resolve(cachedResponse);
    }

    const data: T = await fetchJson(input, init);

    cache.put(key, data, hrsToCache * 1000 * 60 * 60);
    return Promise.resolve(data);
  };

export const fetchJson = async <T>(
  ...[input, init]: Parameters<typeof fetch>
): Promise<T> => {
  try {
    requestId += 1;
    const requestTimeMs = Date.now();

    if (typeof window === 'undefined') {
      // eslint-disable-next-line no-console
      console.log(
        `Request ${requestId}: ${input} requested at ${requestTimeMs}`,
      );
    }

    const res = await fetch(input, {
      ...init,
      headers: {
        ...init?.headers,
        'Content-Type': 'application/json',
      },
    });

    if (typeof window === 'undefined') {
      const responseTimeMs = Date.now();
      // eslint-disable-next-line no-console
      console.log(
        `Request ${requestId}: ${input} responded with ${
          res.status
        } at ${responseTimeMs} (${responseTimeMs - requestTimeMs}ms)`,
      );
    }

    await checkStatus(res);

    return parseJSON(res);
  } catch (error: unknown) {
    if (error instanceof FetchError) {
      error.request = {
        url: input as string,
      };
      throw error;
    }

    // eslint-disable-next-line no-console
    console.log(error);

    const unknownError = new FetchError('Unknown fetch error');
    unknownError.request = {
      url: input as string,
    };
    unknownError.status = -1;
    unknownError.data = {
      code: 'network-error',
      message: unknownError.message,
      type: '_network',
    };
    throw unknownError;
  }
};

export const fetchGCPJson = async <T>(
  ...[input, init]: Parameters<typeof fetch>
): Promise<T> =>
  fetchJson(input, {
    ...init,
    headers: {
      ...init?.headers,
      ...(await getGoogleAuthHeaders(input as string)),
    },
  });
