import type { FC, ReactText } from 'react';
import React from 'react';
import styled from 'styled-components';
import type { IconSize } from '../Icons';
import { Icon as BaseIcon } from '../Icons';

import {
  breakpointMediumStart,
  buttonBorderRadius,
  buttonFocusOutlineColor,
  buttonFontSize,
  buttonFontWeight,
  buttonHeight,
  buttonLineHeight,
  buttonPadding,
  buttonDesktopPadding,
  buttonIconSpacing,
  buttonDisabledBackgroundColor,
  buttonDisabledFontColor,
  buttonPrimaryBackgroundColor,
  buttonSecondaryBackgroundColor,
  buttonTertiaryBackgroundColor,
  buttonBorderWidth,
  buttonTertiaryBorderColor,
  buttonPrimaryFontColor,
  buttonSecondaryFontColor,
  buttonTertiaryFontColor,
  buttonPrimaryHoverBackgroundColor,
  buttonSecondaryHoverBackgroundColor,
  buttonTertiaryHoverBackgroundColor,
  fontFamilyMain,
  buttonTertiaryHoverFontColor,
} from '../../design-tokens';
import { IconLoading } from '../../../design-system/components/Icons/Icons';

const tabFocus = `
  outline: 0;

  &:focus-visible {
    box-shadow: 0 0 0 4px ${buttonFocusOutlineColor};
  }
`;

const StyledButtonBase = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: ${buttonBorderRadius};
  font-family: ${fontFamilyMain};
  font-size: ${buttonFontSize};
  font-weight: ${buttonFontWeight};
  line-height: ${buttonLineHeight};
  padding: 0 ${buttonPadding};
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;

  min-height: ${buttonHeight};
  ${tabFocus};

  ${BaseIcon} {
    flex: 0 0 auto;
    margin-right: ${buttonIconSpacing};
  }

  &:disabled {
    background-color: ${buttonDisabledBackgroundColor};
    color: ${buttonDisabledFontColor};
    pointer-events: none;
    border: none;

    ${BaseIcon} {
      svg,
      svg path {
        fill: ${buttonDisabledFontColor};
      }
    }
  }

  @media (min-width: ${breakpointMediumStart}) {
    padding: 0 ${buttonDesktopPadding};
  }
`;

export const ButtonBase = React.forwardRef(
  (
    {
      children,
      onClick,
      Icon,
      className,
      href,
      target,
      iconSize = 'medium',
      iconColor = buttonPrimaryFontColor,
      ...rest
    }: {
      children: ReactText;
      onClick?: () => void;
      Icon?: FC<{ size: IconSize; color: string }>;
      className?: string;
      href?: string;
      target?: string;
      iconSize: IconSize;
      iconColor: string;
      [key: string]: any;
    },
    ref,
  ) => (
    <StyledButtonBase
      ref={ref as any}
      onClick={onClick}
      className={className}
      as={href ? 'a' : 'button'}
      {...(href ? { href, target } : {})}
      {...rest}
    >
      {Icon && <Icon size={iconSize} color={iconColor} />}
      {children}
    </StyledButtonBase>
  ),
);

ButtonBase.displayName = 'ButtonBase';

export const PrimaryButton = styled(ButtonBase)`
  background-color: ${buttonPrimaryBackgroundColor};
  color: ${buttonPrimaryFontColor};

  &:active {
    background-color: ${buttonPrimaryHoverBackgroundColor};
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${buttonPrimaryHoverBackgroundColor};
    }
  }
`;

export const SecondaryButton = styled(ButtonBase)`
  background-color: ${buttonSecondaryBackgroundColor};
  color: ${buttonSecondaryFontColor};

  &:active {
    background-color: ${buttonSecondaryHoverBackgroundColor};
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${buttonSecondaryHoverBackgroundColor};
    }
  }
`;

export const TertiaryButton = styled(ButtonBase)`
  background-color: ${buttonTertiaryBackgroundColor};
  color: ${buttonTertiaryFontColor};
  border: ${buttonBorderWidth} solid ${buttonTertiaryBorderColor};

  // override icon color in all states but disabled
  :not(:disabled) {
    ${BaseIcon} {
      svg,
      svg path {
        fill: ${buttonTertiaryFontColor};
      }
    }
  }

  &:active {
    background-color: ${buttonTertiaryHoverBackgroundColor};
    color: ${buttonTertiaryHoverFontColor};

    ${BaseIcon} {
      svg,
      svg path {
        fill: ${buttonTertiaryHoverFontColor};
      }
    }
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${buttonTertiaryHoverBackgroundColor};
      color: ${buttonTertiaryHoverFontColor};

      ${BaseIcon} {
        svg,
        svg path {
          fill: ${buttonTertiaryHoverFontColor};
        }
      }
    }
  }
`;

const commonLoading = `
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  ${BaseIcon} {
    svg {
      animation: rotation 1.5s linear infinite;
    }
  }
`;

// @TODO Can we reduce the repetition here at all?
export const SecondaryLoadingButton = styled(SecondaryButton)`
  ${commonLoading};
`;

export const TertiaryLoadingButton = styled(TertiaryButton)`
  ${commonLoading};
`;

interface ButtonWithLoaderProps {
  baseText: string;
  className?: string;
  loading: boolean;
  loadingText: string;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
}

// If not submit or reset, you MUST pass an onClick function
interface ButtonProps extends ButtonWithLoaderProps {
  onClick: () => void;
  type?: 'button' | 'submit' | 'reset';
}

interface FormProps extends ButtonWithLoaderProps {
  onClick?: () => void;
  type: 'submit' | 'reset';
}

export const TertiaryButtonWithLoader = ({
  baseText,
  loading,
  loadingText,
  onClick,
  type,
}: ButtonProps | FormProps) => {
  return (
    <TertiaryLoadingButton
      disabled={loading}
      Icon={loading && IconLoading}
      onClick={onClick}
      type={type}
    >
      {loading ? loadingText : baseText}
    </TertiaryLoadingButton>
  );
};

export const PillButton = styled(TertiaryButton)`
  border-radius: 9999px;
`;
