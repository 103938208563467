// Inspired from
// https://medium.com/@martin_hotell/improved-redux-type-safety-with-typescript-2-8-2c11a8062575
type FunctionType = (...args: any[]) => any;
type ActionCreatorsMapObject = { [actionCreator: string]: FunctionType };

export type ActionsUnion<A extends ActionCreatorsMapObject> = ReturnType<
  A[keyof A]
>;

export interface ActionHelper<T extends string> {
  type: T;
}

export interface ActionWithPayloadHelper<T extends string, P>
  extends ActionHelper<T> {
  payload: P;
}

export function createAction<T extends string>(type: T): ActionHelper<T>;
export function createAction<T extends string, P>(
  type: T,
  payload?: P,
): ActionWithPayloadHelper<T, P>;
export function createAction<T extends string, P>(type: T, payload?: P) {
  return payload === undefined ? { type } : { type, payload };
}

export const simpleActionCreator =
  <T extends string>(actionType: T) =>
  () =>
    createAction(actionType);
