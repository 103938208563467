import { logger } from 'lib/utils/logger';
import { IDPTokenSchema } from './auth.schemas';
import { jwtDecode } from 'jwt-decode';

export const getTokens = async (idpToken: string) => {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_IDENTITY_SERVICE_URL}/auth/realms/consumer/token`,
    {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        client_id: process.env.SLASH_ID_AUTH_ID as string,
        client_secret: process.env.SLASH_ID_AUTH_SECRET as string,
        grant_type: 'urn:com:checkatrade:oauth:grant-type:idp-token',
        idp: 'slashid',
        idp_token: idpToken,
        scope: 'openid email offline_access',
      }),
      method: 'POST',
    },
  );

  const tokens = await res.json();

  if (!res.ok) {
    logger.error('Session - Get Tokens failed');
    return null;
  }

  const { email, sub: id } = jwtDecode<{ email: string; sub: string }>(
    tokens.id_token,
  );

  const currentTimeSeconds = Math.floor(Date.now() / 1000);

  return IDPTokenSchema.parse({
    accessToken: tokens.access_token,
    refreshToken: tokens.refresh_token,
    expiresAt: currentTimeSeconds + tokens.expires_in,
    email,
    id,
  });
};
