export const breakpoints = {
  phone: { type: 'max-width', value: 599 },
  tablet: { value: 600 },
  tabletPortrait: { value: 800 },
  tabletPortraitHeight: { type: 'min-height', value: 800 },
  laptop: { value: 1200 },
  desktop: { value: 1800 },
};

const breakpoint = (range, css) => {
  if (!Object.prototype.hasOwnProperty.call(breakpoints, range)) {
    return css;
  }

  const { type = 'min-width', value } = breakpoints[range];

  return `@media (${type}: ${value}px) {
    ${css}
  }`;
};

export default breakpoint;
