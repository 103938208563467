import sizes, { defaultFontSize } from '@toolshed/styleguide/styles/sizes-rem';

const MakeSize = size => {
  // E.g. 'atom', 'large' etc. or '100%'
  if (typeof size === 'string') {
    return sizes[size] ? `${sizes[size]}rem` : size;
  }

  if (typeof size === 'number') {
    return `${size / defaultFontSize}rem`;
  }

  return undefined;
};

export default MakeSize;
