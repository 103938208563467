import config from 'lib/config';
import { fetchJson, fetchJsonAndCache } from 'lib/utils/fetch';
import type {
  APIFunnelJourney,
  APIFunnelScreen,
  APIMetaTitleAndDescription,
  JourneyType,
} from './types';

import createTimeoutAbort from './createTimeoutAbort';

import type {
  Page,
  APICategoryBlurb,
  APIPartnershipCategory,
  APINotificationBanner,
} from './types';
import isPreviewEnabled from './isPreviewEnabled';
import { JOURNEY_TYPES } from './constants';
import { MetaTitleAndDescriptionSchema } from './schema';

export const getContentPage = async (pageName: string): Promise<Page[]> =>
  fetchJson(`${config.strapiUrl}pages?slug=${pageName}`);

export const getPartnershipCategories = async (): Promise<
  APIPartnershipCategory[]
> => fetchJson(`${config.strapiUrl}partnership-categories`);

export const getCategoryInformation = async (
  categoryId: number,
): Promise<APICategoryBlurb> =>
  fetchJson<APICategoryBlurb[]>(
    `${config.strapiUrl}category-blurbs?category_id=${categoryId}`,
  ).then(results => {
    return results && results[0];
  });

export const getScreen = async (id: number): Promise<APIFunnelScreen> =>
  fetchJsonAndCache(1)(`${config.strapiUrl}funnel-screens/${id}`);

export const getFunnelJourney = async (
  categoryId: number,
  type: JourneyType,
): Promise<APIFunnelJourney[]> => {
  const { signal } = createTimeoutAbort(3000);
  let url = `${config.strapiUrl}funnel-journeys/category/${categoryId}`;

  if (isPreviewEnabled()) {
    url += `?_publicationState=preview`;
  }

  const funnelJourneys: APIFunnelJourney[] = await fetchJsonAndCache(1)(url, {
    signal,
  });

  return (funnelJourneys || [])
    .map(funnelJourney => {
      return {
        ...funnelJourney,
        type: funnelJourney.type || JOURNEY_TYPES.filter,
      };
    })
    .filter(funnelJourney => {
      return funnelJourney.type === type;
    });
};

export const getNotificationBanner = async (): Promise<APINotificationBanner> =>
  fetchJson(`${config.strapiUrl}notification-banner`);

export const getMetaTitleAndDescription = async (
  categoryId: number,
): Promise<APIMetaTitleAndDescription> => {
  const response = await fetchJsonAndCache(1)(
    `${config.strapiUrl}trade-categories?categoryId=${categoryId}`,
  );

  const parseResponse = MetaTitleAndDescriptionSchema.safeParse(response);

  if (!parseResponse.success) return {};

  return parseResponse.data[0];
};
