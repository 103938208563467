import { all, fork } from 'redux-saga/effects';

import appSaga from 'containers/AppContainer/saga';

const sagas = () =>
  function* rootSaga() {
    try {
      const sagas = [fork(appSaga)]; // eslint-disable-line
      yield all(sagas);
    } catch (err) {
      /* istanbul ignore next */
      console.log(err); // eslint-disable-line
    }
  };

export default sagas;
