import formatAbsoluteUrl from '@utils/formatters/absolute-url';
import jsonLD from './json-ld';

const url = formatAbsoluteUrl('');

const createSchema = (key = 'general') => {
  const info = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url,
    name: 'Checkatrade: Recommendations you can rely on',
    description:
      'Checkatrade is the most searched for website for finding tradespeople in the UK. Get your home and garden in shape. Fix that DIY you got a bit carried away with.',
  };

  return jsonLD({ info, key });
};

export default createSchema;
