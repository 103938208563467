import styled, { css } from 'styled-components';
import {
  breakpointLargeStart,
  breakpointMediumStart,
  neutral900,
  neutral800,
  fontSizeBodySM,
  fontSizeSubHeading,
  spacing6,
} from '../design-tokens';

export const SubHeadingSemibold = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: ${fontSizeSubHeading};
  line-height: ${spacing6};
  color: ${neutral900};
  margin-top: 0;
`;

export const BodyRegularSM = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: ${fontSizeBodySM};
  line-height: ${spacing6};
  color: ${neutral800};
  margin-top: 0;

  @media (min-width: ${breakpointMediumStart}) {
    font-size: 16px;
  }
`;

export const headingBoldMD = css`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.04em;

  @media (min-width: ${breakpointLargeStart}) {
    font-size: 2rem;
    line-height: 40px;
  }
`;
