import { useState, useEffect } from 'react';
import type {
  Firestore,
  FirestoreError,
  DocumentReference,
  Query,
  DocumentData,
} from 'firebase/firestore';
import { onSnapshot } from 'firebase/firestore';

export const useDocumentData = <T>(
  firestore: Firestore,
  ref: DocumentReference<DocumentData> | null,
): [T | undefined, boolean, FirestoreError | undefined] => {
  const [data, setData] = useState<T | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<FirestoreError | undefined>(undefined);

  useEffect(() => {
    if (!firestore || !ref) {
      setData(undefined);
      setLoading(false);
      return () => {};
    }

    setLoading(true);

    return onSnapshot(
      ref,
      snapshot => {
        setData(snapshot.data() as T);
        setLoading(false);
      },
      e => {
        setError(e);
        setLoading(false);
      },
    );
  }, [firestore, ref]);

  return [loading ? undefined : data, loading, loading ? undefined : error];
};

export const useCollectionData = <T>(
  firestore: Firestore,
  ref: Query<DocumentData> | null,
): [collection: T[], loading: boolean, error: FirestoreError | undefined] => {
  const [collection, setCollection] = useState<T[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<FirestoreError | undefined>(undefined);

  useEffect(() => {
    if (!firestore || !ref) {
      setCollection([]);
      setLoading(false);
      return () => {};
    }

    setLoading(true);

    return onSnapshot(
      ref,
      snapshot => {
        setCollection(snapshot.docs.map(document => document.data() as T));
        setLoading(false);
      },
      e => {
        setLoading(false);
        setError(e);
      },
    );
  }, [firestore, ref]);

  return [collection, loading, error];
};
