import config from 'lib/config';
import { track } from 'lib/sdks/analytics';
import type { OptInToMarketingSource } from './types';

const EMAIL_ADDRESS_STORAGE_KEY = 'latestEmail';

let ephemeralEmailAddress: string | null = null;

export const getEphemeralEmailAddress = () => ephemeralEmailAddress;

export const setEphemeralEmailAddress = (email: string) => {
  ephemeralEmailAddress = email;
};

export const removeEphemeralEmailAddress = () => {
  ephemeralEmailAddress = null;
};

export const getPersistedEmailAddress = () => {
  try {
    return localStorage.getItem(EMAIL_ADDRESS_STORAGE_KEY);
  } catch (e) {
    return null;
  }
};

export const persistEmailAddress = (email: string) => {
  try {
    localStorage.setItem(EMAIL_ADDRESS_STORAGE_KEY, email);
  } catch (e) {
    // ignore
  }
};

export const removePersistedEmailAddress = () => {
  try {
    localStorage.removeItem(EMAIL_ADDRESS_STORAGE_KEY);
  } catch (e) {
    // ignore
  }
};

export const optInToMarketing = async ({
  email,
  firstName,
  lastName,
  source,
  force,
}: {
  email: string;
  firstName?: string;
  lastName?: string;
  source: OptInToMarketingSource;
  force?: boolean;
}) => {
  try {
    await fetch(`${config.consumerAreaApiUrl}api/opt-in-to-marketing`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        firstName,
        lastName,
        source,
        force,
      }),
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  track('consumer_area.opt_in_to_marketing', { source });
};

export const optOutOfMarketing = async ({
  idToken,
  source,
}: {
  idToken: string;
  source: OptInToMarketingSource;
}) => {
  const url = `${config.consumerAreaApiUrl}api/opt-out-of-marketing`;

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify({ source }),
  });
  if (!response.ok) {
    throw new Error(`${response.status} - ${response.statusText}`);
  }

  track('consumer_area.opt_out_of_marketing', { source });
};

export const requestDataUpdate = (idToken: string) =>
  fetch(`${config.consumerAreaApiUrl}api/request-user-data-update`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify({}),
  });

export const authenticateWithToken = async ({
  jobId,
  token,
}: {
  jobId: string;
  token: string;
}): Promise<string> => {
  const url = `${config.consumerAreaApiUrl}api/authenticate`;

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ jobId, token }),
  });
  if (!response.ok) {
    throw new Error(`${response.status} - ${response.statusText}`);
  }

  return response.text();
};

export * from './types';
