import type { Config } from '../types';

export const globalClientConfigKey = '__CLIENT_CONFIG__';

declare global {
  interface Window {
    [globalClientConfigKey]: Config;
  }
}

export const createClientJsonConfigOnServer = (): string => {
  const publicConfig: Config = {
    platform: process.env.PLATFORM === 'local' ? 'local' : 'gcp',
    strapiUrl: new URL(process.env.STRAPI_URL as string).toString(),
    reviewsApiUrl: new URL(process.env.REVIEWS_API_URL as string).toString(),
    searchApiUrl: new URL(process.env.SEARCH_API_URL as string).toString(),
    consumerAreaApiUrl: new URL(
      process.env.CONSUMER_AREA_API_URL as string,
    ).toString(),
    consumerPublicApiUrl: new URL(
      process.env.NEXT_PUBLIC_CONSUMER_PUBLIC_URL as string,
    ).toString(),
  };
  return JSON.stringify(publicConfig);
};

export const getClientConfig = (): Config => window[globalClientConfigKey];
