// Web App colours
// https://www.figma.com/file/VN5ULUUO0VDcz1S7xxKgvx/Design-System?node-id=12131%3A11898
export const colorPrimary175Darkest = '#001629';
export const colorPrimary150Darker = '#002c51';
export const colorPrimary125Dark = '#00427a';
export const colorPrimary100Default = '#0058a2';
export const colorPrimary75Light = '#4082b9';
export const colorPrimary50Lighter = '#80acd1';
export const colorPrimary10Lightest = '#e6eef6';

export const colorSecondary175Darkest = '#34030e';
export const colorSecondary150Darker = '#69051c';
export const colorSecondary125Dark = '#9d082a';
export const colorSecondary100Default = '#d10a38';
export const colorSecondary75Light = '#dd476a';
export const colorSecondary50Lighter = '#e8859c';
export const colorSecondary10Lightest = '#fae7eb';

export const colorTertiary175Darkest = '#040710';
export const colorTertiary150Darker = '#080f21';
export const colorTertiary125Dark = '#0c1631';
export const colorTertiary100Default = '#101d41';
export const colorTertiary75Light = '#4c5671';
export const colorTertiary50Lighter = '#888ea0';
export const colorTertiary10Lightest = '#e7e8ec';

export const colorSuccess175Darkest = '#142a10';
export const colorSuccess150Darker = '#285320';
export const colorSuccess125Dark = '#3c7d2f';
export const colorSuccess100Default = '#50a63f';
export const colorSuccess75Light = '#7cbc6f';
export const colorSuccess50Lighter = '#a8d39f';
export const colorSuccess10Lightest = '#eef6ec';

export const colorAttention175Darkest = '#3e3510';
export const colorAttention150Darker = '#7c6921';
export const colorAttention125Dark = '#ba9e31';
export const colorAttention100Default = '#f8d241';
export const colorAttention75Light = '#fadd71';
export const colorAttention50Lighter = '#fce9a0';
export const colorAttention10Lightest = '#fefbec';

export const colorError175Darkest = '#390505';
export const colorError150Darker = '#720a0a';
export const colorError125Dark = '#aa0e0e';
export const colorError100Default = '#e31313';
export const colorError75Light = '#ea4e4e';
export const colorError50Lighter = '#f18989';
export const colorError10Lightest = '#fce7e7';

export const colorHeaderPrimary = '#101d41';
export const colorHeaderSecondary = '#0058a2';

export const colorTextDefault = '#333333';
export const colorTextSecondary = '#666666';
export const colorTextDisabled = '#999999';
export const colorTextInverse = '#ffffff';
export const colorTextBrandSecondary = '#d10a38';
export const colorTextLinkDefault = '#005f87';
export const colorTextLinkHover = '#007eb4';
export const colorTextLinkPressed = '#003f5a';
export const colorTextLinkDisabled = '#999999';
export const colorTextAssistError = '#e31313';

export const colorBorderActive = '#666666';
export const colorBorderDefault = '#999999';
export const colorBorderDisabled = '#d3d3d3';
export const colorBorderHover = '#333333';
export const colorBorderDecorative = '#ededed';

export const colorShadow = '#000000';

export const colorBackgroundDefault = '#ffffff';
export const colorBackgroundDark = '#cccccc';
export const colorBackgroundLight = '#ededed';
export const colorBackgroundWarm = '#f8f7f5';
export const colorBackgroundDisabled = '#e8e8e8';
export const colorBackgroundRed = '#eb1f40';
export const colorBackgroundLilac = '#9999cc';
export const colorBackgroundOrange = '#ff9900';
export const colorBackgroundEmerald = '#009999';

// Trade App colours
// https://www.figma.com/file/NqxcL107D3k9qrtRdLlxdM/Trade-App-Design-System?node-id=40%3A498
export const colorFaffBlue = '#f9faff';

// Mortar Colours
export const colorBlueGreyFull = '#62687A';
export const colorLinkBlue = '#0066FF';
export const colorNavyBlueFull = '#00112C';
export const colorLightGrey = '#d7d7d7';

export const success500 = '#36C075';
export const colorSystemOrange = '#FF7E47';
