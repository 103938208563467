import { debounce } from '@utils/debounce';

const RETRY_DELAY = 1500;
const DEBOUNCE_DELAY = 300;
const RETRY_LIMIT = 3;

export class ApiQueue<T> {
  private readonly apiFn: (data: T[]) => Promise<{ success: boolean }>;
  private queue: T[];
  private retryCount: number;

  private readonly processQueueDebounced: Function;

  constructor(apiFn: (data: T[]) => Promise<{ success: boolean }>) {
    this.apiFn = apiFn;
    this.queue = [];
    this.retryCount = 0;

    this.processQueueDebounced = debounce(async () => {
      await this.processQueue();
    }, DEBOUNCE_DELAY);
  }

  private async sendData(data: T[]) {
    return await this.apiFn(data);
  }

  private flushQueue() {
    return this.queue.splice(0);
  }

  private addToQueue(data: T[]) {
    this.queue.push(...data);
  }

  private retry(data: T[]) {
    this.addToQueue(data);
    this.retryCount++;
    if (this.retryCount < RETRY_LIMIT) {
      setTimeout(this.processQueueDebounced, RETRY_DELAY);
    }
  }

  private async processQueue() {
    const dataToProcess = this.flushQueue();

    if (!dataToProcess.length) return;

    let response;

    try {
      response = await this.sendData(dataToProcess);
    } catch (e) {
      response = { success: false };
    }

    if (response?.success) {
      this.retryCount = 0;
    } else {
      this.retry(dataToProcess);
    }
  }

  add(data: T) {
    this.queue.push(data);
    this.processQueueDebounced();
  }
}
