export const OUT_OF_TEST_VALUE = -1;
export const CONTROL_VALUE = 0;

type ABTestState = Record<string, number>;

export const ABTestStates: ABTestState = {
  INACTIVE: OUT_OF_TEST_VALUE,
  CONTROL: CONTROL_VALUE,
} as const;

// Current AB tests
// Keep these in alphabetical order
export const RANKING_ALGORITHMS = 'ranking-algorithms';
export const SEARCH_ALGO_EXPERIMENT_ID = 'S-1375';
export const START_DATE_SCREEN = 'start-date-screen';
export const SERVER_TRACKING = 'server_tracking';

// Current AB test states
// Keep these in alphabetical order
export const RankingAlgorithmStates: ABTestState = {
  ...ABTestStates,
  NEW_ALGO_WEIGHT_3: 23,
} as const;

export const StartDateScreenStates: ABTestState = {
  ...ABTestStates,
  START_DATE_SCREEN_FIRST: 1,
} as const;

export const UpdatedTrackingToggleStates: ABTestState = {
  ...ABTestStates,
  ENABLED: 1,
} as const;
