/* eslint-disable camelcase */
export class FetchError extends Error {
  response?: Response;

  status?: number;

  parse_error_message?: string;

  data?: any;

  request?: {
    url: string;
  };

  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, FetchError.prototype);
  }
}
