import deepmerge from 'deepmerge';

const STORAGE_NAME = 'state';

const noop = v => v;

export const getLocalStorage = () => {
  const data = localStorage.getItem(STORAGE_NAME);
  return JSON.parse(data);
};

export const attachHandler = handler => e => {
  if (e.key !== STORAGE_NAME) {
    return;
  }

  handler(JSON.parse(e.newValue));
};

export const subscribe = handler => {
  window.addEventListener('storage', attachHandler(handler), false);
};

export const loadState = () => {
  try {
    const json = getLocalStorage();

    return json;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error); // Note: This should be handled by Datadog when it is installed

    return {};
  }
};

export const saveState = (state, keys = []) => {
  try {
    let dataToSave = {};

    if (!keys.length) {
      dataToSave = state;
    }

    keys.map(descriptor => {
      const key = descriptor.key || descriptor;
      const presave = descriptor.presave || noop;
      const path = key.split('.');
      const getIn = (p, o) =>
        p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);
      const value = getIn(path, state);

      const expand = (str, val) =>
        str.reduceRight((acc, currentValue) => ({ [currentValue]: acc }), val);
      const res = expand(path, presave(value));
      // logic to handle multiple values in keys e.g. global.shortlist and global.projects
      dataToSave = deepmerge(dataToSave, res);

      return value;
    });

    const serializedState = JSON.stringify(dataToSave);
    localStorage.setItem(STORAGE_NAME, serializedState);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error); // Note: This should be handled by Datadog when it is installed
  }
};
