import { debugLog } from 'lib/utils/debug';
import type { Event, Plugin } from '../../types';
import { formatEventData } from '../../formatter';

declare global {
  interface Window {
    dataLayer?: any[];
  }
}

const pushToDataLayer = (item: any) => {
  debugLog('GTM push: ', item);
  window.dataLayer?.push(item);
};

export const GTM: Plugin = (event: Event) => {
  if (!window.dataLayer) {
    return;
  }

  // We currently push the same event data shape that Kinesis tracks to the dataLayer,
  // importing the formatting logic from there.
  // TODO: why are we pushing the full event data? What is it used for and can we use a simplified structure instead?
  pushToDataLayer({ ...formatEventData(event), event: event.name });

  if (event.name === 'global.page_view') {
    // TODO: why are we pushing experiments object and string? can we remove?
    const experimentsObject = event.context.app.experiments.reduce(
      (obj, experiment) => ({
        ...obj,
        [experiment.name]: {
          experimentId: experiment.id,
          variationId: experiment.variant,
        },
      }),
      {},
    );

    const experimentString = event.context.app.experiments
      .map(experiment => {
        return `${experiment.id}.${experiment.variant}`;
      })
      .join('!');

    pushToDataLayer(experimentsObject);
    pushToDataLayer({ exp: experimentString });
  }
};
