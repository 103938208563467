import { v4 } from 'lib/utils/uuid';
import { trackEvent } from './track';
import type {
  CustomEventData,
  EventName,
  FlowEnrollment,
  FlowId,
} from './types';

const flowEnrollments = new Map<FlowId, FlowEnrollment>();
const flowContexts = new Map<FlowId, CustomEventData>();

export const clearFlowData = (flowId: FlowId) => {
  if (flowEnrollments.has(flowId)) {
    flowEnrollments.delete(flowId);
  }

  if (flowContexts.has(flowId)) {
    flowContexts.delete(flowId);
  }
};

export const updateFlowContext = (flowId: FlowId, context: CustomEventData) => {
  const currentFlowContext = flowContexts.get(flowId);

  flowContexts.set(flowId, {
    ...(currentFlowContext ?? {}),
    ...context,
  });
};

const getFlowEventData = (flowId: FlowId, customData?: CustomEventData) => {
  const flowContext = flowContexts.get(flowId);

  if (!flowContext && !customData) {
    return undefined;
  }

  return { ...(flowContext ?? {}), ...(customData ?? {}) };
};

export const startFlow = (
  flowId: FlowId,
  stepId?: string | number,
  customData?: CustomEventData,
) => {
  if (flowEnrollments.has(flowId)) {
    return;
  }

  const flowEnrollment: FlowEnrollment = {
    flowId,
    enrollmentId: v4(),
    currentStepId: stepId,
  };

  flowEnrollments.set(flowId, flowEnrollment);

  trackEvent('started', getFlowEventData(flowId, customData), flowEnrollment);
};

export const persistFlow = (flowId: FlowId) => {
  const flowEnrollment = flowEnrollments.get(flowId);
  const currentFlowContext = flowContexts.get(flowId);

  if (!flowEnrollment) {
    return;
  }

  localStorage.setItem('flow-tracking', JSON.stringify(flowEnrollment));
  currentFlowContext &&
    localStorage.setItem('flow-context', JSON.stringify(currentFlowContext));
};

export const restoreFlow = (flowId: FlowId) => {
  if (!localStorage.getItem('flow-tracking')) {
    return;
  }

  const flowEnrollment = JSON.parse(
    localStorage.getItem('flow-tracking') as string,
  );

  flowEnrollments.set(flowId, flowEnrollment);

  if (!localStorage.getItem('flow-context')) return;

  const flowContext = JSON.parse(
    localStorage.getItem('flow-context') as string,
  );

  flowContexts.set(flowId, flowContext);
};

export const updateFlow = (
  flowId: FlowId,
  stepId: string | number,
  customData?: CustomEventData,
) => {
  if (!flowEnrollments.has(flowId)) {
    // Starts flow automatically instead of update track event
    // if it hasn't been previously started
    return startFlow(flowId, stepId, customData);
  }

  let flowEnrollment = flowEnrollments.get(flowId);

  if (!flowEnrollment) {
    return;
  }

  flowEnrollment = {
    ...flowEnrollment,
    currentStepId: stepId,
    previousStepId: flowEnrollment.currentStepId,
  };

  flowEnrollments.set(flowId, flowEnrollment);

  trackEvent(
    'step_changed',
    getFlowEventData(flowId, customData),
    flowEnrollment,
  );
};

const endFlow = (
  eventName: EventName,
  flowId: FlowId,
  customData?: CustomEventData,
  stepId?: string | number,
) => {
  localStorage.removeItem('flow-tracking');
  localStorage.removeItem('flow-context');

  if (!flowEnrollments.has(flowId)) {
    // TODO: check if we want to add customData
    startFlow(flowId);
  }

  let flowEnrollment = flowEnrollments.get(flowId);

  if (!flowEnrollment) {
    return;
  }

  if (stepId) {
    flowEnrollment = {
      ...flowEnrollment,
      currentStepId: stepId,
      previousStepId: flowEnrollment.currentStepId,
    };
  }

  trackEvent(eventName, getFlowEventData(flowId, customData), flowEnrollment);

  clearFlowData(flowId);
};

export const clearFlow = (
  flowId: FlowId,
  customData?: CustomEventData,
  stepId?: string | number,
) => {
  endFlow('cleared', flowId, customData, stepId);
};

export const stopFlow = (flowId: FlowId, customData?: CustomEventData) => {
  endFlow('stopped', flowId, customData);
};
