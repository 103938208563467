import type { ReactNode } from 'react';
import React, { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import type { Firestore } from 'firebase/firestore';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { useFirebaseApp, useFirebaseConfig } from '../app';

export const FirestoreContext = createContext<Firestore | null>(null);

export const FirestoreProvider = ({ children }: { children: ReactNode }) => {
  const app = useFirebaseApp();
  const config = useFirebaseConfig();
  const firestore = useMemo(() => {
    const fs = getFirestore(app);
    if (config && config.firestoreEmulatorHost) {
      try {
        const [host, port] = config.firestoreEmulatorHost.split(':');
        connectFirestoreEmulator(fs, host, +port);
      } catch (e) {
        // there is nothing in the firebase sdk
        // to check if firestore has already initialized
      }
    }

    return fs;
  }, [app, config]);

  return (
    <FirestoreContext.Provider value={firestore}>
      {children}
    </FirestoreContext.Provider>
  );
};

FirestoreProvider.propTypes = {
  children: PropTypes.any,
};
