import { getCookie } from 'lib/utils/cookie';

const isPreviewEnabled = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  const strapiPreviewMode = getCookie('strapi-preview');

  if (strapiPreviewMode === '1') {
    return true;
  }

  return false;
};

export default isPreviewEnabled;
