import React, { useEffect } from 'react';
import styled from 'styled-components';
import Head from 'next/head';
import { useDispatch, useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import { FirebaseAppProvider } from '@fire/app';
import { FirestoreProvider } from '@fire/firestore';
import { AuthProvider } from '@fire/auth';
import spacing from '@styles/spacing';
import createGeneral from '@schemas/create-general';
import injectGAWhenReady from '@utils/inject-ga';
import Nav from 'components/Nav/Nav';
import Footer from 'components/Footer/Footer';
import type { Router } from 'next/router';
import { track } from 'lib/sdks/analytics';
import useNoticeBanner from 'components/NoticeBanner/use-notice-banner';
import { SessionProvider } from 'next-auth/react';

import {
  setModal,
  addToShortlist,
  handleShortlistClick,
  getCategoryByNameInit,
  getCategoriesSuccess,
  getTradeByNameInit,
  getTradeByNameSuccess,
  locationChange,
} from './actions';

import {
  selectGlobalError,
  makeSelectModal,
  makeSelectShortlistModalShown,
  makeSelectShortlistFull,
  makeSelectGtmId,
  selectFirebaseEnv,
  selectShortlistData,
  selectMessageTraderHistory,
} from './selectors';
import type { AppContainerProps } from './AppContainer.interfaces';
import { debounce } from '@utils/debounce';
import { ConsumerProvider } from 'lib/consumer/consumer.context';
import { BrazeProvider } from 'lib/sdks/braze/braze-provider';

const NoticeBanner = dynamic(
  () => import('components/NoticeBanner/notice-banner'),
);

const ErrorContainer = dynamic(
  () => import('containers/ErrorContainer/ErrorContainer'),
);

const ModalManager = dynamic(() => import('./ModalManager'), {
  ssr: false,
});

const Page = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${spacing.navTotal}px;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
`;

interface AppContainerInterface {
  Component: React.ElementType;
  pageProps: Record<string, any>;
  router: Router;
  query: object;
}

const AppContainer = ({
  Component,
  pageProps: { session, ...pageProps },
  router,
  ...rest
}: AppContainerInterface) => {
  const [showBanner, bannerData] = useNoticeBanner();

  const dispatch = useDispatch();

  const categoryDebounce = debounce(
    (payload: { category: string }) => dispatch(getCategoryByNameInit(payload)),
    300,
  );

  const gtmId = useSelector(makeSelectGtmId());

  const propsToPassDown: AppContainerProps = {
    ...rest,
    router,
    globalError: useSelector(selectGlobalError),
    modal: useSelector(makeSelectModal()),
    shortlist: useSelector(selectShortlistData),
    shortlistModalShown: useSelector(makeSelectShortlistModalShown()),
    shortlistFull: useSelector(makeSelectShortlistFull()),
    messageHistory: useSelector(selectMessageTraderHistory),
    onTrackEvent: ({ key: eventName, data: customData }) => {
      track(eventName, customData); // TODO: call this function directly in all locations instead of passing down onTrackEvent everywhere
    },
    onSetModal: (payload: Parameters<typeof setModal>[0]) =>
      dispatch(setModal(payload)),
    onDismissModal: () =>
      dispatch(setModal({ name: undefined, data: undefined })),
    onAddToShortlist: (payload: Parameters<typeof addToShortlist>[0]) =>
      dispatch(addToShortlist(payload)),
    handleShortlistClick: (
      payload: Record<string, unknown>,
      isShortlisted: boolean,
      trackingData: unknown,
    ) =>
      dispatch(
        handleShortlistClick({
          ...payload,
          isShortlisted,
          trackingData,
        }),
      ),
    handleCategorySearch: (payload: string) => {
      if (payload) {
        categoryDebounce({ category: payload });
      } else {
        dispatch(getCategoriesSuccess({ data: [] }));
      }
    },
    getTradeByName: (payload: Parameters<typeof getTradeByNameInit>[0]) =>
      dispatch(getTradeByNameInit(payload)),
    setTradesByName: (payload: Parameters<typeof getTradeByNameSuccess>[0]) =>
      dispatch(getTradeByNameSuccess(payload)),
    dispatch,
  };

  useEffect(() => {
    track('global.page_view');

    setTimeout(() => {
      // TODO: this causes an immediate re-render of the whole app on every load
      // it would probably be better to set the url in initialState on the server instead
      dispatch(
        locationChange({
          url: router.asPath,
        }),
      );
    }, 0);

    const onRouteChangeComplete = (url: string) => {
      dispatch(locationChange({ url }));
      track('global.page_view');
    };

    router.events.on('routeChangeComplete', onRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [dispatch, router]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        return;
      }

      track('global.application_in_foreground');
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    handleVisibilityChange();

    injectGAWhenReady(gtmId);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [gtmId]);

  const firebaseEnv = useSelector(selectFirebaseEnv);

  return (
    <SessionProvider session={session}>
      <FirebaseAppProvider config={firebaseEnv}>
        <FirestoreProvider>
          <AuthProvider trackEvent={propsToPassDown.onTrackEvent}>
            <ConsumerProvider>
              <BrazeProvider>
                <Head>{createGeneral()}</Head>
                <Nav {...propsToPassDown} />
                {propsToPassDown.modal && <ModalManager {...propsToPassDown} />}
                <Page data-guid="AppContainer" role="main">
                  {showBanner && <NoticeBanner {...bannerData} />}
                  {!propsToPassDown.globalError && (
                    <Component {...pageProps} {...propsToPassDown} />
                  )}
                  {!!propsToPassDown.globalError && (
                    <ErrorContainer {...pageProps} {...propsToPassDown} />
                  )}
                </Page>
                <Footer onTrackEvent={propsToPassDown.onTrackEvent} />
              </BrazeProvider>
            </ConsumerProvider>
          </AuthProvider>
        </FirestoreProvider>
      </FirebaseAppProvider>
    </SessionProvider>
  );
};

export default AppContainer;
