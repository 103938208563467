const createTimeoutAbort = (durationMs: number) => {
  const controller = new AbortController();

  const id = setTimeout(() => controller.abort(), durationMs);
  const clear = () => clearTimeout(id);

  return { id, signal: controller.signal, clear };
};

export default createTimeoutAbort;
