const debugIsOn =
  typeof window !== 'undefined' &&
  new URLSearchParams(window.location.search).get('catdebug') === '1';

export const debugLog: typeof console.log = (...args) => {
  if (!debugIsOn) {
    return;
  }

  // eslint-disable-next-line no-console
  console.log(...args);
};
