/* istanbul ignore file */

/* istanbul ignore next */
const initialState = {
  query: undefined,
  data: undefined,
  key: undefined,
  loading: undefined,
  error: undefined,
  categories: undefined,
  refineCategories: undefined,
  category: undefined,
  locations: undefined,
  location: undefined,
  postcode: undefined,
  locationToPostcode: {},
};

export default initialState;
