import { useEffect } from 'react';
import hasRef from '@toolshed/core-react/utils/has-ref';

const SUPPORTED_FOCUSABLE_SELECTORS = [
  'a:not([tabindex="-1"])',
  'button:not([tabindex="-1"])',
  'input:not([tabindex="-1"])',
  'select:not([tabindex="-1"])',
  'textarea:not([tabindex="-1"])',
  '[tabindex]:not([tabindex="-1"])',
];

export const getFocusableElements = wrapperRef => {
  return hasRef(wrapperRef)
    ? wrapperRef.current.querySelectorAll(
        SUPPORTED_FOCUSABLE_SELECTORS.join(', '),
      )
    : [];
};

const getFirst = wrapperRef => getFocusableElements(wrapperRef)[0];

const getLast = wrapperRef => {
  const elements = getFocusableElements(wrapperRef);
  return elements[elements.length - 1];
};

const focusOnOutsideOrEl = (outsideRef, el) => {
  if (hasRef(outsideRef)) {
    outsideRef.current.focus();
  } else {
    el.focus();
  }
};

export const onTabKeyDown = (e, wrapperRef, outsideRef) => {
  if (!hasRef(wrapperRef)) {
    return;
  }

  const first = getFirst(wrapperRef);
  const last = getLast(wrapperRef);
  const isBackTab = e.shiftKey;

  if (hasRef(outsideRef) && outsideRef.current === e.target) {
    e.preventDefault();
    (isBackTab ? last : first).focus();
    return;
  }

  if (e.target === first && isBackTab) {
    e.preventDefault();
    focusOnOutsideOrEl(outsideRef, last);
    return;
  }

  if (
    (e.target === last && !isBackTab) ||
    !wrapperRef.current.contains(e.target)
  ) {
    e.preventDefault();
    focusOnOutsideOrEl(outsideRef, first);
  }
};

/* istanbul ignore next */
const UseTrapFocusWithin = (wrapperRef, outsideRef = null) => {
  const onKeyDown = e => {
    if (e.key === 'Tab') {
      onTabKeyDown(e, wrapperRef, outsideRef);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);
};

export default UseTrapFocusWithin;
