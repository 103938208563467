import {
  SET_MODAL,
  ADD_TO_SHORTLIST,
  REMOVE_FROM_SHORTLIST,
  SET_MESSAGE_TRADER_SENT,
  REMOVE_RECENT_SEARCH,
  ADD_RECENT_SEARCH,
} from 'containers/AppContainer/constants';

import {
  localStorageChange,
  localStorageLoaded,
  localStorageNoAccess,
} from 'containers/AppContainer/actions';

const config = {
  saveOnActionTypes: [
    SET_MODAL,
    ADD_TO_SHORTLIST,
    REMOVE_FROM_SHORTLIST,
    SET_MESSAGE_TRADER_SENT,
    ADD_RECENT_SEARCH,
    REMOVE_RECENT_SEARCH,
  ],
  localStorageChange,
  localStorageLoaded,
  localStorageNoAccess,
  keys: [
    'global.shortlist',
    'global.recentSearches',
    {
      key: 'global.messageTraderHistory',
      presave: history =>
        history.map(({ expires, ...rest }) => ({
          ...rest,
          expires: expires || Date.now() + 30 * 24 * 60 * 60 * 1000, // expires in 30 days
        })),
    },
    'giveFeedback',
  ],
};

export default config;
