import { track } from 'lib/sdks/analytics';
import { SET_MODAL } from 'containers/AppContainer/constants';
import { makeSelectModal } from 'containers/AppContainer/selectors';

const trackError = (payload: { data: { message: string; code: string } }) => {
  const { data: { message, code } = {} } = payload;
  track('error.message', {
    message,
    response_code: code,
  });
};

const trackModal = ({ name }: { name?: string }, preState: any) => {
  const modalName = makeSelectModal()(preState);

  track(name ? 'global.modal_appear' : 'global.modal_close', {
    modal_name: name || modalName,
  });
};

const analyticsMiddleware = (store: any) => (next: any) => (action: any) => {
  const preState = store.getState();
  const result = next(action);
  const { type, payload } = action;

  if (type.endsWith('_ERROR')) {
    trackError(payload);
    return result;
  }

  if (type === SET_MODAL) {
    trackModal(payload, preState);
    return result;
  }

  return result;
};

export default analyticsMiddleware;
