import config from 'lib/config';
import { getGoogleAuthHeaders } from 'lib/utils/authorization';
import { fetchGCPJson, fetchJsonAndCache } from 'lib/utils/fetch';
import type {
  PaginationOptionsGcp,
  V3SearchOptions,
} from 'lib/utils/pagination';
import { applyPaginationGcp } from 'lib/utils/pagination';
import { appendQuery } from 'lib/utils/url';
import type {
  PaginatedTradesBasicGcp,
  PaginatedReviews,
  Trade,
  TradeBasicGcp,
  getTradeWorkRadiusArgs,
  tradeWorkRadiusRes,
} from '.';
import type {
  TradeBasic,
  PaginatedTrades,
  Category,
  Location,
  PaginatedCategories,
  searchCategoriesOptions,
  Contact,
} from './types';

const ONE_HOUR = 1;

/**
 * Returns a list of search categories
 */
export const searchCategories = async (
  includeAliasCategories: boolean,
): Promise<Category[]> => {
  let url = `${config.searchApiUrl}api/v1/category`;
  if (includeAliasCategories) {
    url = `${url}?alias=true`;
  }

  return fetchJsonAndCache(ONE_HOUR)(url, {
    headers: await getGoogleAuthHeaders(url),
  });
};

/**
 * Returns an array of categories that matches the search criteria
 */
export const searchCategoriesByName = async ({
  category,
  abortSignal,
}: searchCategoriesOptions): Promise<Category[]> => {
  const url = `${
    config.searchApiUrl
  }api/v1/category/search?filter=name[like]:${encodeURIComponent(category)}`;

  const response = await fetchGCPJson<PaginatedCategories>(url, {
    signal: abortSignal,
  });
  return response.items;
};

/**
 * Returns a HTTP response code to confirm a postcode and category combination is within a given trade's work radius
 */
export const checkTradeWorkRadius = async ({
  categoryId,
  companyId,
  postcode,
}: getTradeWorkRadiusArgs): Promise<tradeWorkRadiusRes> => {
  const url = `${config.searchApiUrl}api/v1/trade/${companyId}/search-locations/${postcode}/${categoryId}`;
  const response = await fetchGCPJson<tradeWorkRadiusRes>(url);
  return response;
};

/**
 * Returns a trade that matches given trade id
 */
export const getCompanyByTradeId = async (
  tradeId: number,
): Promise<TradeBasicGcp | null> => {
  const url = `${config.searchApiUrl}api/v1/trade/search?filter=tradeid[eq]:${tradeId}`;
  const response: PaginatedTradesBasicGcp = await fetchGCPJson(url);
  if (!response || response.items.length <= 0) {
    return null;
  }

  return response.items[0];
};

/**
 * Returns a trade that matches given unique name
 */
export const getCompanyByUniqueName = async (
  uniqueName: string,
  view?: 'feedback',
): Promise<TradeBasicGcp | null> => {
  let url = `${config.searchApiUrl}api/v1/trade/search?filter=uniqueName[eq]:${uniqueName}`;
  if (view) {
    url += `;view[eq]:${view}`;
  }

  const response: PaginatedTradesBasicGcp = await fetchGCPJson(url);
  if (!response || response.items.length <= 0) {
    return null;
  }

  return response.items[0];
};

/**
 * Returns a trade that matches given company id and then fetches the trade by its unique name
 */
export const getTradeByCompanyId = async (
  companyId: number,
): Promise<Trade | null> => {
  const response = await fetchGCPJson<{ uniqueName?: string }>(
    `${config.searchApiUrl}api/v1/trade/${companyId}`,
  );
  if (!response || !response.uniqueName) {
    return null;
  }

  return await getTradeByUniqueName(response.uniqueName);
};

/**
 * Returns an object of multiple trades that match given company ids
 */
export const getTradesMapByCompanyIds = async (
  companyIds: number[],
): Promise<Record<string, Trade | null>> =>
  (await Promise.allSettled(companyIds.map(getTradeByCompanyId))).reduce(
    (trades: Record<string, Trade | null>, result, index) => {
      const tradeId = companyIds[index];
      trades[tradeId] =
        result.status !== 'fulfilled' || !result.value ? null : result.value;
      return trades;
    },
    {},
  );

/**
 * Returns an array of trades that matches given company ids
 */
export const getTradesByCompanyIds = async (
  companyIds: number[],
): Promise<TradeBasicGcp[]> => {
  const url = `${
    config.searchApiUrl
  }api/v1/trade/search?filter=companyId[in]:${companyIds.join(',')}`;

  const response = await fetchGCPJson<PaginatedTradesBasicGcp>(url);

  const sortedTrades: TradeBasicGcp[] = [];
  companyIds.forEach((companyId: number) => {
    const trade = response.items.find(x => x.companyId === companyId);
    if (trade) {
      sortedTrades.push(trade);
    }
  });

  return Promise.resolve(sortedTrades);
};

/**
 * Returns a valid postcode for the given location
 */
export const searchLocations = async (
  location: string,
): Promise<Location[]> => {
  const url = `${config.searchApiUrl}api/v1/location/${location}`;
  return fetchGCPJson(url);
};

interface SearchCompaniesOptions {
  companyName: string;
  abortSignal?: AbortSignal;
  view: 'profile' | 'feedback';
}

/**
 * Returns an array of trades that match the search criteria
 * "name" filter allows to search by using name aliases
 */
const searchCompanies = async ({
  companyName,
  abortSignal,
  view,
}: SearchCompaniesOptions): Promise<TradeBasic[]> => {
  const url = `${
    config.searchApiUrl
  }api/v1/trade/search?filter=name[like]:${encodeURIComponent(
    companyName,
  )};view[eq]:${view}`;

  const response = await fetchGCPJson<PaginatedTradesBasicGcp>(url, {
    signal: abortSignal,
  });

  return response.items.map(
    ({ companyId, location, name, tradeId, uniqueName }) => ({
      id: companyId.toString(),
      location: location || '',
      name,
      ttrid: tradeId,
      // eslint-disable-next-line camelcase
      unique_name: uniqueName,
    }),
  );
};

/**
 * Returns an array of trades that match the search criteria
 * "feedback" view excludes Unclaimed, NonMember and ExMember from the search
 */
export const searchTradeLookup = async (
  companyName: string,
  abortSignal?: AbortSignal,
): Promise<TradeBasicGcp[]> => {
  const url = `${
    config.searchApiUrl
  }api/v1/trade/search?filter=name[like]:${encodeURIComponent(
    companyName,
  )};view[eq]:feedback`;

  const response = await fetchGCPJson<PaginatedTradesBasicGcp>(url, {
    signal: abortSignal,
  });

  return response.items;
};

/**
 * Returns an array of trades that match the search criteria
 * "profile" view excludes NewMembers
 */
export const searchProfileCompanies = async (
  companyName: string,
  abortSignal?: AbortSignal,
): Promise<TradeBasic[]> => {
  return searchCompanies({
    view: 'profile',
    abortSignal,
    companyName,
  });
};

/**
 * Returns an array of trades that match the search criteria
 * "feedback" view excludes Unclaimed, NonMember and ExMember from the search
 */
export const searchFeedbackCompanies = async (
  companyName: string,
  abortSignal?: AbortSignal,
): Promise<TradeBasic[]> => {
  return searchCompanies({
    view: 'feedback',
    abortSignal,
    companyName,
  });
};

/**
 * Returns a trade by its unique name
 */
export const getTradeByUniqueName = async (
  uniqueName: string,
): Promise<Trade> => {
  const url = `${config.searchApiUrl}api/v1/trade-profile/${uniqueName}`;

  const res = await fetchGCPJson<Trade>(url);

  const {
    view: { showWebsiteUrl, ...view },
    contact,
  } = res || {};

  const heroWebsiteUrl = showWebsiteUrl
    ? contact?.contacts.find((contactDetails: Contact) =>
        // Checking that its not a number, if not we assume its a website address.
        isNaN(parseInt(contactDetails?.href || '')),
      )
    : undefined;

  return {
    ...res,
    view: {
      ...view,
      heroWebsiteUrl: heroWebsiteUrl?.href ?? null,
    } as Trade['view'],
  };
};

/**
 * Returns a paginated array of trade cards refined by the search options given
 */
export const v3Search = async (
  categoryLabel: string,
  location: string,
  {
    page = 1,
    size = 12,
    algorithm = '',
    excludeCompanyIds,
    injections,
  }: Partial<V3SearchOptions>,
  abortSignal?: AbortSignal,
): Promise<PaginatedTrades> => {
  const url = `${config.searchApiUrl}api/v1/trade-card/search`;
  let queriedUrl = appendQuery(url, {
    filter: `category[eq]:${encodeURIComponent(
      categoryLabel,
    )};postcode[eq]:${location}`,
  });

  let paginationObj: Partial<PaginationOptionsGcp> = { page, size };

  if (algorithm || algorithm === 'raq') {
    paginationObj = { ...paginationObj, algorithm };
  }

  if (excludeCompanyIds) {
    queriedUrl += `;companyId[nin]:${excludeCompanyIds.toString()}`;
  }

  if (typeof injections !== 'undefined') {
    paginationObj = { ...paginationObj, injections };
  }

  const paginatedUrl = applyPaginationGcp(queriedUrl, paginationObj);

  return fetchGCPJson<PaginatedTrades>(paginatedUrl, {
    signal: abortSignal,
  });
};

/**
 * Returns reviews for a specific trade
 */
export const getReviewsByUniqueName = async (
  uniqueName: string,
  paginationOptions: Pick<PaginationOptionsGcp, 'size' | 'sort' | 'page'>,
): Promise<PaginatedReviews> => {
  const url = applyPaginationGcp(
    `${config.searchApiUrl}api/v1/trade-profile/${uniqueName}/review`,
    paginationOptions,
  );
  return fetchGCPJson<PaginatedReviews>(url);
};
