import publicRuntimeConfig from '@utils/public-runtime-config';

export const MEMBERS = 'members';
export const JOIN = 'join';

function getHostname(host) {
  switch (host) {
    case MEMBERS:
      return publicRuntimeConfig.URL_MEMBERS_HOSTNAME;
    case JOIN:
      return publicRuntimeConfig.URL_JOIN_HOSTNAME;
    default:
      return publicRuntimeConfig.URL_HOSTNAME;
  }
}

const absoluteUrl = (path = '', host = '') => {
  return `${publicRuntimeConfig.URL_PROTOCOL}://${getHostname(host)}${
    path && path.charAt(0) !== '/' ? '/' : ''
  }${path}`;
};

export default absoluteUrl;
