import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import {
  LinkItem,
  LinkItemInner,
  LinkWrapper,
  NavIconContainer,
} from 'components/Nav/styles';
import styled from 'styled-components';
import spacing from '@styles/spacing';
import colors from '@toolshed/styleguide/styles/colors';
import useTrapFocusWithin from '@toolshed/core-react/hooks/use-trap-focus-within';
import Badge from 'components/Badge/Badge';

const MegaHomeowner = dynamic(() => import('components/Nav/MegaHomeowner'), {
  ssr: false,
});

const MegaTrades = dynamic(() => import('components/Nav/MegaTrades'), {
  ssr: false,
});

export const Wrapper = styled.div`
  position: fixed;
  top: ${spacing.nav}px;
  left: 0;
  right: 0;
  z-index: 104;
  border-bottom: 4px solid ${colors.skyBlue};

  a:hover,
  a:link,
  a:visited {
    text-decoration: none;
  }
`;

export const Outside = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3;
`;

export const underlineStyle = { borderBottom: `3px solid ${colors.white}` };

export const homeOwnerType = 'homeowner';

export const tradesType = 'trades';

const MegaLink = ({
  isOpen,
  linkText,
  onClickOutside,
  onLinkClick,
  openStyle,
  type,
  'data-guid': dataGuid,
  hasBadge,
  shortlistLength,
}) => {
  const Component = type === homeOwnerType ? MegaHomeowner : MegaTrades;
  const linkRef = useRef(null);
  const wrapperRef = useRef(null);
  const style = isOpen ? openStyle : {};

  useTrapFocusWithin(wrapperRef, linkRef);

  return (
    <>
      <LinkWrapper onClick={onLinkClick} style={style}>
        <LinkItem
          data-guid={dataGuid}
          style={isOpen ? underlineStyle : undefined}
          ref={linkRef}
        >
          <LinkItemInner style={{ color: style.color }}>
            {linkText}
          </LinkItemInner>
        </LinkItem>
        {hasBadge && (
          <NavIconContainer>
            <Badge value={shortlistLength} />
          </NavIconContainer>
        )}
      </LinkWrapper>

      {isOpen && (
        <>
          <Outside onClick={onClickOutside} />
          <Wrapper ref={wrapperRef}>
            <Component shortlistLength={shortlistLength} />
          </Wrapper>
        </>
      )}
    </>
  );
};

MegaLink.defaultProps = {
  isOpen: false,
  openStyle: {},
};

MegaLink.propTypes = {
  isOpen: PropTypes.bool,
  linkText: PropTypes.string.isRequired,
  onClickOutside: PropTypes.func.isRequired,
  onLinkClick: PropTypes.func.isRequired,
  openStyle: PropTypes.object,
  type: PropTypes.string.isRequired,
  'data-guid': PropTypes.string,
  hasBadge: PropTypes.bool,
  shortlistLength: PropTypes.number,
};

export default MegaLink;
