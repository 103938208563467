import { titleCase } from 'title-case';
import { isPostcode } from './postcode-validation';

export const formatPostcode = postcode => postcode.toUpperCase();

export const formatNamedLocation = location => {
  return titleCase(location.toLowerCase());
};

export const formatLocation = (str = '') => {
  if (isPostcode(str)) {
    return formatPostcodeToDistrict(str);
  }

  return formatNamedLocation(str);
};

const formatPostcodeToDistrict = postcode => {
  const parts = postcode.match(/([A-Z]{1,2}[A-Z0-9]{1,2}) ?([0-9][A-Z]{2})/i);

  const district = parts && parts.length ? parts[1] : postcode;

  return district.toUpperCase();
};

export default formatLocation;
