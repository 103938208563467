import UAParser from 'ua-parser-js';
import { getCookie, setCookie } from 'lib/utils/cookie';
import { v4 } from 'lib/utils/uuid';
import type { Context, AppContext, Experiment } from './types';
import { setAsReady } from './queue';

const sessionDurationSeconds = 30 * 60;
const deviceCookieDurationSeconds = 365 * 24 * 60 * 60;
const sessionKey = 'cat-session-id';
const deviceKey = 'cat-device-id';
const ua = typeof window !== 'undefined' ? UAParser(navigator.userAgent) : null;

let appContext: AppContext;
let userId: string | null | undefined;
let deviceId: string | null;
let useCrossDomainCookie: boolean = true;

const getDeviceId = () => {
  const cookieId =
    getCookie(deviceKey) ||
    getAndRemoveDeviceIdFromLocalStorage(deviceKey) ||
    v4();

  setCookie(deviceKey, cookieId, {
    maxAgeSeconds: deviceCookieDurationSeconds,
    domain: useCrossDomainCookie ? 'checkatrade.com' : undefined,
  });

  return cookieId;
};

const getAndRemoveDeviceIdFromLocalStorage = (key: string): string | null => {
  let id;
  try {
    id = window.localStorage.getItem(key);
    if (id) {
      window.localStorage.removeItem(key);
    } else {
      return null;
    }

    return id;
  } catch (e) {
    return null;
  }
};

const getAndRefreshSessionId = () => {
  const sessionId = getCookie(sessionKey) || v4();
  setCookie(sessionKey, sessionId, {
    maxAgeSeconds: sessionDurationSeconds,
    domain: useCrossDomainCookie ? 'checkatrade.com' : undefined,
  });

  return sessionId;
};

export const setAppContext = (
  param: AppContext,
  waitForUserId: boolean = false,
  useCrossDomainCookieParam: boolean = true,
) => {
  appContext = param;
  useCrossDomainCookie = useCrossDomainCookieParam;
  deviceId = getDeviceId();
  if (!waitForUserId || userId) {
    setAsReady();
  }
};

export const addAppExperiment = (experiment: Experiment) => {
  const appExperiments = appContext?.experiments || [];

  if (appExperiments.find(exp => exp.id === experiment.id)) {
    appContext = {
      ...appContext,
      experiments: appExperiments.map(exp => {
        if (exp.id === experiment.id) {
          return experiment;
        }

        return exp;
      }),
    };
    return;
  }

  appContext = {
    ...appContext,
    experiments: [...appExperiments, experiment],
  };
};

export const setUserId = (param: string | null) => {
  userId = param;

  if (appContext) {
    setAsReady();
  }
};

export const getContext = (): Context | null =>
  ua
    ? {
        userId,
        deviceId,
        sessionId: getAndRefreshSessionId(),
        app: appContext,
        userAgent: navigator.userAgent,
        browser: ua.browser.name,
        browserVersion: ua.browser.version,
        os: ua.os.name,
        osVersion: ua.os.version,
        osLanguage: navigator.language,
      }
    : null;
