import { createGlobalStyle } from 'styled-components';
import { defaultFontSizeEm } from '@toolshed/styleguide/styles/sizes-rem';
import tablet from '@styles/breakpoint/tablet';
import colors from '@toolshed/styleguide/styles/colors';
import { fontFamilyMain } from '../lib/design-system';

export const BOX_SHADOW = `0px 3px 6px ${colors.boxShadow}`;
export const BORDER_RADIUS = `10px`;
export const NAV_HEIGHT = '64px';
export const SINGLE_FORM_MAX_WIDTH = '360px';

const GlobalStyle = css => createGlobalStyle`
  //This matches the size of Arial to our Webfont and reduces CLS
  @font-face {
    font-family: "Open Sans-fallback";
    size-adjust: 105.41000000000004%;
    ascent-override: 105%;
    src: local("Arial");
  }

  html {
    font-size: ${defaultFontSizeEm};
    height: 100%;
  }

  body, #__next {
    height: 100%;
    margin: 0;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  // Setting font family to our default due to browser overriding the font - https://stackoverflow.com/a/28524331
  #__next, body, button, input {
    font-family: ${fontFamilyMain};
  }

  body.modal {
    overflow: hidden;
  }

  body.modalMobile {
    overflow: hidden;

    ${tablet(`
      overflow: auto;
  `)}
  }

  button {
    background: none;
    border: 1px solid transparent;
  }

  ${css}

  h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
  }
`;

export default GlobalStyle;
