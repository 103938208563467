import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware, { END } from 'redux-saga';
import {
  setConfig as setAnalyticsConfig,
  createKinesis,
  GA,
  GTM,
} from 'lib/sdks/analytics';
import { catAnalytics } from 'lib/sdks/analytics/plugins/cat';
import createSyncLocalStorageState from './middlewares/sync-local-storage-state/sync-local-storage-state';
import analyticsMiddleware from './middlewares/analytics';

import localStorageConfig from './local-storage-config';
import reducersFactory from './reducers-factory';
import rootSaga from './sagas';
import {
  SERVER_TRACKING,
  UpdatedTrackingToggleStates,
} from './ab-tests.constants';

const getABTests = ({ isServer, res, req, query }) => {
  // only run server side, then pass down a/b tests in the redux store
  if (!isServer || !req) {
    return {};
  }

  const serverGetABTests = require('./server.get-ab-tests'); // eslint-disable-line
  const abTests = serverGetABTests(req, res);

  return {
    ...abTests,
    ...query.abTests, // allow overriding from query string
  };
};

/**
 * @param {object} initialState
 * @param {boolean} options.isServer indicates whether it is a server side or client side
 * @param {Request} options.req NodeJS Request object (not set when client applies initialState from server)
 * @param {Request} options.res NodeJS Request object (not set when client applies initialState from server)
 * @param {boolean} options.debug User-defined debug mode param.
 * @param {string} options.storeKey This key will be used to preserve store in global namespace for safe HMR
 */
const makeStore = (initialState, { isServer, req, res, query } = {}) => {
  const abTests = getABTests({ isServer, req, res, query });
  const env = initialState ? initialState.env : {};
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware];

  if (!isServer) {
    const syncLocalStorageState =
      createSyncLocalStorageState(localStorageConfig);
    middlewares.push(syncLocalStorageState);

    const isCatAnalyticsEnabled =
      initialState?.abTests?.[SERVER_TRACKING]?.variationId ===
      UpdatedTrackingToggleStates.ENABLED;

    const catAnalyticsPlugin = isCatAnalyticsEnabled ? catAnalytics : () => {};

    setAnalyticsConfig({
      appContext: {
        version: env.appVersion,
        env: env.awsTrackingEnv,
        platform: 'web',
        lang: 'en-GB',
        experiments: initialState.abTests
          ? Object.keys(initialState.abTests).map(name => ({
              name,
              id: initialState.abTests[name].experimentId,
              variant: initialState.abTests[name].variationId,
            }))
          : [],
        ipAddress: env.requestIp,
      },
      plugins: [
        createKinesis({
          awsIdentityPoolId: env.awsIdentityPoolId,
          awsPartitionKey: env.awsPartitionKey,
          awsRegion: env.awsRegion,
          awsStreamName: env.awsStreamName,
          awsFlowStreamName: env.awsFlowStreamName,
          awsTrackingEnv: env.awsTrackingEnv,
        }),
        catAnalyticsPlugin,
        GTM,
        GA,
      ],
      waitForUserId: true,
      useCrossDomainCookie: env.useCrossDomainAnalyticsCookies,
    });

    middlewares.push(analyticsMiddleware);

    /* istanbul ignore next */
    if (process.env.NODE_ENV === `development`) {
      // eslint-disable-next-line
      const reduxLogger = require('redux-logger');

      const logger = reduxLogger.createLogger({
        collapsed: true,
      });

      middlewares.push(logger);
    }
  }

  const store = createStore(
    reducersFactory({ isServer, req, query, abTests }),
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  );

  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry
  store.allTasks = [];
  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers-factory', () => {
      store.replaceReducer(
        reducersFactory({
          isServer,
          req,
          injectedReducers: store.injectedReducers,
        }),
      );
    });
  }

  const saveRunSaga = store.runSaga;
  store.runSaga = function interceptRunSaga(saga) {
    // eslint-disable-line no-param-reassign
    const task = saveRunSaga.call(store, saga);
    store.allTasks.push(task);
    return task;
  };

  store.close = () => {
    store.dispatch(END);
  };

  store.done = () => {
    if (!isServer) {
      return undefined;
    }

    store.close();

    return Promise.all(store.allTasks.map(t => t.toPromise()));
  };

  store.runSaga(rootSaga(isServer));

  return store;
};

export default makeStore;
