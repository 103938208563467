const shortlist = {
  data: [],
  modalShown: false,
};

const sendAJob = {
  step: 'form',
  loading: false,
  error: undefined,
};

const traderByName = {
  data: undefined,
  loading: false,
  error: undefined,
};

export interface AppState {
  localStorageAccess: boolean;
  localStorageLoaded?: boolean;
  modal: unknown;
  shortlist: {
    data: unknown[];
    modalShown: boolean;
  };
  categories: unknown;
  categoriesLoading: boolean;
  messageTrader: {
    companyId?: number | null;
    uniqueName?: string | null;
    companyCategories?: number | null;
  };
  user: unknown;
  mostRecentDescription: string;
  messageTraderHistory: unknown[];
  sendAJob: {
    step: string;
    loading: boolean;
    error: unknown;
  };
  traderByName: {
    data: unknown;
    loading: boolean;
    error: unknown;
  };
  recentSearches: {
    categoryId: number;
    location: string;
  }[];
  error: unknown;
  url: unknown;
}

const initialState: AppState = {
  localStorageAccess: true,
  modal: undefined,
  shortlist,
  categories: undefined,
  categoriesLoading: false,
  mostRecentDescription: '',
  messageTrader: {
    companyId: null,
    uniqueName: null,
  },
  user: undefined,
  messageTraderHistory: [],
  sendAJob,
  traderByName,
  recentSearches: [],
  error: undefined,
  url: undefined,
};

export default initialState;
