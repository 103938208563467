import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type { OptInToMarketingSource } from 'lib/sdks/consumer';
import {
  getPersistedEmailAddress,
  removePersistedEmailAddress,
  persistEmailAddress,
  optOutOfMarketing,
  authenticateWithToken,
} from 'lib/sdks/consumer';
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  confirmPasswordReset,
  checkActionCode,
  signOut,
  fetchSignInMethodsForEmail,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  signInWithCustomToken,
} from 'firebase/auth';
import { useFirebaseConfig } from '@fire/app';
import validateEmail from '@toolshed/core/validators/validate-email';
import { AuthContext } from '../context';
import {
  SignInErrors,
  SendPasswordResetEmailErrors,
  ResetPasswordErrors,
  DeleteUserErrors,
  SetPasswordErrors,
  ReauthenticateErrors,
  HasPasswordErrors,
  SendSignInLinkErrors,
  SignInWithEmailLinkErrors,
  SignInWithCustomTokenErrors,
} from '../errors';

const SignInErrorMap: Record<string, SignInErrors> = {
  'auth/invalid-email': SignInErrors.INVALID_EMAIL,
  'auth/user-not-found': SignInErrors.USER_NOT_FOUND,
  'auth/invalid-password': SignInErrors.INVALID_PASSWORD,
  'auth/wrong-password': SignInErrors.INVALID_PASSWORD,
};

const SendPasswordResetEmailErrorMap: Record<
  string,
  SendPasswordResetEmailErrors
> = {
  'auth/invalid-email': SendPasswordResetEmailErrors.INVALID_EMAIL,
  'auth/user-not-found': SendPasswordResetEmailErrors.USER_NOT_FOUND,
};

const ResetPasswordErrorMap: Record<string, ResetPasswordErrors> = {
  'auth/expired-action-code': ResetPasswordErrors.EXPIRED_CODE,
  'auth/invalid-action-code': ResetPasswordErrors.INVALID_CODE,
  'auth/user-not-found': ResetPasswordErrors.USER_NOT_FOUND,
  'auth/weak-password': ResetPasswordErrors.WEAK_PASSWORD,
};

const DeleteUserErrorMap: Record<string, DeleteUserErrors> = {
  'auth/requires-recent-login': DeleteUserErrors.REQUIRES_RECENT_LOGIN,
};

const SetPasswordErrorMap: Record<string, SetPasswordErrors> = {
  'auth/requires-recent-login': SetPasswordErrors.REQUIRES_RECENT_LOGIN,
  'auth/weak-password': SetPasswordErrors.WEAK_PASSWORD,
};

const ReauthenicateErrorMap: Record<string, ReauthenticateErrors> = {
  'auth/user-mismatch': ReauthenticateErrors.USER_MISMATCH,
  'auth/invalid-email': ReauthenticateErrors.INVALID_EMAIL,
  'auth/user-not-found': ReauthenticateErrors.USER_NOT_FOUND,
  'auth/invalid-credential': ReauthenticateErrors.INVALID_CREDENTIAL,
  'auth/wrong-password': ReauthenticateErrors.WRONG_PASSWORD,
};

const HasPasswordErrorMap: Record<string, HasPasswordErrors> = {
  'auth/invalid-email': HasPasswordErrors.INVALID_EMAIL,
};

const sendSignInLinkErrorMap: Record<string, SendSignInLinkErrors> = {
  'auth/argument-error': SendSignInLinkErrors.ARGUMENT_ERROR,
  'auth/missing-continue-uri': SendSignInLinkErrors.MISSING_CONTINUE_URI,
  'auth/invalid-continue-uri': SendSignInLinkErrors.INVALID_CONTINUE_URI,
  'auth/unauthorized-continue-uri':
    SendSignInLinkErrors.UNAUTHORIZED_CONTINUE_URI,
  'auth/invalid-email': SendSignInLinkErrors.INVALID_EMAIL,
};

const signInWithEmailLinkErrorMap: Record<string, SignInWithEmailLinkErrors> = {
  'auth/expired-action-code': SignInWithEmailLinkErrors.EXPIRED_CODE,
  'auth/invalid-email': SignInWithEmailLinkErrors.INVALID_EMAIL,
  'auth/user-disabled': SignInWithEmailLinkErrors.USER_DISABLED,
};

const signInWithCustomTokenErrorMap: Record<
  string,
  SignInWithCustomTokenErrors
> = {
  'auth/invalid-custom-token': SignInWithCustomTokenErrors.INVALID_CUSTOM_TOKEN,
};

interface AuthError {
  code: string;
}

const getSignError = (error: AuthError) =>
  (error && SignInErrorMap[error.code]) || SignInErrors.UNKNOWN;

const getSendPasswordResetEmailError = (error: AuthError) =>
  (error && SendPasswordResetEmailErrorMap[error.code]) ||
  SendPasswordResetEmailErrors.UNKNOWN;

const getResetPasswordError = (error: AuthError) =>
  (error && ResetPasswordErrorMap[error.code]) || ResetPasswordErrors.UNKNOWN;

const getDeleteUserError = (error: AuthError) =>
  (error && DeleteUserErrorMap[error.code]) || DeleteUserErrors.UNKNOWN;

const getSetPasswordError = (error: AuthError) =>
  (error && SetPasswordErrorMap[error.code]) || SetPasswordErrors.UNKNOWN;

const getReauthenicateError = (error: AuthError) =>
  (error && ReauthenicateErrorMap[error.code]) || ReauthenticateErrors.UNKNOWN;

const getHasPasswordError = (error: AuthError) =>
  (error && HasPasswordErrorMap[error.code]) || HasPasswordErrors.UNKNOWN;

const getSendSignInLinkError = (error: AuthError) =>
  (error && sendSignInLinkErrorMap[error.code]) || SendSignInLinkErrors.UNKNOWN;

const getSignInWithEmailLinkError = (error: AuthError) =>
  (error && signInWithEmailLinkErrorMap[error.code]) ||
  SignInWithEmailLinkErrors.UNKNOWN;

const getSignInWithCustomTokenError = (error: AuthError) =>
  (error && signInWithCustomTokenErrorMap[error.code]) ||
  SignInWithCustomTokenErrors.UNKNOWN;

export const useAuthContext = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('useAuthContext must be used within Auth context provider');
  }

  return authContext;
};

export const useAuth = () => {
  const { auth } = useAuthContext();
  return auth;
};

export const useLoading = () => {
  const { loading } = useAuthContext();
  return loading;
};

export const useCurrentUser = () => {
  const { currentUser } = useAuthContext();
  return currentUser;
};

export const useDeleteUser = ({
  optOut,
  source,
}: {
  optOut?: boolean;
  source?: OptInToMarketingSource;
}) => {
  const {
    auth,
    callbacks: { onDeleteUser },
  } = useAuthContext();
  const [error, setError] = useState<string | null>(null);

  const deleteUser = useCallback(async () => {
    setError(null);
    try {
      if (auth.currentUser) {
        const idToken = await auth.currentUser.getIdToken();
        if (optOut) {
          await optOutOfMarketing({ idToken, source: source || 'Portal' });
        }

        await auth.currentUser.delete();
      }

      onDeleteUser();
      return true;
    } catch (e) {
      const deleteUserError = getDeleteUserError(e as AuthError);
      setError(deleteUserError);
      return deleteUserError;
    }
  }, [auth.currentUser, onDeleteUser, optOut, source]);

  return { deleteUser, error };
};

export const useSignIn = () => {
  const {
    auth,
    callbacks: { onSignIn, onSignInError },
  } = useAuthContext();
  const [error, setError] = useState<string | null>(null);
  const signIn = useCallback(
    async ({ email, password }: any) => {
      setError(null);
      try {
        const { user: currentUser } = await signInWithEmailAndPassword(
          auth,
          email,
          password,
        );
        onSignIn();
        return currentUser;
      } catch (e: any) {
        const signInError = getSignError(e);
        setError(signInError);
        onSignInError(signInError);
        return signInError;
      }
    },
    [auth, onSignIn, onSignInError],
  );

  return {
    signIn,
    error,
  };
};

export const useSignOut = () => {
  const {
    auth,
    callbacks: { onSignOut },
  } = useAuthContext();

  return useCallback(async () => {
    onSignOut();
    await signOut(auth);
  }, [auth, onSignOut]);
};

export const useSendPasswordResetEmail = () => {
  const {
    auth,
    callbacks: { onPasswordReset, onPasswordResetError },
  } = useAuthContext();
  const [error, setError] = useState<string | null>(null);
  const callback = useCallback(
    async ({ email }: any) => {
      try {
        setError(null);
        await sendPasswordResetEmail(auth, email);
        onPasswordReset();
        return Promise.resolve();
      } catch (e: any) {
        const sendPasswordError = getSendPasswordResetEmailError(e);
        setError(sendPasswordError);
        onPasswordResetError(sendPasswordError);
        return Promise.reject();
      }
    },
    [auth, onPasswordReset, onPasswordResetError],
  );

  return {
    sendPasswordResetEmail: callback,
    error,
  };
};

export const useResetPassword = () => {
  const {
    auth,
    callbacks: { onPasswordResetError, onPasswordResetSubmitted },
  } = useAuthContext();
  const [error, setError] = useState<string | null>(null);
  const resetPassword = useCallback(
    async ({ code, newPassword }: any) => {
      try {
        setError(null);
        await confirmPasswordReset(auth, code, newPassword);
        onPasswordResetSubmitted();
        return Promise.resolve();
      } catch (e: any) {
        const resetPasswordError = getResetPasswordError(e);
        setError(resetPasswordError);
        onPasswordResetError(resetPasswordError);
        return Promise.reject();
      }
    },
    [auth, onPasswordResetError, onPasswordResetSubmitted],
  );

  return {
    resetPassword,
    error,
  };
};

export const useSetPassword = (): [
  string | null,
  (newPassword: string) => Promise<void>,
] => {
  const {
    auth,
    callbacks: { onSetPassword, onSetPasswordError },
  } = useAuthContext();
  const [error, setError] = useState<string | null>(null);

  const setPassword = useCallback(
    async (newPassword: string) => {
      try {
        setError(null);
        if (auth.currentUser) {
          await updatePassword(auth.currentUser, newPassword);
          onSetPassword();
        }

        return Promise.resolve();
      } catch (e: any) {
        const setPasswordError = getSetPasswordError(e);
        setError(setPasswordError);
        onSetPasswordError(setPasswordError);
        return Promise.reject();
      }
    },
    [auth.currentUser, onSetPassword, onSetPasswordError],
  );

  return [error, setPassword];
};

export const useReauthenticate = (): [
  string | null,
  (password: string) => Promise<void>,
] => {
  const {
    auth,
    callbacks: { onReauthenticate, onReauthenticateError },
  } = useAuthContext();
  const [error, setError] = useState<string | null>(null);

  const reauthenticate = useCallback(
    async (password: any) => {
      try {
        setError(null);
        if (auth.currentUser && auth.currentUser.email) {
          const credential = EmailAuthProvider.credential(
            auth.currentUser.email,
            password,
          );
          await reauthenticateWithCredential(auth.currentUser, credential);
          onReauthenticate();
        }

        return Promise.resolve();
      } catch (e: any) {
        const reauthenticationError = getReauthenicateError(e);
        setError(reauthenticationError);
        onReauthenticateError(reauthenticationError);
        return Promise.reject();
      }
    },
    [auth.currentUser, onReauthenticate, onReauthenticateError],
  );

  return [error, reauthenticate];
};

export const useHasPassword = (): [
  (email: string) => Promise<boolean | HasPasswordErrors>,
  string | null,
  boolean,
  boolean,
  () => void,
] => {
  const { auth } = useAuthContext();
  const [hasPassword, setHasPassword] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const reset = useCallback(() => {
    setHasPassword(false);
    setError(null);
    setLoading(false);
  }, []);

  const hasPasswordCheck = useCallback(
    async (email: string) => {
      try {
        setLoading(true);
        const signInMethods = await fetchSignInMethodsForEmail(auth, email);
        const result = signInMethods.includes('password');
        setHasPassword(result);
        setLoading(false);
        return result;
      } catch (e) {
        setLoading(false);
        const hasPasswordError = getHasPasswordError(e as AuthError);
        setError(hasPasswordError);
        return hasPasswordError;
      }
    },
    [auth],
  );

  return [hasPasswordCheck, error, hasPassword, loading, reset];
};

export const useSendSignInLink = (): [
  string | null,
  (email: string) => Promise<void>,
  boolean,
  boolean,
] => {
  const {
    auth,
    callbacks: { onSendSignInLink, onSendSignInLinkError },
  } = useAuthContext();
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const sendSignInLink = useCallback(
    async (email: string) => {
      if (sending) {
        return;
      }

      setSending(true);
      try {
        persistEmailAddress(email);
        await sendSignInLinkToEmail(auth, email, {
          url: `${window.location.origin}`,
          handleCodeInApp: true,
        });
        onSendSignInLink();
        setSending(false);
        setSent(true);
      } catch (e) {
        const sendSignInLinkError = getSendSignInLinkError(e as AuthError);
        setError(sendSignInLinkError);
        onSendSignInLinkError(sendSignInLinkError);
        setSending(false);
      }
    },
    [auth, onSendSignInLink, onSendSignInLinkError, sending],
  );

  return [error, sendSignInLink, sending, sent];
};

export const useAuthUserHasPassword = () => {
  const user = useCurrentUser();
  return user?.providerData.some(p => p.providerId === 'password');
};

export const useIsEmailRegistered = (): [
  (email: string) => Promise<void>,
  boolean | null,
  boolean | null,
  boolean,
] => {
  const firebaseConfig = useFirebaseConfig();
  const [isRegistered, setIsRegistered] = useState<boolean | null>(null);
  const [error, setError] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(false);
  const checkRegistrationStatus = useCallback(
    async (email: any) => {
      if (validateEmail(email)) {
        setError(true);
        setLoading(false);
        setIsRegistered(false);
      } else {
        setError(false);
        setLoading(true);
        try {
          const baseUrl = firebaseConfig.authEmulatorHost
            ? `http://${firebaseConfig.authEmulatorHost}/identitytoolkit.googleapis.com`
            : 'https://identitytoolkit.googleapis.com';

          const registrationResult = await fetch(
            `${baseUrl}/v1/accounts:createAuthUri?key=${firebaseConfig.apiKey}`,
            {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                continueUri: `${window.location.origin}`,
                identifier: email,
              }),
            },
          );
          const parsedRegistrationResult = await registrationResult.json();
          setIsRegistered(parsedRegistrationResult.registered);
          setLoading(false);
        } catch {
          setError(true);
          setLoading(false);
          setIsRegistered(false);
        }
      }
    },
    [firebaseConfig],
  );

  return [checkRegistrationStatus, error, isRegistered, loading];
};

export const useSignInWithLink = (
  actionCode: string,
): [
  error: SignInWithEmailLinkErrors | null,
  storedEmail: string | null,
  signInWithLink: (email: string) => Promise<void>,
  signingIn: boolean,
  verifying: boolean,
] => {
  const auth = useAuth();
  const [error, setError] = useState<SignInWithEmailLinkErrors | null>(null);
  const storedEmail = useMemo(() => {
    const email = getPersistedEmailAddress();
    removePersistedEmailAddress();
    return email;
  }, []);
  const [signingIn, setSigningIn] = useState(false);
  const [verifying, setVerifying] = useState(true);

  useEffect(() => {
    setVerifying(true);

    (async () => {
      try {
        await checkActionCode(auth, actionCode);
      } catch (e: any) {
        setError(getSignInWithEmailLinkError(e));
      }

      setVerifying(false);
    })();
  }, [actionCode, auth]);

  const signInWithLink = useCallback(
    async (email: string) => {
      setSigningIn(true);
      try {
        await signInWithEmailLink(auth, email, window.location.href);
      } catch (e: any) {
        setError(getSignInWithEmailLinkError(e));
      }

      setSigningIn(false);
    },
    [auth],
  );

  return [error, storedEmail, signInWithLink, signingIn, verifying];
};

export const useSignInWithCustomToken = (): [
  error: SignInWithCustomTokenErrors | null,
  signInWithToken: (jobId: string, token: string) => Promise<void>,
  signingIn: boolean,
] => {
  const auth = useAuth();
  const [error, setError] = useState<SignInWithCustomTokenErrors | null>(null);
  const [signingIn, setSigningIn] = useState(false);

  const signInWithToken = useCallback(
    async (jobId: string, token: string) => {
      setSigningIn(true);
      try {
        const customToken = await authenticateWithToken({ jobId, token });

        await signInWithCustomToken(auth, customToken);
      } catch (e: unknown) {
        setError(getSignInWithCustomTokenError(e as AuthError));
      }

      setSigningIn(false);
    },
    [auth],
  );

  return [error, signInWithToken, signingIn];
};
