import React, { createContext, useEffect, useMemo, useState } from 'react';
import type { Auth, User } from 'firebase/auth';
import {
  initializeAuth,
  connectAuthEmulator,
  indexedDBLocalPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
} from 'firebase/auth';
import type { CustomEventData, EventName } from 'lib/sdks/analytics';
import { setUserId } from 'lib/sdks/analytics';
import { removePersistedEmailAddress } from 'lib/sdks/consumer';
import { useFirebaseApp, useFirebaseConfig } from '../app';
import { RegisterErrors } from './errors';

export type RegistrationSource =
  | 'create_account'
  | 'auto_reg_review'
  | 'auto_reg_raq'
  | 'auto_reg_message';

interface AuthContextCallbacks {
  onAccountUpdated: () => void;
  onDeleteUser: () => void;
  onEmailVerified: () => void;
  onEmailVerifyError: (e: string) => void;
  onPasswordReset: () => void;
  onPasswordResetError: (e: string) => void;
  onPasswordResetSubmitted: () => void;
  onRegister: (source: RegistrationSource) => void;
  onRegisterError: (e: string, source: RegistrationSource) => void;
  onResendEmailVerification: () => void;
  onResendEmailVerificationError: (e: string) => void;
  onSignIn: () => void;
  onSignInError: (e: string) => void;
  onSignOut: () => void;
  onSendSignInLink: () => void;
  onSendSignInLinkError: (e: string) => void;
  onReauthenticate: () => void;
  onReauthenticateError: (e: string) => void;
  onSetPassword: () => void;
  onSetPasswordError: (e: string) => void;
}

export type AuthenticationLevel =
  | 'unauthenticated'
  | 'soft-authenticated'
  | 'authenticated';

export interface IAuthContext {
  auth: Auth;
  currentUser: User | null;
  loading: boolean;
  authenticationLevel: AuthenticationLevel;
  callbacks: AuthContextCallbacks;
}

export const AuthContext = createContext<IAuthContext | null>(null);

interface AuthProviderProps extends Partial<AuthContextCallbacks> {
  children: React.ReactNode;
  trackEvent: (event: { key: EventName; data?: CustomEventData }) => void;
}

export const AuthProvider = ({ children, trackEvent }: AuthProviderProps) => {
  const app = useFirebaseApp();
  const config = useFirebaseConfig();

  const auth = useMemo(() => {
    const authImpl = initializeAuth(app, {
      persistence: [
        ...(config.authEmulatorHost ? [] : [indexedDBLocalPersistence]),
        browserLocalPersistence,
        browserSessionPersistence,
      ],
    });

    if (config.authEmulatorHost) {
      connectAuthEmulator(authImpl, `http://${config.authEmulatorHost}`, {
        disableWarnings: true,
      });
    }

    return authImpl;
  }, [app, config]);

  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  useEffect(() => {
    // The user's email address is saved in local storage when they wish to sign in via email link.
    // This is to make the sign in process quicker when on the same device.
    // The email address is only read when performing the sign in action (on url /account/action).
    // On any other page load, instruct to remove any existing email address.
    // This is to ensure that we don't hold on to personal info
    if (window.location.pathname !== '/account/action') {
      removePersistedEmailAddress();
    }

    auth.onAuthStateChanged(user => {
      setUserId(user ? user.uid : null);
      setCurrentUser(user);
      setLoading(false);
    });
  }, [auth]);

  const onEmailVerified = () => {
    trackEvent({ key: 'consumer_area.account_verification' });
  };

  const onEmailVerifyError = (error: string) => {
    trackEvent({
      key: 'consumer_area.account_verification_failed',
      data: { error },
    });
  };

  const onPasswordReset = () => {
    trackEvent({ key: 'consumer_area.password_reset_requested' });
  };

  const onPasswordResetError = (error: string) => {
    trackEvent({
      key: 'consumer_area.password_reset_request_failed',
      data: { error },
    });
  };

  const onPasswordResetSubmitted = () => {
    trackEvent({ key: 'consumer_area.password_reset_submitted' });
  };

  const onAccountUpdated = () => {
    trackEvent({ key: 'consumer_area.account_updated' });
  };

  const onResendEmailVerification = () => {
    trackEvent({ key: 'consumer_area.resend_verification' });
  };

  const onResendEmailVerificationError = (error: string) => {
    trackEvent({
      key: 'consumer_area.resend_verification_email_failed',
      data: { error },
    });
  };

  const onSignOut = () => {
    trackEvent({
      key: 'consumer_area.logout',
    });
  };

  const onRegister = (source: RegistrationSource) => {
    trackEvent({ key: 'consumer_area.register', data: { source } });
  };

  const onRegisterError = (error: string, source: RegistrationSource) => {
    if (error === RegisterErrors.EMAIL_ALREADY_IN_USE) {
      trackEvent({
        key: 'consumer_area.register_existing_account',
        data: { source },
      });
    } else {
      trackEvent({ key: 'consumer_area.register_failed', data: { source } });
    }
  };

  const onSignIn = () => {
    trackEvent({ key: 'consumer_area.login' });
  };

  const onSignInError = (error: string) => {
    trackEvent({ key: 'consumer_area.login_failed', data: { error } });
  };

  const onDeleteUser = () => {
    trackEvent({ key: 'consumer_area.delete_account' });
  };

  const onReauthenticate = () => {
    trackEvent({ key: 'consumer_area.reauthenticate' });
  };

  const onReauthenticateError = (error: string) => {
    trackEvent({ key: 'consumer_area.reauthenticate_failed', data: { error } });
  };

  const onSetPassword = () => {
    trackEvent({ key: 'consumer_area.set_password' });
  };

  const onSetPasswordError = (error: string) => {
    trackEvent({ key: 'consumer_area.set_password_failed', data: { error } });
  };

  const onSendSignInLink = () => {
    trackEvent({ key: 'consumer_area.magic_link_requested' });
  };

  const onSendSignInLinkError = (error: string) => {
    trackEvent({
      key: 'consumer_area.magic_link_requested_failed',
      data: { error },
    });
  };

  const authenticationLevel = !currentUser
    ? 'unauthenticated'
    : currentUser.email
      ? 'authenticated'
      : 'soft-authenticated';

  return (
    <AuthContext.Provider
      value={{
        auth,
        loading,
        currentUser,
        authenticationLevel,
        callbacks: {
          onAccountUpdated,
          onDeleteUser,
          onEmailVerified,
          onEmailVerifyError,
          onPasswordReset,
          onPasswordResetError,
          onPasswordResetSubmitted,
          onRegister,
          onRegisterError,
          onResendEmailVerification,
          onResendEmailVerificationError,
          onSignIn,
          onSignInError,
          onSignOut,
          onSendSignInLink,
          onSendSignInLinkError,
          onReauthenticate,
          onReauthenticateError,
          onSetPassword,
          onSetPasswordError,
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
