import styled from 'styled-components';
import colors from '@toolshed/styleguide/styles/colors';

export const keyboardOnlyOutline = () => `
  outline: ${colors.blue} auto 1px;
  outline: -webkit-focus-ring-color auto 1px;
`;

export const ButtonBaseInner = styled.span.attrs({
  tabIndex: -1,
})`
  box-sizing: border-box;
  height: 100%;
  outline: none;
  position: relative;
  width: 100%;
`;

export const ButtonBase = styled.button.attrs(({ tabIndex }) => ({
  tabIndex: tabIndex || 0,
}))`
  &:focus > ${ButtonBaseInner} {
    ${keyboardOnlyOutline()};
  }

  background: none;
  border: none;
  box-sizing: border-box;
  outline: none;
  padding: 0;
`;

export const LinkBaseInner = styled(ButtonBaseInner)`
  display: initial;
  outline: none;
`;

export const LinkBase = styled(ButtonBase.withComponent('a'))`
  display: inline-block;

  &:focus > ${LinkBaseInner} {
    ${keyboardOnlyOutline()};
  }

  &:hover,
  &:link,
  &:visited {
    text-decoration: none;
  }
`;
