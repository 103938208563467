export enum SignInErrors {
  INVALID_EMAIL = 'invalid-email',
  USER_NOT_FOUND = 'user-not-found',
  INVALID_PASSWORD = 'invalid-password',
  UNKNOWN = 'unknown',
}

export enum RegisterErrors {
  INVALID_EMAIL = 'invalid-email',
  EMAIL_ALREADY_IN_USE = 'email-already-in-use',
  WEAK_PASSWORD = 'weak-password',
  UNKNOWN = 'unknown',
}

export enum SendPasswordResetEmailErrors {
  INVALID_EMAIL = 'invalid-email',
  USER_NOT_FOUND = 'user-not-found',
  UNKNOWN = 'unknown',
}

export enum ResetPasswordErrors {
  INVALID_CODE = 'invalid-code',
  EXPIRED_CODE = 'expired-code',
  WEAK_PASSWORD = 'weak-password',
  USER_NOT_FOUND = 'user-not-found',
  UNKNOWN = 'unknown',
}

export enum VerifyEmailErrors {
  INVALID_CODE = 'invalid-action-code',
  EXPIRED_CODE = 'expired-action-code',
  UNKNOWN = 'unknown',
}

export enum DeleteUserErrors {
  REQUIRES_RECENT_LOGIN = 'requires-recent-login',
  UNKNOWN = 'unknown',
}

export enum SetPasswordErrors {
  WEAK_PASSWORD = 'weak-password',
  REQUIRES_RECENT_LOGIN = 'requires-recent-login',
  UNKNOWN = 'unknown',
}

export enum ReauthenticateErrors {
  USER_MISMATCH = 'user-mismatch',
  INVALID_EMAIL = 'invalid-email',
  USER_NOT_FOUND = 'user-not-found',
  INVALID_CREDENTIAL = 'invalid-credential',
  WRONG_PASSWORD = 'wrong-password',
  UNKNOWN = 'unknown',
}

export enum HasPasswordErrors {
  INVALID_EMAIL = 'invalid-email',
  UNKNOWN = 'unknown',
}

export enum SendSignInLinkErrors {
  ARGUMENT_ERROR = 'argument-error',
  MISSING_CONTINUE_URI = 'missing-continue-uri',
  INVALID_CONTINUE_URI = 'invalid-continue-uri',
  UNAUTHORIZED_CONTINUE_URI = 'unauthorized-continue-uri',
  INVALID_EMAIL = 'invalid-email',
  UNKNOWN = 'unknown',
}

export enum SignInWithEmailLinkErrors {
  INVALID_EMAIL = 'invalid-email',
  EXPIRED_CODE = 'expired-code',
  USER_DISABLED = 'user-disabled',
  UNKNOWN = 'unknown',
}

export enum SignInWithCustomTokenErrors {
  INVALID_CUSTOM_TOKEN = 'invalid-custom-token',
  UNKNOWN = 'unknown',
}
