import { useCallback, useState } from 'react';
import type { OptInToMarketingSource } from 'lib/sdks/consumer';
import { optInToMarketing } from 'lib/sdks/consumer';
import {
  createUserWithEmailAndPassword,
  updateProfile,
  // sendEmailVerification,
  signInAnonymously,
  updateEmail,
} from 'firebase/auth';
import { RegisterErrors } from '../errors';
import { useUpsertUserDocument } from '../../firestore';
import { useAuthContext } from '.';
import type { RegistrationSource } from '../context';

const RegisterErrorMap: Record<string, RegisterErrors> = {
  'auth/invalid-email': RegisterErrors.INVALID_EMAIL,
  'auth/email-already-in-use': RegisterErrors.EMAIL_ALREADY_IN_USE,
  'auth/weak-password': RegisterErrors.WEAK_PASSWORD,
};

export type RegisterOptions = {
  autoOptIn?: boolean;
  source?: RegistrationSource;
};

interface AuthError {
  code: string;
}

const getRegisterError = (error: AuthError) =>
  (error && RegisterErrorMap[error.code]) || RegisterErrors.UNKNOWN;

const getOptInToMarketingSourceFromRegistrationSource = (
  registrationSource: RegistrationSource,
): OptInToMarketingSource => {
  if (registrationSource === 'auto_reg_raq') {
    return 'RAQ_Implicit';
  }

  if (registrationSource === 'auto_reg_message') {
    return 'Message_Implicit';
  }

  if (registrationSource === 'auto_reg_review') {
    return 'TradeReview_Implicit';
  }

  return 'Registration';
};

export const useRegister = (source?: RegistrationSource) => {
  const {
    auth,
    callbacks: { onRegister, onRegisterError },
  } = useAuthContext();
  const [error, setError] = useState<string | null>(null);
  const createUserDocument = useUpsertUserDocument();

  const register = useCallback(
    async (
      {
        email,
        password,
        firstName,
        lastName,
        phoneNumber,
        postcode,
      }: {
        email: string;
        firstName: string;
        lastName: string;
        postcode: string;
        phoneNumber: string;
        password?: string;
      },
      options: RegisterOptions = {},
    ) => {
      const { autoOptIn = true, source: registerSource = source } = options;

      if (!registerSource) {
        throw new Error('Source is required');
      }

      try {
        let newUser;

        if (password === undefined) {
          const response = await signInAnonymously(auth);
          newUser = response.user;

          if (newUser) {
            try {
              await updateEmail(newUser, email);
            } catch (e) {
              try {
                await newUser.delete();
              } catch (er) {
                // ignore
              }

              throw e;
            }
          }
        } else {
          const { user } = await createUserWithEmailAndPassword(
            auth,
            email,
            password,
          );

          newUser = user;
        }

        onRegister(registerSource);

        if (newUser) {
          await updateProfile(newUser, {
            displayName: firstName,
          });

          const { uid } = newUser;
          await createUserDocument({
            email,
            emailVerified: false,
            userId: uid,
            firstName,
            lastName,
            postcode,
            phoneNumber,
            isCreate: true,
          });

          // Not fully removing for now as we may need to re-enable this
          // Relying on account area to verify email for now
          // if (source !== 'auto_reg_review') {
          //   // we have found there is a negative impact on SMS verification rate
          //   // when sending email verification after users leave a review
          //   // for the timebeing we will not send it in this scenario
          //   // and see what we can re-introduce with good measurement in place
          //   await sendEmailVerification(newUser);
          // }
        }

        /* Don't opt-in the user on vanilla registration until there will be a proper UX letting then to handle it */
        if (source !== 'create_account' && autoOptIn) {
          await optInToMarketing({
            email,
            firstName,
            lastName,
            source:
              getOptInToMarketingSourceFromRegistrationSource(registerSource),
          });
        }

        return newUser;
      } catch (e: any) {
        const registerError = getRegisterError(e);
        setError(registerError);
        onRegisterError(registerError, registerSource);
        return registerError;
      }
    },
    [auth, createUserDocument, onRegister, onRegisterError, source],
  );

  return {
    register,
    error,
  };
};
