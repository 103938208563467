import styled from 'styled-components';

import {
  iconSizeXS,
  iconSizeXXS,
  iconSizeSmall,
  iconSizeSMedium,
  iconSizeMedium,
  iconSizeLMedium,
  iconSizeLarge,
  iconSizeXLarge,
  iconSizeXXLarge,
  iconSizeXXXLarge,
  iconSizeExtreme,
} from './icon-sizes';

export type IconSize =
  | 'xsmall'
  | 'xxsmall'
  | 'small'
  | 'smedium'
  | 'medium'
  | 'lmedium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'
  | 'extreme';

export const IconSizeMap: Record<IconSize, string> = {
  xsmall: iconSizeXS,
  xxsmall: iconSizeXXS,
  small: iconSizeSmall,
  smedium: iconSizeSMedium,
  medium: iconSizeMedium,
  lmedium: iconSizeLMedium,
  large: iconSizeLarge,
  xlarge: iconSizeXLarge,
  xxlarge: iconSizeXXLarge,
  xxxlarge: iconSizeXXXLarge,
  extreme: iconSizeExtreme,
};

export interface IconProps {
  size?: IconSize;
  color?: string;
  style?: object;
  className?: string;
  hoverColor?: string;
}

const widthAndHeight = (size: string) => `
  width: ${size};
  height: ${size};
`;

interface IconSpanProps {
  defaultColor: string;
  applyColorToFill?: boolean;
  applyColorToStroke?: boolean;
  size?: IconSize;
  color?: string;
  hoverColor?: string;
}

export const Icon = styled.span<IconSpanProps>`
  ${props => `
    display: flex;
    flex: none;
    ${widthAndHeight(IconSizeMap[props.size || 'medium'])};
    svg {
      width: 100%;
      height: 100%;
      fill: ${
        props.applyColorToFill ? props.color || props.defaultColor : 'none'
      };
      stroke: ${
        props.applyColorToStroke ? props.color || props.defaultColor : 'none'
      };
    }

     &:hover {
        svg {
          fill: ${
            props.applyColorToFill
              ? props.hoverColor || props.color
              : props.defaultColor
          };
          stroke: ${
            props.applyColorToStroke
              ? props.hoverColor || props.color
              : 'transparent'
          };
        }
      }
  `}
`;

export const AppIconWrapper = styled.div`
  ${Icon} {
    width: 100%;
    height: 100%;
  }
`;
