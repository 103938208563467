/* istanbul ignore file */

const inject = /* istanbul ignore next */ gtmId => {
  // eslint-disable-next-line func-names
  (function (w, d, s, l, i) {
    // eslint-disable-next-line no-param-reassign
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);
    // eslint-disable-next-line eqeqeq
    const dl = l !== 'dataLayer' ? `&l=${l}` : '';
    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', gtmId);

  const gtmPixel = document.createElement('noscript');
  gtmPixel.id = 'gtmIframe';
  // eslint-disable-next-line no-template-curly-in-string
  gtmPixel.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
  document.querySelector('body').appendChild(gtmPixel);
};

const injectGA = /* istanbul ignore next */ gtmId => {
  if (window.Optanon && window.Optanon.IsAlertBoxClosedAndValid()) {
    inject(gtmId);
  } else {
    const optanonWrapperCallback = window.OptanonWrapper;
    // eslint-disable-next-line func-names
    window.OptanonWrapper = function () {
      inject(gtmId);
      optanonWrapperCallback();
    };
  }
};

/* istanbul ignore next */
export default injectGA;
