import { useEffect, useState } from 'react';
import { getNotificationBanner } from 'lib/sdks/strapi';
import type { APINotificationBanner } from '../../lib/sdks/strapi/types';

export type BannerProps = APINotificationBanner;

type BannerState = {
  showBanner: boolean;
  bannerData?: BannerProps;
};

type useNotificationBannerReturn = [true, BannerProps] | [false, undefined];

const useNoticeBanner = () => {
  const [bannerState, setBannerData] = useState<BannerState>({
    showBanner: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const strapiData = await getNotificationBanner();
        const localStorageValue = window.localStorage.getItem(
          'preview-notice-banner',
        );
        const showBanner =
          (strapiData.enabled || localStorageValue === 'true') && !!strapiData;

        setBannerData({
          bannerData: strapiData,
          showBanner: showBanner,
        });
      } catch (error) {
        // Client side logging to be added here
      }
    };

    fetchData();
  }, []);

  return [
    bannerState.showBanner,
    bannerState.bannerData,
  ] as useNotificationBannerReturn;
};

export default useNoticeBanner;
