import { getConsumerProfile } from 'lib/consumer/consumer-profile-apis';
import { ConsumerValidProfile } from 'lib/consumer/consumer.schema';
import { logger } from 'lib/utils/logger';

export const checkUserProfile = async ({
  accessToken,
  userId,
  email,
}: {
  accessToken: string;
  userId: string;
  email: string;
}) => {
  try {
    const consumerProfile = await getConsumerProfile({
      token: accessToken as string,
    });
    const isNewUser = consumerProfile === null;

    logger.info({ userId, isNewUser }, 'Session - Profile check success');

    return {
      id: userId,
      email: email,
      isNewUser,
      validProfile: ConsumerValidProfile.safeParse(consumerProfile).success,
    };
  } catch (err) {
    logger.warn({ userId, err }, 'Session - Profile check failed');
  }

  return {
    id: userId,
    email: email,
    isNewUser: true,
    validProfile: false,
  };
};
