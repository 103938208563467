import { Link } from '@cat-home-experts/cat-web-ui';
import NextLink from 'next/link';
import colors from '@toolshed/styleguide/styles/colors';
import {
  IconAccountActive,
  IconAccountInactive,
} from '@toolshed/styleguide/components/AppIcon/AppIcons';
import { useIsAuthenticated } from 'lib/auth/auth.hooks';

export const LoginButton = ({
  handleMegaClickOutside,
}: {
  handleMegaClickOutside: () => void;
}) => {
  const isAuthenicated = useIsAuthenticated();

  const AccountIcon = isAuthenicated ? IconAccountInactive : IconAccountActive;

  return (
    <Link
      href="/account"
      data-guid="TopNavLogin"
      as={NextLink}
      aria-label={isAuthenicated ? 'Account' : 'Login'}
      onClick={handleMegaClickOutside}
      className="mr-4"
    >
      <AccountIcon color={colors.blue} size="xlarge" />
    </Link>
  );
};
