import type {
  ConsumerPartialRequest,
  ConsumerRequest,
} from './consumer.schema';
import { ConsumerResponseSchema } from './consumer.schema';
import { createAuthAPI } from '../utils/fetch/create-api-factory';

export const getConsumerProfile = createAuthAPI({
  url: `${process.env.NEXT_PUBLIC_CONSUMER_SERVICE_URL}/profile`,
  method: 'GET',
  schema: ConsumerResponseSchema,
});

export const patchConsumerProfile = createAuthAPI<
  ConsumerPartialRequest,
  typeof ConsumerResponseSchema
>({
  url: `${process.env.NEXT_PUBLIC_CONSUMER_SERVICE_URL}/profile`,
  method: 'PATCH',
  schema: ConsumerResponseSchema,
});

export const putConsumerProfile = createAuthAPI<
  ConsumerRequest,
  typeof ConsumerResponseSchema
>({
  url: `${process.env.NEXT_PUBLIC_CONSUMER_SERVICE_URL}/profile`,
  method: 'PUT',
  schema: ConsumerResponseSchema,
});

export const postConsumerProfile = createAuthAPI({
  url: `${process.env.NEXT_PUBLIC_CONSUMER_SERVICE_URL}/profile`,
  method: 'POST',
  schema: ConsumerResponseSchema,
});

export const deleteConsumerProfile = createAuthAPI({
  url: `${process.env.NEXT_PUBLIC_CONSUMER_SERVICE_URL}/profile`,
  method: 'DELETE',
  schema: ConsumerResponseSchema,
});
