export const getAppVersion = env => {
  if (!env) {
    return undefined;
  }

  const {
    CF_REPO_NAME,
    CF_BRANCH,
    CF_REVISION,
    CF_BUILD_TIMESTAMP,
    CF_BUILD_ID,
  } = env;

  return `${CF_REPO_NAME}:${CF_BRANCH}:${CF_REVISION}:${CF_BUILD_ID}:${CF_BUILD_TIMESTAMP}`;
};

export const getEnv = ({ req }) => {
  if (typeof process === 'undefined' || typeof req === 'undefined') {
    return {};
  }

  const ip = req.headers['x-forwarded-for'] || req.connection.remoteAddress;
  const {
    AWS_REGION,
    AWS_STREAM_NAME,
    AWS_FLOW_STREAM_NAME,
    AWS_IDENTITY_POOL_ID,
    AWS_PARTITION_KEY,
    AWS_TRACKING_ENV,
    GTM_ID,
    GTM_OPTIMIZE_ID,
    URL_PROTOCOL,
    URL_HOSTNAME,
    FIREBASE_API_KEY,
    FIREBASE_AUTH_DOMAIN,
    FIREBASE_PROJECT_ID,
    FIREBASE_APP_ID,
    FIREBASE_AUTH_EMULATOR_HOST,
    FIREBASE_FIRESTORE_EMULATOR_HOST,
    FIREBASE_REGION,
    CONSUMER_AREA_API_URL,
    USE_CROSS_DOMAIN_ANALYTICS_COOKIES,
  } = process.env;

  return {
    awsRegion: AWS_REGION,
    awsStreamName: AWS_STREAM_NAME,
    awsFlowStreamName: AWS_FLOW_STREAM_NAME,
    awsIdentityPoolId: AWS_IDENTITY_POOL_ID,
    awsPartitionKey: AWS_PARTITION_KEY,
    awsTrackingEnv: AWS_TRACKING_ENV,
    requestIp: ip,
    appVersion: getAppVersion(process.env),
    urlProtocol: URL_PROTOCOL,
    urlHostname: URL_HOSTNAME,
    gtmId: GTM_ID,
    gtmOptimizeId: GTM_OPTIMIZE_ID,
    firebaseApiKey: FIREBASE_API_KEY,
    firebaseAuthDomain: FIREBASE_AUTH_DOMAIN,
    firebaseProjectId: FIREBASE_PROJECT_ID,
    firebaseAppId: FIREBASE_APP_ID,
    firebaseAuthEmulatorHost: FIREBASE_AUTH_EMULATOR_HOST,
    firebaseFirestoreEmulatorHost: FIREBASE_FIRESTORE_EMULATOR_HOST,
    firebaseRegion: FIREBASE_REGION,
    consumerAreaApiUrl: CONSUMER_AREA_API_URL,
    useCrossDomainAnalyticsCookies:
      USE_CROSS_DOMAIN_ANALYTICS_COOKIES === 'true',
  };
};

const reducersEnvFactory = ({ isServer, req }) => {
  if (!isServer) {
    return (state = {}) => state;
  }

  const env = getEnv({ req });
  return (state = env) => {
    return state;
  };
};

export default reducersEnvFactory;
