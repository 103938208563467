// Slugify and then capitalize first letter and letters after hyphens
// Requested by and named after Jon for SEO purposes. Please curse his name every time you read this.
import slugify from './slugify';

export const jonify = string => {
  return slugify(string)
    .split('-')
    .map(bits => `${bits.charAt(0).toUpperCase()}${bits.slice(1)}`)
    .join('-');
};

export default jonify;
