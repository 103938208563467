import { useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import { useSessionUserId } from 'lib/auth/auth.hooks';
import { initialiseBraze, openSession } from './braze';

export function BrazeProvider({ children }: { children: ReactNode }) {
  const userId = useSessionUserId();
  const [initialised, setInitialised] = useState(false);

  useEffect(() => {
    if (initialised) return;
    initialiseBraze();

    setInitialised(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!initialised) return;

    openSession(userId);
  }, [initialised, userId]);

  return children;
}
