import { useContext } from 'react';
import { FirebaseAppContext } from './context';

export const useFirebaseAppContext = () => {
  const appContext = useContext(FirebaseAppContext);
  if (!appContext) {
    throw new Error(
      'useFirebaseAppContext must be used within FirebaseAppContext',
    );
  }

  return appContext;
};

export const useFirebaseApp = () => {
  const { app } = useFirebaseAppContext();
  return app;
};

export const useFirebaseConfig = () => {
  const { config } = useFirebaseAppContext();
  return config;
};
