import { useAuthContext, useCurrentUser, useLoading } from '@fire/auth';
import { useRegister } from '@fire/auth/hooks/register';
import type { Auth } from 'firebase/auth';
import { signInWithCustomToken } from 'firebase/auth';
import {
  useAuthToken,
  useIsAuthenticated,
  useSessionUserEmail,
} from 'lib/auth/auth.hooks';
import { createContext, useEffect, useState } from 'react';
import { getConsumerProfile } from './consumer-profile-apis';
import type { Dispatch, ReactNode, SetStateAction } from 'react';
import type { ConsumerResponse } from './consumer.schema';
import { z } from 'zod';
import { track } from 'lib/sdks/analytics';

type ConsumerContextValue = {
  email?: string;
  profile: ConsumerResponse | null;
};

export const ConsumerContext = createContext<
  [ConsumerContextValue, Dispatch<SetStateAction<ConsumerContextValue>>] | null
>(null);

async function signInToFirebaseUsingBFFCustomToken(
  firebaseAuth: Auth,
  token: string,
) {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_CONSUMER_SERVICE_URL}/auth/firebase`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );

  if (!res.ok) {
    throw res;
  }

  const result = await res.json();

  await signInWithCustomToken(firebaseAuth, result.token);
  track('consumer_area.login');
}

async function registerAndSignIn({
  register,
  profile: _profile,
  token,
  firebaseAuth,
}: {
  token: string;
  register: ReturnType<typeof useRegister>['register'];
  firebaseAuth: Auth;
  profile?: ConsumerResponse;
}) {
  const profile = _profile ?? (await getConsumerProfile({ token }));

  await register(
    {
      firstName: profile.firstName,
      lastName: profile.lastName,
      email: profile.email,
      phoneNumber: profile.phone as string,
      postcode: profile.postcode as string,
    },
    { autoOptIn: false, source: 'create_account' },
  );

  await signInToFirebaseUsingBFFCustomToken(firebaseAuth, token);
}

export const ConsumerProvider = ({ children }: { children: ReactNode }) => {
  const consumerContextValue = useState<ConsumerContextValue>({
    profile: null,
  });
  const [{ profile }] = consumerContextValue;
  const token = useAuthToken();
  const isAuthenticated = useIsAuthenticated();
  const loading = useLoading();
  const currentFirebaseUser = useCurrentUser();
  const isAuthFirebase = !!currentFirebaseUser;
  const { auth: firebaseAuth } = useAuthContext();
  const { register } = useRegister();
  const sessionEmail = useSessionUserEmail();

  const differingUsers = !!(
    currentFirebaseUser?.email &&
    sessionEmail &&
    currentFirebaseUser.email !== sessionEmail
  );

  useEffect(() => {
    if (differingUsers) {
      firebaseAuth.signOut();
    }
  }, [differingUsers, firebaseAuth]);

  useEffect(() => {
    async function signIntoFirebase(_token: string) {
      try {
        await signInToFirebaseUsingBFFCustomToken(firebaseAuth, _token);
      } catch (err) {
        // TODO Find a way to type error better
        const error = z.object({ status: z.number() }).safeParse(err);
        if (!error.success) return;
        if (error.data.status.toString().charAt(0) !== '4') {
          return;
        }

        if (!profile) return;

        await registerAndSignIn({
          register,
          profile,
          token: _token,
          firebaseAuth,
        });
      }
    }

    if (!isAuthenticated || !token || isAuthFirebase || loading) return;
    signIntoFirebase(token);
  }, [
    firebaseAuth,
    isAuthFirebase,
    isAuthenticated,
    loading,
    register,
    token,
    profile,
  ]);

  return (
    <ConsumerContext.Provider value={consumerContextValue}>
      {children}
    </ConsumerContext.Provider>
  );
};
