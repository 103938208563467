import { signIn, signOut } from 'next-auth/react';
import { getServerSession as getServerSessionNextAuth } from 'next-auth/next';
import { authOptions } from './config';
import { logger } from 'lib/utils/logger';
import absoluteUrl from '@utils/formatters/absolute-url';

export const ALLOWED_AUTH_REDIRECTS = [
  '/account',
  '/consumer/messages',
  '/account/register',
  '/message/contact-information',
] as const;

export const AUTH_REDIRECT_LOCAL_STORAGE_KEY = 'authn-redirect-uri';

/**
 * Allowed callback urls for login. These should be whitelisted in auth provider before being added here.
 */
type AllowedCallbacks = (typeof ALLOWED_AUTH_REDIRECTS)[number] | (string & {});

export const login = (callbackUrl: AllowedCallbacks = '/account') => {
  localStorage.setItem(AUTH_REDIRECT_LOCAL_STORAGE_KEY, callbackUrl);
  signIn(
    'checkatrade',
    { callbackUrl },
    callbackUrl && ALLOWED_AUTH_REDIRECTS.includes(callbackUrl)
      ? { authn_link_redirect_uri: absoluteUrl(callbackUrl) }
      : undefined,
  );
};

export const loginWithIDPToken = (token: string, callbackUrl: string) => {
  signIn('custom-checkatrade', { token, callbackUrl });
};

export const logout = () => {
  signOut();
};

export const getServerSession = async (req: any, res: any) => {
  try {
    const session = await getServerSessionNextAuth(req, res, authOptions);

    return session
      ? {
          ...session,
          activeSession: session.user && !session.error,
        }
      : ({ activeSession: false } as const);
  } catch (e) {
    logger.error(e, 'getServerSession error');
    return { activeSession: false } as const;
  }
};
