export enum EventMap {
  'started' = 'Flow started',
  'step_changed' = 'Flow step changed',
  'cleared' = 'Flow cleared',
  'stopped' = 'Flow stopped',
  'global.geo_location_pin' = 'Clicked geo location pin',
  'global.page_view' = 'Rendered the page.',
  'global.url_redirect' = 'Link or button clicked that leaves the website.',
  'global.application_in_foreground' = 'Fires when the tab or window gets in the foreground.',
  'global.modal_appear' = 'Modal window appeared.',
  'global.modal_close' = 'Modal window closed.',
  'global.open_trader' = 'Visitor opens a trade profile from the carousel',
  'global.hamburger_open' = 'Clicked on the hamburger menu.',
  'global.sign_in' = 'Clicked the "Sign-in" button on the popup.',
  'global.sign_out' = 'Clicked the Sign Out button.',
  'global.search' = 'Search button clicked. The event must be fired on the page where the button was pushed to capture the URL of the page, and we can redirect afterwards.',
  'global.search_category_change_live' = 'Search category input was changed. The event will be fired, after a short debounce, when the category search input box has changed value.',
  'global.search_validation_error' = 'The user tries to search but it received an error about providing an existing category name.',
  'global.request_callback' = 'Click the request a callback button on a project',
  'global.message_trader' = 'Click the message a trader',
  'global.trader_impressions' = 'A list of traders appears on the site. Send only if they are visible for the user with order in the grid view. We send multiple trader in a batch as they appear.',
  'global.trades.share_click' = 'Share the trader via social media',
  'global.call_trader' = 'Click the call button on a trader',
  'global.send_email' = 'Visitor clicks the Traders email address on the Trader profile',
  'global.add_to_shortlist' = 'Visitor clicks the "Add to shortlist" button on a trader',
  'global.remove_from_shortlist' = 'Visitor clicks the "Remove to shortlist" button on a trader',
  'global.join' = 'Visitor clicks the "Get started" or "Join Checkatrade" buttons.',
  'error.message' = 'Error happened on the webpage',
  'homepage.show' = 'Trader page was rendered',
  'homepage.search_type_change' = 'Visitor clicks the "look up a member by name" or the "search for a trade person near you" to change search method',
  'homepage.article_open' = 'Visitor clicks any of the articles on the homepage',
  'homepage.article_context_menu_open' = 'Visitor opens context menu on any of the articles on the homepage',
  'trader.show' = 'Trader page was rendered',
  'trader.show_more_albums' = 'Visitor clicks show more albums button to show two more albums',
  'trader.top_services_show' = 'Visitor clicks to see one of the top services category',
  'trader.top_skills_show' = 'Visitor clicks to see one of the top skills category',
  'trader.description_show' = 'Visitor clicks to see the full description of the trader',
  'trader.description_collapse' = 'Visitor clicks to see a brief description of the trader',
  'trader.accreditation_show' = 'Visitor clicks to show all accreditations',
  'trader.accreditation_collapse' = 'Visitor clicks to show some accreditations',
  'trader.verification_show' = 'Visitor clicks to collapse one of the articles in the verification section',
  'trader.leave_feedback' = 'Visitor click the "Leave feedback" button',
  'trader.banner.leave_feedback' = 'Visitor click the "Write a review" button from the banner which loads if the user has already contacted a trade via a message or a call',
  'trader.feedback_show' = 'Visitor clicks to show the full feedback',
  'trader.feedback_impressions' = 'List of reviews has loaded on the page',
  'trader.image_show' = 'Event sent with the initial image appears on the header, or when a visitor clicks the arrows (or the small dots) to show other pictures of previous works from the trader, or on gallery change with the initial images',
  'trader.review_show' = 'Trader reviews page is rendered',
  'trader.review_sort_change' = 'Visitor changed the sorting on the review listing.',
  'trader.open_related_search' = 'Open related search from trader page',
  'search.filter' = 'Search filter button click - search dock',
  'search.show' = 'Search page is rendered.',
  'search.load_more' = 'Search page load more button clicked.',
  'search.refine_open' = 'Visitor clicked the "Refine" box to show the options. (mobile only)',
  'search.refine_change' = 'Any change on the selected filters within the refine search box. Please resend search.show after this.',
  'search.refine_category' = 'Visitor clicked a subcategory',
  'search.location_refinement_modal.show' = 'Location refinement modal is shown to the user on search page',
  'search.location_refinement_modal.location_filled' = 'Location field in location refinement modal filled and no longer in focus',
  'search.location_refinement_modal.dismissed' = 'Location refinement modal is dismissed by the user',
  'search.location_refinement_modal.submit' = 'Location refinement modal is submitted by the user with the refined location',
  'search.heatable_redirect' = 'User has started heatable flow',
  'search.heatable_show' = 'Heatable panel is shown',
  'shortlist.show' = 'Shortlist page is rendered.',
  'message.image' = 'User clicked image button',
  'message.show' = 'Message Trader page was rendered',
  'message.form_submit' = 'Visitor clicks the "Submit" button on the "send your message" form',
  'message.form_input_filled' = 'Input field not in focus anymore on the "send your message" form',
  'message.sent' = 'A DirectMessage request was sent to JobsManagement after "Message" from submit',
  'send_a_job.show' = 'Send a Job page was rendered',
  'send_a_job.form_submit' = 'Visitor clicks the "Submit" button on the "send your Send a Job" form',
  'send_a_job.form_input_filled' = 'Input field not in focus anymore on the "send your Send a Job" form',
  'send_a_job.sent' = 'A callback request send after "Post a Job" form submit',
  'inline_send_a_job.open' = 'Visitor toggles the inline post a job to open',
  'inline_send_a_job.form_submit' = 'Visitor clicks the "Submit" button on the "Inline Post a Job" form',
  'inline_send_a_job.form_input_filled' = 'Input field not in focus anymore on the "Inline Post a Job" form',
  'inline_send_a_job.sent' = 'A callback request send after "Inline Post a Job" form submit',
  'homepage.categories_tile_click' = 'User clicked on an homepage category tile',
  'homepage.categories_arrow_right_click' = 'User clicked on an homepage arrow right on categories',
  'homepage.categories_arrow_left_click' = 'User clicked on an homepage arrow left on categories',
  'homepage.categories_dot_click' = 'User clicked on an homepage dot on categories',
  'homepage.search_modal_submit' = 'User Search on homepage search modal',
  'freemium.claim_profile' = 'User clicked on claim freemium profile',
  'freemium.search_verified_trader' = 'User searched for verified trader from freemium',
  'convert_to_job.show' = 'Show convert message to post a job',
  'convert_to_job.form_submit' = 'Submit the form to convert a message to a job',
  'convert_to_job.cancel' = 'Opt out of converting a message to a job',
  'convert_to_job.sent' = 'A callback request send after messaging form submit',
  'consumer_area.login' = 'Sent after login with password or magic link',
  'consumer_area.login_failed' = 'Sent at login failure',
  'consumer_area.logout' = 'Sent after logout',
  'consumer_area.register' = 'Successful registration',
  'consumer_area.register_failed' = 'Registration failed',
  'consumer_area.register_existing_account' = 'Sent when the user already exists in the registration flow and message was shown',
  'consumer_area.password_reset_requested' = 'Password reset is requested',
  'consumer_area.password_reset_request_failed' = 'Password reset request failed',
  'consumer_area.password_reset_submitted' = 'Sent after a successful password reset',
  'consumer_area.account_verification' = 'Successful account verification',
  'consumer_area.account_verification_failed' = 'Error at account verification',
  'consumer_area.account_updated' = 'Profile is successfully edited',
  'consumer_area.resend_verification' = 'Resend verification button is clicked',
  'consumer_area.resend_verification_email_failed' = 'Resend verification email failed',
  'consumer_area.show_job' = 'The user clicks on a trader on the trader message lists',
  'consumer_area.view_trader_profile' = 'View profile has been clicked on a trader',
  'consumer_area.leave_review' = 'User clicks the leave review button',
  'consumer_area.request_a_new_quote' = 'User clicks to request new quote for their job',
  'consumer_area.close_job' = 'User clicks to close their job',
  'consumer_area.close_job_new_job_open' = 'User lands on close confirmation page',
  'consumer_area.close_job_not_closed_new_job_open' = 'User lands on close confirmation page, but job is not closed',
  'consumer_area.close_job_new_job_submit' = 'User clicks to submit new job',
  'consumer_area.close_job_not_closed_new_job_submit' = 'User clicks to submit new job, but job is not closed',
  'consumer_area.delete_account' = 'User closes their account',
  'consumer_area.reauthenticate' = 'Sent after reauthenticating with password',
  'consumer_area.reauthenticate_failed' = 'Reauthenticate request has failed',
  'consumer_area.set_password' = 'User has updated their password',
  'consumer_area.set_password_failed' = 'Updating password has failed',
  'consumer_area.magic_link_requested' = 'User clicks login with email link',
  'consumer_area.magic_link_requested_failed' = 'Login with email link failure',
  'consumer_area.magic_link_resend' = 'User requests another magic link to be sent',
  'consumer_area.message_edit_details_clicked' = 'Message edit details clicked',
  'consumer_area.message_prepopulated' = 'Message Pre-populated',
  'consumer_area.message_use_another_account_clicked' = 'Message use another account clicked',
  'consumer_area.review_prepopulated' = 'Review Pre-populated',
  'consumer_area.review_edit_details_clicked' = 'Review edit details clicked',
  'consumer_area.review_use_another_account_clicked' = 'Review use another account clicked',
  'consumer_area.opt_in_to_marketing' = 'User opts in to marketing',
  'consumer_area.opt_out_of_marketing' = 'User opts out of marketing',
  'consumer_area.guarantee_banner_clicked' = 'User clicks the job guarantee banner',
  'consumer_area.close_job_leave_review_open' = 'User lands on the leave a review page',
  'consumer_area.close_job_leave_review' = 'User wishes to leave a review',
  'consumer_area.close_job_leave_review_not_ready_yet' = 'User not wishing to leave a review.',
  'consumer_area.close_job_survey_submit' = 'Close job survey submit',
  'consumer_area.close_job_closed_not_going_ahead_submit' = 'Closed job not going ahead submit',
  'consumer_area.more_quotes_open' = 'User lands on the more quotes page',
  'consumer_area.more_quotes_submit' = 'User clicks submit or cancel on more quotes page',
  'consumer_area.more_quotes_confirmation_open' = 'User lands on confirmation page and goes to search on checkatrade.',
  'consumer_area.call_trade' = 'User call trade from jobs page',
  'trader.carousel_show' = 'Trader profile is rendered with the carousel',
  'trader.carousel_impressions' = 'A list of traders appears in the carousel',
  'trade_backToSearch.click' = 'Click back to search from trade page',
  'trade_previousTrade.click' = 'Click previous trade from trade page',
  'trade_nextTrade.click' = 'Click next trade from trade page',
  'trade_nav.click' = 'Click a link on the nav menu',
  'image_missing' = 'Image missing',
  'contact.started' = 'Homowner has started a contact flow with a trade',
  'reviews_form.seen' = 'User has seen the reviews form',
  'reviews_form.success' = 'User has successfully submitted the reviews form',
  'job.view_change' = 'User has changed views in the job flow',
  'job.description.submit' = 'User has submitted a job description',
  'job.validation_error' = 'User has received a validation error in the job flow',
  'auth.validate-magic-link' = 'Validating Magic link',
  'auth.invalid-magic-link' = 'Invalid magic link',
  'auth.different-device' = 'User signed in on a different device',
  'auth.validate-session-timeout' = 'Failed to validate session',
  'auth.redirect-success' = 'User signed in and redirected successfully',
  'inbox.message_opened' = 'User has opened a message',
  'inbox.message.report' = 'User has report message',
  'inbox.message.options.leave_review' = 'User has clicked on leave a review',
  'inbox.message_screen_overflow_withdraw_click' = 'User has withdraw job from tradesperson',
  'inbox.message.options.block_tradesperson' = 'User has clicked on block tradesperson',
  'inbox.message.call_clicked' = 'User has clicked on call button in chat',
  'chat.quote_accept' = 'User has accepted the quote via chat',
  'chat.quote_decline' = 'User has declined the quote via chat',
  'quote.seen' = 'User has seen the anonymous quote',
  'quote.error' = 'Error fetching the quote',
  'quote.accepted' = 'User has accepted the quote',
  'quote.rejected' = 'User has rejected the quote',
  'quote.pdf_seen' = 'User has seen the PDF of the quote',
  'quote.pdf_error' = 'Error fetching the PDF of the quote',
  'my_jobs.withdraw_request_confirm' = 'Job has been withdrawn from trade',
  'my_jobs.action_trader_withdraw_click' = 'User has clicked on options menu',
  'my_jobs.job_options_close_job_click' = 'User has clicked on close job option in job menu',
  'my_jobs._close_job_continue_click' = 'User click continue in close job modal',
  'my_jobs._close_job_confirm_click' = 'User confirms that they want to close job',
  'my_jobs._close_job_keep_open_click' = 'User decided to keep job open',
}
