import styled from 'styled-components';

interface NotificationDotProps {
  color: string;
}

const NotificationDot = styled.div<NotificationDotProps>`
  background-color: ${props => props.color};
  border-radius: 50%;
  box-sizing: border-box;
  color: white;
  font-size: 1px;
  height: 10px;
  width: 10px;
`;

export default NotificationDot;
