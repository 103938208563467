import type { SelectEffect, CallEffect, PutEffect } from 'redux-saga/effects';
import { takeLatest, put, call } from 'redux-saga/effects';

import { searchCategories } from 'lib/sdks/search';
import { GET_CATEGORIES_INIT } from '../constants';
import { getCategoriesSuccess, getCategoriesError } from '../actions';

export function* getCategories({
  payload,
}: {
  payload: {
    isServer: boolean;
    includeAliasCategories: boolean;
    persistFromSSR?: boolean;
  };
}): Generator<SelectEffect | CallEffect | PutEffect, any, any> {
  const { isServer, persistFromSSR, includeAliasCategories } = payload;
  try {
    const data = yield call(searchCategories, includeAliasCategories);
    // This saves 32kb off of the page size
    // But the sitemap needs it to be there for the
    // selectors to run correctly
    if (!isServer || persistFromSSR) {
      yield put(getCategoriesSuccess({ data }));
    }

    return data;
  } catch (error) {
    yield put(getCategoriesError(error));
    return null;
  }
}

export default function* watcher() {
  // @ts-ignore
  yield takeLatest(GET_CATEGORIES_INIT, getCategories);
}
