import { combineReducers } from 'redux';
import companyInitialState from 'containers/CompanyContainer/initial-state';
import globalReducerFactory from 'containers/AppContainer/reducer-factory';
import searchInitialState from 'containers/SearchContainer/initial-state';
import partnershipsInitialState from 'containers/PartnershipsContainer/initial-state';

import envFactory from './reducers-env-factory';
import abTestsFactory from './reducers-abtests-factory';

const noop = initialState => s => s || initialState || {};

const reducersFactory = opts => {
  return combineReducers({
    global: globalReducerFactory(opts),
    env: envFactory(opts),
    abTests: abTestsFactory(opts),
    // these will be replaced by injected reducers
    company: noop(companyInitialState),
    search: noop(searchInitialState),
    partnerships: noop(partnershipsInitialState),
    ...opts.injectedReducers,
  });
};

export default reducersFactory;
