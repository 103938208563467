const nav = 60;
const navBorder = 4;

const spacing = {
  // general:
  micro: 2,
  small: 5,
  base: 10,
  medium: 15,
  large: 20,
  xlarge: 25,
  xxlarge: 30,
  xxxlarge: 35,
  title: 36,
  huge: 40,
  giant: 45,
  super: 50,

  // specific:
  circleLogo: 65,
  nav,
  navBorder,
  navTotal: nav + navBorder,
  squareLogo: 100,
  squareLogoSmall: 90,

  maxPageWidth: 1050,

  // new figma:
  newBorderRadius: 4,
  newBase: 8,
  newLarge: 18,
};

export default spacing;
