import { z } from 'zod';
import {
  AddressPostcodeSchema,
  AddressResponseSchema,
  AddressSchema,
} from './address/address.schema';

const nameSchema = (fieldName: string = 'Name') =>
  z
    .string()
    .min(1, `${fieldName} should be at least 1 character`)
    .max(125, `${fieldName} should be less than 125 characters`)
    .regex(/^[\p{L}\p{M}][\p{L}\p{M}\p{Zs}\p{Pd}.']*$/u, 'Invalid name')
    .trim();

export const CommunicationPreferencesSchema = z.array(
  z.object({
    // TODO change to literal of string values
    topic: z.string(),
    channel: z.object({
      email: z.boolean().optional(),
      push: z.boolean().optional(),
    }),
  }),
);

export const ConsumerSchema = z.object({
  id: z.string(),
  firstName: nameSchema('First name'),
  lastName: nameSchema('Last name'),
  email: z.string({ description: 'email' }).email(),
  phone: z
    .string()
    .min(5, 'Phone number is invalid')
    .max(16, 'Phone number is invalid')
    .regex(/^\+?[0-9]{5,15}$/, 'Phone number is invalid'),
  postcode: AddressPostcodeSchema.optional(),
  address: z.array(AddressSchema).optional(),
  primaryAddress: AddressSchema.optional(),
  communicationPreferences: CommunicationPreferencesSchema,
});

export const ConsumerResponseSchema = ConsumerSchema.partial({
  phone: true,
})
  .extend({
    primaryAddress: AddressResponseSchema.optional(),
  })
  .transform(data => {
    return {
      fullName: `${data.firstName} ${data.lastName}`,
      ...data,
    };
  });

export const ConsumerRequestSchema = ConsumerSchema.omit({
  id: true,
  email: true,
}).extend({
  primaryAddressId: AddressSchema.extend({
    type: z.literal('JOB'),
  }).optional(),
});

export const ConsumerRequestPartialSchema = ConsumerSchema.omit({
  id: true,
  email: true,
})
  .extend({
    primaryAddressId: z.string().optional(),
  })
  .partial();

export const ConsumerValidProfile = ConsumerRequestSchema.required({
  primaryAddress: true,
});

export type ConsumerResponse = z.infer<typeof ConsumerResponseSchema>;

export type ConsumerRequest = z.infer<typeof ConsumerRequestSchema>;

export type ConsumerPartialRequest = z.infer<
  typeof ConsumerRequestPartialSchema
>;
