import { z } from 'zod';

export const TokenSchema = z.object({
  accessToken: z.string(),
  refreshToken: z.string(),
  expiresAt: z.number(),
});

export const IDPTokenSchema = TokenSchema.extend({
  email: z.string(),
  id: z.string(),
});

export const JWTSchema = TokenSchema.extend({
  version: z.number().optional(),
  isNewUser: z.boolean(),
  userId: z.string(),
  error: z.string().optional(),
  email: z.string(),
});
