import React from 'react';
import dynamic from 'next/dynamic';
import { CSSTransition } from 'react-transition-group';
import { Wing } from './styles';
import type { OnTrackEventArgs } from 'containers/AppContainer/AppContainer.interfaces';

const SideNav = dynamic(() => import('./SideNav'), { ssr: false });

interface NavLeftProps {
  onClose: () => void;
  onOpen: () => void;
  onTrackEvent: (args: OnTrackEventArgs) => void;
  open: boolean;
  shortlistLength: number;
}

class NavLeft extends React.PureComponent<NavLeftProps> {
  /* eslint-disable jsx-a11y/anchor-is-valid */
  render() {
    const { onTrackEvent, open, onClose, onOpen, shortlistLength } = this.props;
    return (
      <CSSTransition in={open} timeout={200} classNames="wing" key="wingkey">
        <Wing>
          {open && (
            <SideNav
              onTrackEvent={onTrackEvent}
              onClose={onClose}
              onOpen={onOpen}
              shortlistLength={shortlistLength}
            />
          )}
        </Wing>
      </CSSTransition>
    );
  }
}

export default NavLeft;
