import { runWhenReady } from './queue';

import type {
  Event,
  FlowEnrollment,
  EventName,
  CustomEventData,
  Plugin,
} from './types';
import { EventMap } from './types';

import { getContext } from './context';

let plugins: Plugin[];
let prevPageUrl: URL;

export const setPlugins = (pluginsParam: Plugin[]) => {
  plugins = pluginsParam;
};

export const trackEvent = (
  eventName: EventName,
  customData?: CustomEventData,
  flowEnrollment?: FlowEnrollment,
) => {
  if (typeof window === 'undefined') {
    return;
  }

  const time = new Date();
  const pageUrl = new URL(window.location.href);
  const browserWidth = window.innerWidth;
  const browserHeight = window.innerHeight;

  runWhenReady(() => {
    const context = getContext();

    if (!context) {
      return;
    }

    const event: Event = {
      name: eventName,
      description: EventMap[eventName] || eventName,
      customData: { ...customData },
      flowEnrollment,
      time,
      pageUrl,
      referrer: prevPageUrl ? prevPageUrl.href : document.referrer,
      browserWidth,
      browserHeight,
      context,
    };

    if (eventName === 'global.page_view') {
      event.utm = {
        source: pageUrl.searchParams.get('utm_source'),
        medium: pageUrl.searchParams.get('utm_medium'),
        campaign: pageUrl.searchParams.get('utm_campaign'),
      };
      prevPageUrl = pageUrl;
    }

    plugins.forEach(plugin => {
      plugin(event);
    });
  });
};
